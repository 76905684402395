import React, {useEffect, useState} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const { REACT_APP_BASE_URL: img_url, REACT_APP_IMAGE_SIZE_2: imageSize2 } = process.env;

const PreviewAlbum = ({images}) => {

  return (
    <div className="preview-album">
      <Carousel {...{
        renderArrowPrev: (clickHandler, hasPrev) => hasPrev && (<div className="arrow arrow-left" onClick={clickHandler}><FontAwesomeIcon icon={faChevronLeft} /></div>),
        renderArrowNext: (clickHandler, hasNext) => hasNext && (<div className="arrow arrow-right" onClick={clickHandler}><FontAwesomeIcon icon={faChevronRight} /></div>),
        showIndicators: false,
      }}>
        {images && images.map((img, idx) => {
          return <div key={idx} interval={3000}>
            <img
              className="d-block w-100"
              src={img_url + img.srcs[imageSize2]}
              alt="First slide"
            />
          </div>
        })}
      </Carousel>
    </div>
  );
}

export default PreviewAlbum;