import React, { useRef, useState } from 'react';
import {Row, Col, Modal, Button, Form,} from 'react-bootstrap';
import { Box } from './index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretRight, faBell} from '@fortawesome/free-solid-svg-icons';

const Service = () => {
  const [show, toggleShow] = useState(false);
  const processText = [
    {item: "初步洽談", content: "了解屋主生活習慣、特殊偏好、各項需求、家具規劃、工程預算......等。"},
    {item: "現場丈量與勘查", content: "對工地現場、周遭環境、大樓規範......等進行了解，並且丈量拍照作為設計參考。"},
    {item: "平面規劃", content: "依據屋主需求及現場空間，進行平面配置與動線規劃。"},
    {item: "初步提案", content: "以初步規劃的草圖進行提案說明，取得必要之認同並進行調整。"},
    {item: "簽訂設計合約", content: "如屋主對前項提供的提案滿意，有一進一步合作，則雙方簽訂合約，並根據設計草案進行必要之調整。"},
    {item: "細部設計", content: "根據設計草案制定色彩計畫、進行細部規劃並繪製施工詳圖。"},
    {item: "工程報價", content: "設計圖面與材質選用確認後，提供完整且合理的工程細項報價，充分取的屋主之信任。"},
    {item: "簽訂工程合約", content: "如屋主對前項提供的報價信任且滿意，則雙方簽訂工程合約，並詳細說明工程承攬內容。"},
    {item: "施工監造", content: "擬定工程進度表，進入工程並現場監工，確保各項工程順利進行。"},
    {item: "驗收交屋", content: "工程進行一定程度後，偕同屋主進行階段性驗收，完工交屋結案。"},
  ]

  const chargeList = [
    { item: '提案費', design: '5000<br/><span>(一次性收費，後續可折抵於設計費)</span>', management: '-', advice: '-' },
    { item: '預售屋客變', design: '20000/案', management: '-', advice: '3~6萬/坪' },
    { item: '新成屋', design: '4000/坪', management: '5~10%<br/><span>(視案場不同調整，分3~6次階段性收取)</span>', advice: '3~6萬/坪' },
    { item: '毛胚屋', design: '4500/坪', management: '5~10%<br/><span>(視案場不同調整，分3~6次階段性收取)</span>', advice: '5~8萬/坪' },
    { item: '老屋翻新', design: '4500/坪', management: '5~10%<br/><span>(視案場不同調整，分3~6次階段性收取)</span>', advice: '8~10萬/坪' },
    { item: '商空規劃', design: '2000~3500/坪<br/><span>(視個案)</span>', management: '5~10%<br/><span>(視案場不同調整，分3~6次階段性收取)</span>', advice: '視個案' },
  ]

  const createMarkup = (string) => {
    return {__html: string};
  }

  return (
    <div className="service">
      <Modal className="service-modal" size={"lg"} show={!!show} onHide={() => {
        toggleShow(false);
      }} centered>
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className="tip-title">
              業主事前準備建議<br/><p>(不一定需要，但有助於流程順利進行、減少來回等待的時間)</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              <li>
                <span>-</span>風格照片：<p>多找些自己偏好的室內照片，喜歡的色系、造型</p></li>
              <li>
                <span>-</span>確認新購or延用的家電家具：
                <p>列出哪些是需要新購的清單，亦可提供品牌型號
                  <br/>
                  若有需延用的家具，建議提供照片、實際尺寸，以利平面配置與色彩規劃時能一併考慮進去
                </p>
              </li>
              <li>
                <span>-</span>CAD圖檔：
                <p>預售、新成屋等物件，可與建商索取相關圖檔，以利於減少屋主與設計師場勘的時間</p>
              </li>
              <li>
                <span>-</span>建築原始圖面：
                <p>中古屋、老屋等物件，因屋子現況大多與一開始蓋好時不太一樣，若能調出建築物竣工圖&結構圖，便可清楚知道管道間、承重牆面...等原始位置，亦有利於後續設計施工、以及申請室裝等事務</p>
              </li>
            </ul>
          </Modal.Body></>
      </Modal>
      <div className="pageContainer">
        <div className="content container">
          <section className="rundown">
            <p className="title">服務流程</p>
            <div className="grid-wrap">
              <div className="grid">
                {processText.map((item, ind) => {
                  return (
                    <React.Fragment key={ind}>
                      <Box>
                        <b><span>{ind+1}</span>{item.item}</b>
                        <p>{item.content}</p>
                        {ind === 0 && <p className="tip" onClick={()=>{toggleShow(true)}}><FontAwesomeIcon icon={faBell}/>貼心小提醒：業主事前準備建議</p>}
                      </Box>
                      {ind < processText.length - 1 ?
                        <Box className="icon-right"><FontAwesomeIcon icon={faCaretRight}/></Box> : ''}
                    </React.Fragment>
                  )
                })
                }
              </div>
            </div>
          </section>
          <section className="data-section">
          <p className="title">收費標準</p>
            <Row>
              <Col>
                <ul className="responsive-table">
                  <li className="table-header">
                    <div className="col col-1">項目</div>
                    <div className="col col-2">設計收費<br/><span>(此費用50%可折抵於後續工程款項)</span></div>
                    <div className="col col-3">工程管理費</div>
                    <div className="col col-4">預算預估建議<br/><span>(不含家具、家電、軟件)</span></div>
                  </li>
                  {chargeList.map((d, idx) => <li className={`table-row ${idx === chargeList.length-1 && "last"}`} key={idx}>
                    <div className="col col-1" data-label="項目">
                      <span>{d.item}</span>
                    </div>
                    <div className="col col-2 text-left" data-label="設計收費">
                      <span dangerouslySetInnerHTML={createMarkup(d.design)}/>
                    </div>
                    <div className="col col-3 text-left" data-label="工程管理費">
                      <span dangerouslySetInnerHTML={createMarkup(d.management)}/>
                    </div>
                    <div className="col col-4 text-left" data-label="預算預估建議">
                      <span dangerouslySetInnerHTML={createMarkup(d.advice)}/>
                    </div>
                  </li>)}
                  <li className="table-row2">
                    <div className="head">設計收費<br/><span>(此費用50%可折抵於後續工程款項)</span></div>
                    {chargeList.map((item, idx) =>
                      <div className="col col-1 text-left" data-label={item.item} key={idx}>
                        <span dangerouslySetInnerHTML={createMarkup(item.design)}/>
                      </div>
                    )}
                  </li>
                  <li className="table-row2">
                    <div className="head">工程管理費</div>
                    <div className="engineer-charge">
                      <p>5~10% <br/>(視案場不同調整，分3~6次階段性收取)</p>
                    </div>
                  </li>
                  <li className="table-row2">
                    <div className="head">預算預估建議<br/><span>(不含家具、家電、軟件)</span></div>
                    {chargeList.map((item, idx) => {
                      if (item.item !== '提案費')
                      return <div className="col col-1 text-left" data-label={item.item} key={idx}>
                        <span dangerouslySetInnerHTML={createMarkup(item.advice)}/>
                      </div>
                    })}
                  </li>
                </ul>
              </Col>
            </Row>

        </section>
        </div>
      </div>
    </div>
  )
};

export default Service;
