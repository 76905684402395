import React, {useState, useEffect} from 'react';
import {SRLWrapper} from "simple-react-lightbox";
import { useLightbox } from 'simple-react-lightbox'
import {Link} from 'react-router-dom';
import _ from 'lodash';
const axios = require('axios').default;

const { REACT_APP_IMAGE_SIZE_2: imageSize2 } = process.env;

const Pictures = (props) => {
  const {computedMatch, baseUrl, img_url} = props;
  const [data, setData] = useState([]);
  const [albumData, setAlbumData] = useState({filter: "", name: ""});
  const [dataArranged, setArrangedData] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const { openLightbox } = useLightbox();
  const id = computedMatch.params.id*1;

  useEffect(() => {
    if (collapsed) {
      return;
    }

    const handleKeyUp = (e) => {
      const state = e.state;
      if (state !== null) {
        setCollapsed(true);
        if(document.getElementsByClassName('SRLOpened')[0]) {
          window.history.go(1);
        }
      }
    }

    window.addEventListener("popstate", handleKeyUp);
    return () => window.removeEventListener("popstate", handleKeyUp);
  }, [collapsed]);

  useEffect(async () => {
    if (id) {
      const result = await axios.post(`${baseUrl + '/projects/' + id}`, {}, {
        validateStatus: false
      });
      setData(result.data.images);
      setAlbumData({filter: result.data.filterName, name: result.data.projectName});
      reArrange(result.data.images);
    }
  }, [id]);

  const reArrange = (data)=>{
    const columnNum = data.length <= 10 ? 2 : data.length <= 20 ? 3 : 5;
    let arrIndex = 1;
    const obj = {};
    _.range(1, columnNum + 1).forEach(arrIndex => obj[arrIndex] = []);
    for (const d of data) {
      obj[arrIndex].push(d);
      arrIndex = arrIndex < columnNum ? arrIndex + 1 : 1;
    }
    const results = Object.values(obj);
    setArrangedData(results);
  }

  const renderImages = (dataList) => {
    return dataList.map((column, idx) => {
      return <div className="column" key={idx}>
        {column.map((img, i) => {
          return <img src={img_url + img.srcs[imageSize2]} key={i} onClick={()=>openLightbox(_.findIndex(data, {srcs: {[imageSize2]:img.srcs[imageSize2]}}))}/>
        })}
      </div>
    })
  }

  const options = {
    settings: {
      autoplaySpeed: 1500,
      transitionSpeed: 900,
      disablePanzoom: false
    },
    buttons: {
      iconColor: 'rgba(255, 255, 255, 0.8)',
      iconPadding: '10px',
      showAutoplayButton: true,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: true,
      showNextButton: true,
      size: '40px'
    },
    caption: {
      captionColor: "#a6cfa5",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    }
  };

  const elements = data.map(image =>{return {src: img_url + image.srcs.src1920}});

  return (
    <div className="pictures">
      <div className="pageContainer">
        <div className="content">
          <ol className="breadcrumb">
              <li>
                <Link to={{pathname: "/Projects", query: albumData.filter}}>
                  {albumData.filter}
                </Link>
              </li>
            &nbsp;/&nbsp;
            <li>{albumData.name}</li>
          </ol>

            <SRLWrapper elements={elements} options={options} />
            <div className="row">
              {data.length > 0 && renderImages(dataArranged)}
            </div>
        </div>
      </div>
    </div>
  )
}

export default Pictures;
