import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Form, Modal, Button, Alert} from 'react-bootstrap';
import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImages, faFile} from '@fortawesome/free-regular-svg-icons';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import PreviewAlbum from './PreviewAlbum'

const axios = require('axios').default;

const FollowUp = (props) => {
  const {width, baseUrl} = props;
  const [types, setTypes] = useState([]);
  const [caseNo, setCaseNo] = useState('');
  const [caseNoForAlbum, setCaseNoForAlbum] = useState('');
  const [data, setData] = useState('');
  const [errMsg, setErrMsg] = useState(null);
  const [showAlbum, setShowAlbum] = useState(0);
  const [allowShow, setAllowShow] = useState(false);
  const [timer, setTimer] = useState(0);
  const [images, setImages] = useState([]);
  const MSG = {
    empty: '請輸入案件編號',
    '1013': '查無案件',
    '1012': '需輸入正確的案件編號',
  }

  useEffect(() => {
    loadTypes();
  }, []);

  useEffect(() => {
    if(!errMsg) clearTimeout(timer);
  } , [errMsg]);

  const updateInput = v => {
    setCaseNo(v.target.value);
  }

  const updateCaseNoForAlbum = v => {
    setCaseNoForAlbum(v.target.value);
  }

  const loadTypes = async () => {
    const url = `${baseUrl}/cases/processType`;
    const response = await axios.get(url);
    if (response.status !== 200) return;
    setTypes(response.data.types);
  }

  const submit = async () => {
    setData('');
    const url = `${baseUrl}/cases`;
    if (caseNo === '') return UpdateTip(MSG.empty);
    const response = await axios.post(url, {
      caseNo: caseNo,
    }, {
      validateStatus: false
    });
    if (response.status !== 200) {
      if (response.data.code === 1013) return UpdateTip(MSG['1013']);
      return;
    }
    setData({
      ...response.data.caseDto,
      processes: response.data.processes
    });
  }

  const UpdateTip = (msg) => {
    setErrMsg(msg);
    setTimer(setTimeout(()=>{
      setErrMsg(null);
    }, 3000));
  }

  const submitPreviewAlbum = async () => {
    const url = `${baseUrl}/projects/${showAlbum}`;
    if ( caseNoForAlbum === '') return UpdateTip(MSG.empty);
    const response = await axios.post(url, {
      caseNo: caseNoForAlbum,
    }, {
      validateStatus: false
    });
    if (response.status !== 200) {
      if (response.data.code === 1012) return UpdateTip(MSG['1012']);
      return;
    }
    setImages(response.data.images);
    setAllowShow(true);
  }

  return (
    <div className="follow-up">
      <div className="pageContainer">
        <section className="select-section">
          <Container className="content text-center">
            <Modal
              size="sm"
              show={errMsg}
              aria-labelledby="example-modal-sizes-title-sm"
              className={"warning"}
              backdropClassName={"warning"}
              onHide={() => setErrMsg(null)}
              onClick={() => setErrMsg(null)}
            >
              <Modal.Body><FontAwesomeIcon icon={faExclamationCircle}/>{errMsg}</Modal.Body>
            </Modal>
            <Row>
              <Col>
                <Form className="form">
                  <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={3}>
                      案件編號：
                    </Form.Label>
                    <Col sm={6}>
                      <Form.Control type="text" placeholder="" onChange={v => updateInput(v)}  onKeyPress={event => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          submit();
                        }
                      }}/>
                    </Col>
                    <Col sm={3}>
                      <Button className="submit" variant="primary" onClick={() => submit()}>送出</Button>
                    </Col>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
        {data && <section className="data-section">
          <Container>
            <Row>
              <Col>
                <ul className="responsive-table">
                  <li className="table-before-header">
                    <div>業主：{data.name}</div>
                    <div>工程地址：{data.address}</div>
                    <div>工程進度：{types.filter(type => type.code === data.process)[0] && types.filter(type => type.code === data.process)[0].description}</div>
                  </li>
                  <li className="table-header">
                    <div className="col col-1 text-center">日期</div>
                    <div className="col col-2">進度描述/會議內容</div>
                    <div className="col col-3 text-center">附件</div>
                  </li>
                  {data.processes ?
                    data.processes.map((d, idx) => <li className="table-row" key={idx}>
                      <div className="col col-1" data-label="日期">
                        <span>{dayjs(d.insertTime).format('YYYY/MM/DD')}</span>
                      </div>
                      <div className="col col-2 text-left" data-label="進度描述/會議內容">
                        <span>{d.content}</span>
                      </div>
                      <div className="col col-3 icon-link" data-label="附件">
                      <span>
                        {d.projectId ?
                          <FontAwesomeIcon icon={faImages} onClick={() => {
                            setShowAlbum(d.projectId)
                          }}/> :
                          d.pdfSrc ?
                            <a href={d.pdfSrc} target="_blank"><FontAwesomeIcon icon={faFile}/></a> : null}</span>
                      </div>
                    </li>)
                    :
                    <li className="table-row">
                      <div className="no-data">尚無進度</div>
                    </li>
                  }
                </ul>
              </Col>
            </Row>
          </Container>
        </section>}
      </div>
      <Modal className={!allowShow ? "fe-modal" : (width > 480) ? "fe-modal" : "fe-modal full-screen"}
             show={!!showAlbum} onHide={() => {
        setAllowShow(false);
        setShowAlbum(0);
      }} size={!allowShow ? "md" : "lg"} centered>
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {!allowShow ? "請再次輸入案件編號：" : "附件預覽"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!allowShow ? <Form className="form">
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                  <Col sm={12}>
                    <Form.Control type="text" placeholder="" onChange={v => updateCaseNoForAlbum(v)} onKeyPress={event => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        submitPreviewAlbum();
                      }
                    }}/>
                  </Col>
                  <Col sm={12} className="submit-section">
                    <Button className="submit" variant="primary"
                            onClick={() => submitPreviewAlbum()}>送出</Button>
                  </Col>
                </Form.Group>
              </Form> :
              <PreviewAlbum {...{
                ...props,
                id: showAlbum,
                images: images
              }} />}
          </Modal.Body></>
      </Modal>
    </div>
  );
};

export default FollowUp;
