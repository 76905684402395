import { useEffect, useState } from 'react';
import { Spinner, Table, Row, Col, Button, Form, Modal, InputGroup } from 'react-bootstrap';
import { TablePagination, ConfirmDeleteModal } from './Common';
import useAlert from '../../common/useAlert';

const { REACT_APP_API_BASE_URL: apiBaseUrl } = process.env;
const axios = require('axios').default;
const _ = require('lodash');
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const WriteModal = ({ handleClose, loadTable, id, caseId }) => {
  const [insertTime, setInsertTime] = useState();
  const [content, setContent] = useState();
  const [pdfSrc, setPdfSrc] = useState();
  const [projectId, setProjectId] = useState();
  const [refType, setRefType] = useState(1);
  const { successAlert } = useAlert();

  useEffect(() => {
    if (id) loadDetail(id);
  }, [])

  const submit = async () => {
    // pdfSrc & projectId 可以兩者都為空
    if (!insertTime || !content) return;

    const data = {
      content,
      insertTime: dayjs.utc(insertTime).utcOffset(-8).format('YYYY-MM-DD HH:mm:ss'),
    };
    if (refType === 1) {
      data.pdfSrc = pdfSrc;
      data.projectId = null;
    }
    if (refType === 2) {
      data.projectId = projectId;
      data.pdfSrc = null;
    }
    if (id) data.id = id;

    const url = `${apiBaseUrl}/admin/cases/${caseId}/processes`;
    const method = id ? 'patch' : 'post';
    const successMsg = id ? '編輯進度成功' : '新增進度成功';
    axios({ url, data, method })
      .then(response => {
        handleClose();
        loadTable();
        successAlert(successMsg);
      })
  };

  const loadDetail = async (id) => {
    const url = `${apiBaseUrl}/admin/cases/${caseId}/processes/${id}`;
    axios.get(url)
      .then(response => {
        const insertTime = dayjs.utc(response.data.insertTime).utcOffset(8).format('YYYY-MM-DD');
        setInsertTime(insertTime);
        setContent(response.data.content);
        if (response.data.pdfSrc) {
          setPdfSrc(response.data.pdfSrc);
          setRefType(1);
        } else {
          setProjectId(response.data.projectId);
          setRefType(2);
        }
      })
  }

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{id ? '編輯' : '新增'}進度</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className="required">日期</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              value={insertTime}
              onChange={({ target: { value } }) => setInsertTime(value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="required">進度描述/會議內容</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={content}
              onChange={({ target: { value } }) => setContent(value)}
            />
          </Form.Group>
          <InputGroup className="mb-3">
            <InputGroup.Text>類型</InputGroup.Text>
            <Button
              variant="outline-primary"
              active={refType === 1}
              onClick={() => {setRefType(1); setProjectId(null)}}
            >pdf 網址</Button>
            <Button
              variant="outline-primary"
              active={refType === 2}
              onClick={() => {setRefType(2); setPdfSrc(null)}}
            >相簿 id</Button>
          </InputGroup>
          <Form.Group className="mb-3">
            {refType === 1
              ? <Form.Control
                type="text"
                placeholder="請填入pdf 網址"
                value={pdfSrc}
                onChange={({ target: { value } }) => setPdfSrc(value)}
              />
              : <Form.Control
                type="number"
                placeholder="請填入相簿 id"
                value={projectId}
                onChange={({ target: { value } }) => setProjectId(value)}
              />
            }
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={submit}>
          {id ? '編輯' : '新增'}進度
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const DataTable = ({ loading, list, setModalShow, setId, setSize, loadTable, caseId }) => {
  const [deletedIds, setDeletedIds] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { successAlert } = useAlert();

  const handleDelete = async () => {
    if (deletedIds.length === 0) return;

    const url = `${apiBaseUrl}/admin/cases/${caseId}/processes`;
    const data = { ids: deletedIds };
    axios.delete(url, { data })
      .then(response => {
        loadTable();
        setDeletedIds([]);
        successAlert('批次刪除成功');
      })
    setShowDeleteConfirm(false);
  }

  return <>
    <Row>
      <Form.Group as={Col} sm={2} className="mb-3" controlId="process">
        <Form.Label>查詢筆數</Form.Label>
        <Form.Control
          as="select"
          onChange={({ target: { value } }) => {setSize(value); loadTable({ size: value }, () => setDeletedIds([]))}}
        >
          <option value={20}>20</option>
          <option value={10}>10</option>
          <option value={5}>5</option>
        </Form.Control>
      </Form.Group>
    </Row>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>id</th>
          <th>日期</th>
          <th width="650">進度描述/會議內容</th>
          <th>pdf 網址/相簿 id</th>
          <th>編輯</th>
          <th>
            <Button
              size="sm"
              variant="danger"
              onClick={() => setShowDeleteConfirm(true)}
              disabled={deletedIds.length === 0}
            >刪除</Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {loading
          ? <Spinner animation="border" />
          : list?.map(({ id, content, projectId, pdfSrc, insertTime }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{dayjs.utc(insertTime).utcOffset(8).format('YYYY/MM/DD')}</td>
              <td>{content}</td>
              <td>
                {projectId || ''}
                {pdfSrc ? <a className="normal-link" href={pdfSrc} target="_blank" rel="noreferrer">雲端連結網址</a> : ''}
              </td>
              <td >
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {setModalShow(true); setId(id)}}
                >編輯</Button>
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  label=""
                  onChange={(evt) => {
                    if (evt.target.checked)
                      setDeletedIds([...deletedIds, id]);
                    else
                      setDeletedIds(deletedIds.filter(i => i !== id));
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
    <ConfirmDeleteModal {...{
      show: showDeleteConfirm,
      handleClose: () => setShowDeleteConfirm(false),
      dataName: '進度',
      deletedIds,
      handleDelete,
    }} />
  </>
}

const Process = (props) => {
  const caseId = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState();
  const [size, setSize] = useState(20);
  const [modalShow, setModalShow] = useState(false);
  const [id, setId] = useState();
  const [caseNo, setCaseNo] = useState('');

  useEffect(() => {
    loadTable();
    loadCase();
  }, []);

  const loadTable = async (params = {}, callback) => {
    setLoading(true);
    const url = `${apiBaseUrl}/admin/cases/${caseId}/processes?page=${params.page || 1}&size=${params.size || size}`;
    axios.get(url)
      .then(response => {
        setList(response?.data?.list);
        setPaginationInfo(_.omit(response?.data, 'list'));
        setLoading(false);
        if (_.isFunction(callback)) callback();
      })
  };

  const loadCase = async () => {
    axios.get(`${apiBaseUrl}/admin/cases/${caseId}`)
      .then(response => setCaseNo(response.data.caseNo))
  }

  return <div className="admin">
    <Button variant="primary" className="new-btn" onClick={() => setModalShow(true)}>新增進度</Button>{' '}
    <h1 className="title">{caseNo} 進度</h1>
    {modalShow &&
      <WriteModal
        id={id}
        caseId={caseId}
        handleClose={() => {setModalShow(false); setId(null);}}
        loadTable={() => loadTable()}
      />
    }
    <DataTable {...{ loading, list, setId, setModalShow, loadTable, caseId, setSize }}/>
    <TablePagination {...{ paginationInfo, loading, loadTable }}/>
  </div>;
};

export default Process;
