import { useEffect, useState } from 'react';
import { Spinner, Table, Row, Col, Button, Form, Modal, InputGroup } from 'react-bootstrap';
import { TablePagination, ConfirmDeleteModal } from './Common';
import useAlert from '../../common/useAlert';

const { REACT_APP_BASE_URL: appBaseUrl, REACT_APP_API_BASE_URL: apiBaseUrl, REACT_APP_IMAGE_SIZE_4: imageSize4 } = process.env;
const axios = require('axios').default;
const _ = require('lodash');
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const BOOLEAN_TEXT_MAP = {
  true: '是',
  false: '否',
  1: '是',
  0: '否',
}

const WriteModal = ({ handleClose, loadTable, id }) => {
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [isTop, setIsTop] = useState(true);
  const [state, setState] = useState(0);
  const [newsOrder, setNewsOrder] = useState(0);
  const [image, setImage] = useState();
  const { successAlert, errorAlert } = useAlert();

  useEffect(() => {
    if (id) loadDetail(id);
  }, []);

  const submit = async () => {
    if (!title) return;
    if (newsOrder < 0) return errorAlert('排序不能小於 0');

    const url = `${apiBaseUrl}/admin/news`;
    const data = id
      ? { news: [{ id, title, content, isTop, newsOrder, state }] }
      : { title, content, isTop, newsOrder, state };
    const method = id ? 'patch' : 'post';
    const successMsg = id ? '編輯最新消息成功' : '新增最新消息成功';
    axios({ url, data, method })
      .then(response => {
        handleClose();
        loadTable();
        successAlert(successMsg);
      })
  };

  const submitImage = async (event) => {
    const image = event.target.files[0];
    if (!image) return;

    const data = new FormData();
    data.append('files',image);
    const url = `${apiBaseUrl}/admin/news/${id}/images`;
    axios.post(url, data)
      .then(response => {
        loadTable();
        successAlert('新增圖片成功')
      })
  };

  const deleteImage = async () => {
    if (!image) return;

    const url = `${apiBaseUrl}/admin/news/${id}/images`;
    axios.delete(url)
      .then(response => {
        loadTable();
        setImage(null);
        successAlert('刪除圖片成功');
      })
  }

  const loadDetail = async (id) => {
    const url = `${apiBaseUrl}/admin/news/${id}`;
    axios.get(url)
      .then(response => {
        setTitle(response.data.title);
        setContent(response.data.content);
        setIsTop(response.data.isTop);
        setNewsOrder(response.data.newsOrder);
        setState(response.data.state);
        setImage(response.data.image)
      })
  }

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{id ? '編輯' : '新增'}最新消息</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label className="required">標題</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder=""
              value={title}
              onChange={({ target: { value } }) => setTitle(value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="content">
            <Form.Label>內容</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={content}
              onChange={({ target: { value } }) => setContent(value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="newsOrder">
            <Form.Label>排序</Form.Label>
            <Form.Control
              type="number"
              value={newsOrder}
              onChange={({ target: { value } }) => setNewsOrder(value)}
            />
          </Form.Group>
          <Row>
            <Col className="mb-3" sm={3}>
              <Form.Label className="required">是否置頂</Form.Label>
              <InputGroup className="mb-3">
                <Button
                  variant="outline-primary"
                  active={isTop}
                  onClick={() => setIsTop(true)}
                >是</Button>
                <Button
                  variant="outline-primary"
                  active={!isTop}
                  onClick={() => setIsTop(false)}
                >否</Button>
              </InputGroup>
            </Col>
            <Col className="mb-3" sm={3}>
              <Form.Label className="required">是否顯示</Form.Label>
              <InputGroup className="mb-3">
                <Button
                  variant="outline-primary"
                  active={state}
                  onClick={() => setState(1)}
                >是</Button>
                <Button
                  variant="outline-primary"
                  active={!state}
                  onClick={() => setState(0)}
                >否</Button>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            {id && (
              image
                ? <>
                  <img src={`${appBaseUrl}${image[imageSize4]}`} width="200" alt=""/>
                  <Button
                    size="sm"
                    variant="danger"
                    className="mt-3"
                    onClick={() => deleteImage()}
                  >刪除圖片</Button>
                </>
                : <Form.Group className="mb-3" controlId="newsOrder">
                  <Form.Label>圖片</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={submitImage}
                  />
                </Form.Group>
            )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={submit}>
          {id ? '編輯' : '新增'}最新消息
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const TableFilter = ({ loadTable, setSize }) => {
  const [title, setTitle] = useState();
  const [isTop, setIsTop] = useState();
  const [state, setState] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const search = async () => {
    loadTable({ title, isTop, state, startTime, endTime });
  }

  return (
    <Row className="mb-3">
      <Form.Group as={Col} className="mb-3" controlId="name">
        <Form.Label>標題</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          onChange={({ target: { value } }) => setTitle(value)}
        />
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="isTop">
        <Form.Label>是否置頂</Form.Label>
        <Form.Control
          as="select"
          placeholder=""
          onChange={({ target: { value } }) => setIsTop(value)}
        >
          <option value="">全部</option>
          <option value={true}>是</option>
          <option value={false}>否</option>
        </Form.Control>
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="state">
        <Form.Label>是否顯示</Form.Label>
        <Form.Control
          as="select"
          placeholder=""
          onChange={({ target: { value } }) => setState(value)}
        >
          <option value="">全部</option>
          <option value={1}>是</option>
          <option value={0}>否</option>
        </Form.Control>
      </Form.Group>
      <Form.Group as={Col} className="mb-3">
        <Form.Label className="required">更新時間(起)</Form.Label>
        <Form.Control
          type="datetime-local"
          placeholder=""
          onChange={({ target: { value } }) => setStartTime(value)}
        />
      </Form.Group>
      <Form.Group as={Col} className="mb-3">
        <Form.Label className="required">更新時間(迄)</Form.Label>
        <Form.Control
          type="datetime-local"
          placeholder=""
          onChange={({ target: { value } }) => setEndTime(value)}
        />
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="size">
        <Form.Label>查詢筆數</Form.Label>
        <Form.Control
          as="select"
          onChange={({ target: { value } }) => setSize(value)}
        >
          <option value={20}>20</option>
          <option value={10}>10</option>
          <option value={5}>5</option>
        </Form.Control>
      </Form.Group>
      <Col>
        <Button variant="primary" className="custom-btn" as={Col} onClick={search}>查詢</Button>
      </Col>
    </Row>
  )
}

const DataTable = ({ loading, list, setModalShow, setId, loadTable }) => {
  const [deletedIds, setDeletedIds] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [updatedNews, setUpdatedNews] = useState({});
  const { successAlert, errorAlert } = useAlert();

  const handleDelete = async () => {
    if (deletedIds.length === 0) return;

    const url = `${apiBaseUrl}/admin/news`;
    const data = { ids: deletedIds };
    axios.delete(url, { data })
      .then(response => {
        loadTable();
        setDeletedIds([]);
        successAlert('批次刪除成功');
      })
    setShowDeleteConfirm(false);
  }

  const handleUpdate = async () => {
    const news = _.map(updatedNews, (newsOrder, id) => ({ id, newsOrder }));
    if (_.some(news, ({ newsOrder }) => newsOrder < 0)) {
      errorAlert('排序不能小於 0');
      return;
    }

    const url = `${apiBaseUrl}/admin/news`;
    axios.patch(url, { news })
      .then(response => {
        loadTable();
        setUpdatedNews({});
        successAlert('批次修改成功');
      })
  }

  return <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>id</th>
          <th>標題</th>
          <th>內容</th>
          <th>圖片縮圖</th>
          <th>
            排序 &nbsp;&nbsp;&nbsp;
            <Button
              size="sm"
              variant="primary"
              onClick={() => handleUpdate(true)}
              disabled={_.isEmpty(updatedNews)}
            >更新</Button>
          </th>
          <th>是否置頂</th>
          <th>是否顯示</th>
          <th>更新時間</th>
          <th width="70">編輯</th>
          <th width="70">
            <Button
              size="sm"
              variant="danger"
              onClick={() => setShowDeleteConfirm(true)}
              disabled={deletedIds.length === 0}
            >刪除</Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {loading
          ? <Spinner animation="border" />
          : list?.map(({ id, title, content, image, newsOrder, isTop, state, updateTime }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{title}</td>
              <td>{content}</td>
              <td>{image && <img src={`${appBaseUrl}${image[imageSize4]}`} width="200" alt=""/>}</td>
              <td>
                <Form.Control
                  className="order-input"
                  type="number"
                  value={updatedNews[id] === undefined ? newsOrder : updatedNews[id]}
                  onChange={(evt) => setUpdatedNews({ ...updatedNews, [id]: evt.target.value })}
                />
              </td>
              <td>{BOOLEAN_TEXT_MAP[isTop]}</td>
              <td>{BOOLEAN_TEXT_MAP[state]}</td>
              <td>{dayjs.utc(updateTime).utcOffset(8).format('YYYY/MM/DD HH:mm')}</td>
              <td>
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {setModalShow(true);setId(id)}}
                >編輯</Button>
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  label=""
                  onChange={(evt) => {
                    if (evt.target.checked)
                      setDeletedIds([...deletedIds, id]);
                    else
                      setDeletedIds(deletedIds.filter(i => i !== id));
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
    <ConfirmDeleteModal {...{
      show: showDeleteConfirm,
      handleClose: () => setShowDeleteConfirm(false),
      dataName: '最新消息',
      deletedIds,
      handleDelete,
    }} />
  </>
}

const News = () => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState();
  const [size, setSize] = useState(20);
  const [modalShow, setModalShow] = useState(false);
  const [id, setId] = useState();

  useEffect(() => {
    loadTable();
  }, []);

  const loadTable = async ({ page, title, isTop, state, startTime, endTime } = {}) => {
    setLoading(true);
    let url = `${apiBaseUrl}/admin/news?page=${page || 1}&size=${size}`;
    if (isTop) url += `&isTop=${isTop}`;
    if (title) url += `&title=${title}`;
    if (state) url += `&state=${state}`;
    if (startTime) url += `&startTime=${dayjs.utc(startTime).utcOffset(-8).format('YYYY-MM-DD HH:mm:ss')}`;
    if (endTime) url += `&endTime=${dayjs.utc(endTime).utcOffset(-8).format('YYYY-MM-DD HH:mm:ss')}`;

    axios.get(url)
      .then(response => {
        setList(response?.data?.list);
        setPaginationInfo(_.omit(response?.data, 'list'));
        setLoading(false);
      })
  };

  return <>
    <Button variant="primary" className="new-btn" onClick={() => setModalShow(true)}>新增最新消息</Button>{' '}
    <h1 className="title">最新消息</h1>
    {modalShow &&
      <WriteModal
        id={id}
        handleClose={() => {setModalShow(false); setId(null);}}
        loadTable={() => loadTable()}
      />
    }
    <TableFilter {...{ setLoading, setSize, loadTable }} />
    <DataTable {...{ loading, list, setId, setModalShow, loadTable }}/>
    <TablePagination {...{ paginationInfo, loadTable, loading }}/>
  </>;
};

export default News;
