
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Room, Pencil, WorkPlace, Office, Home, Helmet, CS, TW } from './Icons';

const AboutUs = (props) => {
  return (
    <div className="about-us">
      <div className="pageContainer">
        <section className="img-group">
          <div className="img-line img-line1"><img className="img" src="/images/about-bn1.jpg" alt="bn1" /></div>
          <div className="img-line img-line2"><img className="img" src="/images/about-bn2.jpg" alt="bn2" /></div>
          <div className="img-line img-line3"><img className="img" src="/images/about-bn3.jpg" alt="bn3" /></div>
        </section>
        <section className="section about">
          <div className="title">公司簡介</div>
          <div>
            新展設計於2021年成立於台北，是一間合法登記且具備建築物室內裝修工程專業技術執照的公司，設計團隊亦具有豐厚的工程與設計實際經驗，可提供業主完善細膩的服務與保固。
          </div>
        </section>
        <section className="section idea">
          <div className="title">設計理念</div>
          <div className="info">
            <p>
              <strong className="circle"><span>起</span></strong>
              利用現有空間
            </p>
            <p>
              在有限預算內
              <strong className="circle"><span>承</span></strong>
            </p>
            <p>
              <strong className="circle"><span>轉</span></strong>
              美學兼具的創造與合理的規劃
            </p>
            <p>
              打造最貼近客戶生活的空間
              <strong className="circle"><span>合</span></strong>
            </p>
          </div>
        </section>
        <section className="section">
          <Container>
            <div className="title">服務項目</div>
            <div className="items">
              <div className="item">
                <div className="icon"><Room /></div>
                <div className="info">住宅設計</div>
              </div>
              <div className="item">
                <div className="icon"><WorkPlace /></div>
                <div className="info">商空設計</div>
              </div>
              <div className="item">
                <div className="icon"><CS /></div>
                <div className="info">預售客變</div>
              </div>
              <div className="item">
                <div className="icon"><Office /></div>
                <div className="info">辦公室設計</div>
              </div>
              <div className="item">
                <div className="icon"><Home /></div>
                <div className="info">老屋翻新</div>
              </div>
              <div className="item">
                <div className="icon"><Helmet /></div>
                <div className="info">裝潢施工</div>
              </div>
              <div className="item">
                <div className="icon"><Pencil /></div>
                <div className="info">局部施工</div>
              </div>
            </div>
          </Container>
        </section>
        <section className="section">
          <Container fluid="sm">
            <div className="title">服務區域</div>
            <Row className="area">
              <Col sm={12} md={4} className="area-info">
                <div>北部地區．中部地區</div>
              </Col>
              <Col sm={12} md={8} className="icon-tw"><TW /></Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
