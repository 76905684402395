
import React, { useState, useEffect } from 'react';
import {Link, Route} from 'react-router-dom';
import { Container, Row, Col, } from 'react-bootstrap';
import { find } from 'lodash';
import _ from 'lodash';
const axios = require('axios').default;

const { REACT_APP_IMAGE_SIZE_3: imageSize3 } = process.env;

const Projects = ({baseUrl, img_url, location}) => {
  const [labels, setLabels] = useState([]);
  const [activeLabel, setActiveLabel] = useState(-1);
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    loadLabels();
  }, [])
  
  const loadLabels = async() => {
    const url = `${baseUrl}/projects/filters`;
    const response = await axios.get(url);
    if (response.status !== 200) return;
    let AllLabels = [{id: -1, name: "全部"}];
    setLabels([...AllLabels, ...response.data.filters]);
    const breadCrumbId = location.query ? _.find([...AllLabels, ...response.data.filters], {name: location.query}).id : -1;
    setActiveLabel(breadCrumbId);
    await filterAlbums(breadCrumbId);
  }

  const filterAlbums = async(filterId) => {
    const url = `${baseUrl}/projects${ filterId  < 0 ? '' : ('?filterId=' + filterId) }`;
    const response = await axios.get(url);
    if (response.status !== 200) return;
    filterId && setActiveLabel(filterId);
    setAlbums(response.data.projects);
  }


  return (
    <div className="projects">
      <div className="pageContainer">
        <section className="filter-section">
          {labels.length > 0 && labels.map(l => <div className={`label ${activeLabel === l.id ? 'active' : ''}`} key={l.id} onClick={() => filterAlbums(l.id)}>{l.name}</div>)}
        </section>
        <Container className="album-section">
          <Row>
              {albums.length > 0 && albums.map(album => <Col key={album.id} sm={12} md={6} lg={4}>
                <Link to={{
                  pathname: `/Projects/${album.id}`,
                  params:{filter: find(labels, v => v.id === activeLabel).name, name: album.name}
                }}>
                  <div className="album" data-name={album.name}>
                    {album.coverImage && album.coverImage[imageSize3] && <img src={img_url + album.coverImage[imageSize3]} alt={album.name} />}
                    <div className="album-name">{album.name}</div>
                  </div>
                </Link>
              </Col>)}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Projects;
