import { Pagination, Modal, Button } from 'react-bootstrap';

const TablePagination = ({ loading, loadTable, paginationInfo = {} }) => {
  const { isFirstPage, isLastPage, prePage, nextPage, pageNum, navigatepageNums, pages } = paginationInfo;

  if (loading) return null;

  return <div>
    <Pagination>
      <Pagination.First disabled={isFirstPage} onClick={() => loadTable({ page: 1 })}/>
      <Pagination.Prev disabled={isFirstPage} onClick={() => loadTable({ page: prePage })}/>
      {!navigatepageNums?.includes(1) && <Pagination.Ellipsis />}
      {navigatepageNums?.map(page => (
        <Pagination.Item key={page} active={page === pageNum} onClick={() => loadTable({ page })}>{page}</Pagination.Item>
      ))}
      {!navigatepageNums?.includes(pages) && <Pagination.Ellipsis />}
      <Pagination.Next disabled={isLastPage} onClick={() => loadTable({ page: nextPage })}/>
      <Pagination.Last disabled={isLastPage} onClick={() => loadTable({ page: pages })}/>
    </Pagination>
  </div>
}

const ConfirmDeleteModal = (props) => {
  const { dataName, show, handleClose, handleDelete, deletedIds } = props;
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>刪除{dataName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        確認刪除這 {deletedIds.length}筆 {dataName}？
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleDelete}>
          全部刪除
        </Button>
      </Modal.Footer>
    </Modal>
  )
};

export { TablePagination, ConfirmDeleteModal };
