import React, {useEffect, useState, useRef} from 'react';
import {ControlledCarousel} from './index';
import BoxTip from '../Layout/BoxTip';
import {Spinner} from 'react-bootstrap';
import {findIndex} from 'lodash';

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const { REACT_APP_IMAGE_SIZE_3: imageSize3 } = process.env;

const Home = (props) => {
  const {fetcher, img_url} = props;
  const [scroll, toggleScroll] = useState(false);
  const [allowscroll, setallow] = useState(true);
  const [allowClick, setClick] = useState(true);
  const [show, toggleShow] = useState(false);
  const [data, setData] = useState([]);
  const [selectNews, setSelectNews] = useState(0);
  const [selectNewsToggle, setSelectNewsToggle] = useState(false);
  const ref = useRef(null);

  useEffect(async () => {
    const result = await fetcher(`/news`);

    if (result.news) {
      const topNewsInd = findIndex(result.news, ['isTop', true]);
      result.news.splice(0, 1, ...result.news.splice(topNewsInd, 1, result.news[0]));
      setData(result.news);
    }
    if(ref.current){
      ref.current.addEventListener("wheel", function(event){handleScroll(event)}, { passive: false });
    }
    }, []);

  useEffect(() => {
    if (scroll){
      ref.current.scrollTop = ref.current.clientHeight;
    } 
    if (!scroll) ref.current.scrollTop = 0;
  }, [scroll])

  const handleScroll = (event) => {
    const currEvent = event.type;
    if(currEvent === 'click' || (currEvent === 'wheel' && event.deltaY>0)) {
      setallow(false);
      toggleScroll(true);
      setClick(false);
    }
  }

  const tipPropsPC = {
    type: 'scroll-pop-up',
    show: true,
    toggleShow: toggleScroll,
    setallow: setallow,
    setClick: setClick
  }

  const tipPropsRWD = {
    type: 'pop-up',
    show: show,
    toggleShow: toggleShow
  }

  return (
    <div className="home">
      <div className="pageContainer">
        <div className="content">
          <div className="container">
            <ControlledCarousel {...props} />
            <div className="news-wrap">
              <div className={`news-title ${allowClick ? 'click-abled' : ''}`}>
                <div className="arrow animate__infinite animate__animated animate__bounce">
                  <div className="left"/>
                  <div className="right"/>
                </div>
                <span onClick={(e)=>{handleScroll(e)}}>
                  最新消息
                </span>
              </div>
            </div>
            <div className="box-tip-wrap" ref={ref} style={{pointerEvents: allowscroll ? 'auto' : 'none'}}>
              <BoxTip {...tipPropsPC}>
                <div>
                  <p className="tip-title">最新消息</p>
                  {data.length === 0 ? <div>目前尚無最新消息。</div> :
                    <ul>
                      {data.map((d, ind) =>
                        <li key={ind}>
                          <p onClick={() => {
                              if(selectNews === ind){
                                !selectNewsToggle ? setSelectNewsToggle(true) : setSelectNewsToggle(false);
                              }else{
                                setSelectNewsToggle(false);
                              }
                              setSelectNews(ind);
                            }}
                            className={`title ${ind === selectNews && 'active'}`}>{dayjs.utc(d.updateTime).utcOffset(8).format('YYYY/MM/DD')}&emsp;{d.title}</p>
                          <p className={`news-content ${ind === selectNews && !selectNewsToggle ? 'show' : 'hidden'}`}>
                            {d.image && d.image[imageSize3] && <span className="news-image"><img src={img_url + d.image[imageSize3]} /></span>}
                            {d.content}
                            </p>
                        </li>
                      )}
                    </ul>
                  }
                </div>
              </BoxTip>
            </div>

            <div className="news-rwd">
              <p className="tip-title">最新消息</p>
              {data.length === 0 ? <div>目前尚無最新消息。</div> :
                <ul>
                  {data.map((d, ind) =>
                    <li key={ind} onClick={() => {
                      setSelectNews(ind)
                    }}>
                      <p className={`title ${ind === selectNews && 'active'}`}
                         onClick={() => toggleShow(true)}>{dayjs.utc(d.updateTime).utcOffset(8).format('YYYY/MM/DD')}&emsp;{d.title}</p>
                      {ind === selectNews &&
                      <BoxTip {...tipPropsRWD}>
                        <p>
                          {dayjs.utc(d.updateTime).utcOffset(8).format('YYYY/MM/DD')}
                          <br/>
                          <b>{d.title}</b>
                        </p>
                        {d.image && d.image[imageSize3] && <span className="news-image"><img src={img_url + d.image[imageSize3]} /></span>}
                        {d.content}
                      </BoxTip>
                      }
                    </li>
                  )}
                </ul>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
