import { useMemo } from 'react'
import axios from 'axios'
import useAlert from '../../common/useAlert';

const { REACT_APP_BASE_URL: appBaseUrl } = process.env;

const WithAxios = ({ children }) => {
  const { errorAlert } = useAlert();

  useMemo(() => {
    axios.interceptors.response.use(
      response => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = `${appBaseUrl}/api/login`;
          return;
        }
        errorAlert(error?.response?.data?.message, error?.response?.data?.code);
        return Promise.reject(error);
      })
  }, [errorAlert])

  return children
}

export default WithAxios
