import { useEffect, useState } from 'react';
import { Spinner, Table, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { TablePagination } from './Common';
import useAlert from '../../common/useAlert';

const { REACT_APP_API_BASE_URL: apiBaseUrl } = process.env;
const axios = require('axios').default;
const _ = require('lodash');
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const spaceTypeMap = {
  0: '新成屋',
  1: '預售屋',
  2: '老屋',
  3: '商業空間',
  4: '辦公室',
  5: '毛胚屋',
}

const MessageModal = ({ handleClose, message }) => {
  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>留言內容</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
    </Modal>
  )
}

const TableFilter = ({ loadTable, setSize }) => {
  const [name, setName] = useState();
  const [spaceType, setSpaceType] = useState();
  const [replied, setReplied] = useState();

  const search = async () => {
    loadTable({ spaceType, name, replied });
  }

  return (
    <Row className="mb-3">
      <Form.Group as={Col} className="mb-3" controlId="name">
        <Form.Label>姓名</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          onChange={({ target: { value } }) => setName(value)}
        />
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="process">
        <Form.Label>空間性質</Form.Label>
        <Form.Control
          as="select"
          onChange={({ target: { value } }) => setSpaceType(value)}
        >
          <option value="">全部</option>
          {_.map(spaceTypeMap, (name, id) => (
            <option key={id} value={id}>{name}</option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="replied">
        <Form.Label>是否已回覆</Form.Label>
        <Form.Control
          as="select"
          onChange={({ target: { value } }) => setReplied(value)}
        >
          <option value="">請選擇</option>
          <option value={1}>是</option>
          <option value={0}>否</option>
        </Form.Control>
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="size">
        <Form.Label>查詢筆數</Form.Label>
        <Form.Control
          as="select"
          onChange={({ target: { value } }) => setSize(value)}
        >
          <option value={20}>20</option>
          <option value={10}>10</option>
          <option value={5}>5</option>
        </Form.Control>
      </Form.Group>
      <Col>
        <Button variant="primary" className="custom-btn" as={Col} onClick={search}>查詢</Button>
      </Col>
    </Row>
  )
}

const DataTable = ({ loading, list, setMessage, loadTable }) => {
  const [repliedIds, setRepliedIds] = useState([]);
  const { successAlert } = useAlert();

  const handleUpdate = async () => {
    if (repliedIds.length === 0) return;

    const url = `${apiBaseUrl}/admin/contact`;
    const data = { ids: repliedIds };
    axios.patch(url, data)
      .then(response => {
        loadTable();
        setRepliedIds([]);
        successAlert('批次修改成功');
      })
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>id</th>
          <th>姓名</th>
          <th>電話</th>
          <th>Email</th>
          <th>Line ID</th>
          <th>空間性質</th>
          <th>坪數</th>
          <th>預算</th>
          <th>預計施工日</th>
          <th>預計住宿日</th>
          <th>留言內容</th>
          <th>
            <Button
              size="sm"
              variant="primary"
              onClick={() => handleUpdate()}
              disabled={repliedIds.length === 0}
            >已回覆</Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {loading
          ? <Spinner animation="border" />
          : list?.map(({ id, name, phoneNumber, email, lineId, spaceType, ping, budget, estimateEndTime, estimateStartTime, message, replied }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{name}</td>
              <td>{phoneNumber}</td>
              <td>{email}</td>
              <td>{lineId}</td>
              <td>{spaceTypeMap[spaceType]}</td>
              <td>{ping}</td>
              <td>{budget}萬</td>
              <td>{estimateStartTime ? dayjs.utc(estimateStartTime).utcOffset(8).format('YYYY-MM-DD') : '-'}</td>
              <td>{estimateEndTime ? dayjs.utc(estimateEndTime).utcOffset(8).format('YYYY-MM-DD') : '-'}</td>
              <td>
                {message
                  ? <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => setMessage(message)}
                  >留言內容</Button>
                  : '無'
                }
              </td>
              <td>
                {replied
                  ? 'v'
                  : <Form.Check
                  type="checkbox"
                  label=""
                  onChange={(evt) => {
                    if (evt.target.checked)
                      setRepliedIds([...repliedIds, id]);
                    else
                      setRepliedIds(repliedIds.filter(i => i !== id));
                  }}
                />
                }
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState();
  const [size, setSize] = useState(20);
  const [message, setMessage] = useState('');

  useEffect(() => {
    loadTable();
  }, []);

  const loadTable = async ({ page, spaceType, name, replied } = {}) => {
    setLoading(true);
    let url = `${apiBaseUrl}/admin/contact?page=${page || 1}&size=${size}`;
    if (name) url += `&name=${name}`;
    if (spaceType) url += `&spaceType=${spaceType}`;
    if (replied) url += `&replied=${replied}`;

    axios.get(url)
      .then(response => {
        setList(response?.data?.list);
        setPaginationInfo(_.omit(response?.data, 'list'));
        setLoading(false);
      })
  };

  return <>
    <h1 className="title">聯絡我們</h1>
    {message &&
      <MessageModal
        handleClose={() => setMessage('')}
        loadTable={() => loadTable()}
        message={message}
      />
    }
    <TableFilter {...{ setLoading, setSize, loadTable }} />
    <DataTable {...{ loading, list, setMessage, loadTable }}/>
    <TablePagination {...{ paginationInfo, loadTable, loading }}/>
  </>;
};

export default Contact;
