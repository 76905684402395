import React, { useState, useCallback } from 'react';


export const AlertContext = React.createContext({
  alert: null,
  successAlert: () => {},
  errorAlert: () => {},
});

export default function AlertProvider ({ children }) {
  const [alert, setAlert] = useState(null);

  const contextValue = {
    alert,
    successAlert: useCallback((message, code) => {
      setAlert({ message, code, status: 'success' });
      setTimeout(() => setAlert(null), 5000)
    }, []),
    errorAlert: useCallback((message, code) => {
      setAlert({ message: message || '伺服器錯誤', code, status: 'error' });
      setTimeout(() => setAlert(null), 5000)
    }, []),
  }

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
}
