import { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import useAlert from '../../common/useAlert';
import { Spinner, Table, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { TablePagination, ConfirmDeleteModal } from './Common';

const { REACT_APP_BASE_URL: appBaseUrl, REACT_APP_API_BASE_URL: apiBaseUrl, REACT_APP_IMAGE_SIZE_4: imageSize4 } = process.env;
const axios = require('axios').default;
const _ = require('lodash');
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const WriteModal = ({ handleClose, loadTable, id, projectId }) => {
  const [images, setImages] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const { successAlert } = useAlert();
  const onDrop = useCallback(acceptedFiles => {
    const acceptedImages = acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));
    setImages([
      ...images,
      ...acceptedImages,
    ]);
  }, [images]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'image/*', onDrop });

  const submit = async () => {
    const data = new FormData();
    images.forEach(image => {
      data.append('files',image);
    })
    setSubmitting(true);
    const url = `${apiBaseUrl}/admin/projects/${projectId}/images`;
    axios.post(url, data)
      .then(response => {
        handleClose();
        loadTable();
        successAlert('新增照片成功')
      })
      .catch(() => setSubmitting(false))
  };
  return (
    <Modal show={true} onHide={handleClose} className="image-dropzone">
      <Modal.Header closeButton>
        <Modal.Title>{id ? '編輯' : '新增'}照片</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="dropzone-container">
          <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} ${isDragReject ? 'reject' : ''} ${isDragAccept ? 'accept' : ''}`}>
            <input {...getInputProps()} />
            <p>拖放照片或是點擊以選擇照片</p>
          </div>
        </div>
        {images.map((image, index) => (
          <div key={index} className="preview-container">
            <img width="300" src={image.preview} alt="preview"/>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={submit} disabled={submitting}>
          {submitting && <Spinner animation="border" size="sm"/>}
          {submitting ? ' 照片上傳中' : id ? '編輯照片' : '新增照片'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const DataTable = ({ loading, list, setModalShow, setId, setSize, loadTable, projectId }) => {
  const [deletedIds, setDeletedIds] = useState([]);
  const [updatedImage, setUpdatedImage] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { successAlert, errorAlert } = useAlert();

  const handleDelete = async () => {
    if (deletedIds.length === 0) return;

    const url = `${apiBaseUrl}/admin/projects/${projectId}/images`;
    const data = { ids: deletedIds };
    axios.delete(url, { data })
      .then(response => {
        loadTable();
        setDeletedIds([]);
        successAlert('批次刪除成功');
      })
    setShowDeleteConfirm(false);
  }

  const handleUpdate = async () => {
    const projectImages = _.map(updatedImage, (payload, id) => ({ id, ...payload }));
    if (_.some(projectImages, ({ imageOrder }) => imageOrder < 0)) {
      errorAlert('排序不能小於 0');
      return;
    }
    const url = `${apiBaseUrl}/admin/projects/${projectId}/images`;
    axios.patch(url, { projectImages })
      .then(response => {
        loadTable();
        setUpdatedImage({});
        successAlert('批次修改成功');
      })
  }

return <>
    <Row>
      <Form.Group as={Col} sm={2} className="mb-3" controlId="process">
        <Form.Label>查詢筆數</Form.Label>
        <Form.Control
          as="select"
          onChange={({ target: { value } }) => {setSize(value); loadTable({ size: value }, () => setDeletedIds([]))}}
        >
          <option value={20}>20</option>
          <option value={10}>10</option>
          <option value={5}>5</option>
        </Form.Control>
      </Form.Group>
      <Col sm={{ span: 2, offset: 6 }}>
        <Button
          variant="primary"
          className="custom-btn"
          onClick={handleUpdate}
          disabled={_.isEmpty(updatedImage)}
        >修改</Button>
      </Col>
    </Row>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>id</th>
          <th>照片</th>
          <th>是否為封面</th>
          <th>排序</th>
          <th>
            <Button
              size="sm"
              variant="danger"
              onClick={() => setShowDeleteConfirm(true)}
              disabled={deletedIds.length === 0}
            >刪除</Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {loading
          ? <Spinner animation="border" />
          : list?.map(({ id, isCover, imageOrder, srcs, insertTime }) => (
            <tr key={id}>
              <td>{id}</td>
              <td><img src={`${appBaseUrl}${srcs[imageSize4]}`} width="200" alt=""/></td>
              <td>
                <Form.Check
                  type="checkbox"
                  label=""
                  checked={updatedImage[id]?.isCover === undefined ? isCover : updatedImage[id]?.isCover}
                  onChange={(evt) => {setUpdatedImage({
                    ...updatedImage,
                    [id]: { ...updatedImage[id], isCover: evt.target.checked } }
                  )}}
                />
                </td>
              <td>
                <Form.Control
                  className="order-input"
                  type="number"
                  value={updatedImage[id]?.imageOrder === undefined ? imageOrder : updatedImage[id]?.imageOrder}
                  onChange={(evt) => {setUpdatedImage({
                    ...updatedImage,
                    [id]: { ...updatedImage[id], imageOrder: evt.target.value } }
                  )}}
                />
              </td>
              <td>
                <Form.Check
                  type="checkbox"
                  label=""
                  onChange={(evt) => {
                    if (evt.target.checked)
                      setDeletedIds([...deletedIds, id]);
                    else
                      setDeletedIds(deletedIds.filter(i => i !== id));
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
    <ConfirmDeleteModal {...{
      show: showDeleteConfirm,
      handleClose: () => setShowDeleteConfirm(false),
      dataName: '照片',
      deletedIds,
      handleDelete,
    }} />
  </>
}

const Image = (props) => {
  const projectId = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState();
  const [size, setSize] = useState(20);
  const [modalShow, setModalShow] = useState(false);
  const [id, setId] = useState();
  const [projectName, setProjectName] = useState();

  useEffect(() => {
    loadTable();
    loadProject();
  }, []);

  const loadTable = async (params = {}, callback) => {
    setLoading(true);
    const url = `${apiBaseUrl}/admin/projects/${projectId}/images?page=${params.page || 1}&size=${params.size || size}`;

    axios.get(url)
      .then(response => {
        setList(response?.data?.list);
        setPaginationInfo(_.omit(response?.data, 'list'));
        setLoading(false);
        if (_.isFunction(callback)) callback();
      })
  };

  const loadProject = async () => {
    axios.get(`${apiBaseUrl}/admin/projects/${projectId}`)
      .then(response => setProjectName(response.data.name));
  }

  return <div className="admin">
    <Button variant="primary" className="new-btn" onClick={() => setModalShow(true)}>新增照片</Button>{' '}
    <h1 className="title">{projectName} 照片</h1>
    {modalShow &&
      <WriteModal
        id={id}
        projectId={projectId}
        handleClose={() => {setModalShow(false); setId(null);}}
        loadTable={() => loadTable()}
      />
    }
    <DataTable {...{ loading, list, setId, setModalShow, loadTable, projectId, setSize }}/>
    <TablePagination {...{ paginationInfo, loading, loadTable }}/>
  </div>;
};

export default Image;
