import React, {useEffect, useRef} from 'react';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BoxTip = ({type, text, show, toggleShow, setallow, setClick, children}) => {
  const ref2 = useRef(null);

  const style = show ? {opacity: 1} : {opacity: 0, pointerEvents: 'none'};

  if (type === 'pop-up') {
    return (
      <div className="box-tip" style={{pointerEvents: 'auto', ...style}}>
      <span ref={ref2}>
         <div className="box-tip-header">
          <div className="close" onClick={() => {
            toggleShow(false);
          }}><FontAwesomeIcon icon={faTimes}/></div>
        </div>
         <div className="box-tip-content">
          {children}
        </div>
      </span>
        <div className="mask"/>
      </div>
    );
  }

  if (type === 'scroll-pop-up') {
    return (
      <div className="box-tip" style={{pointerEvents: 'auto', ...style}}>
      <span ref={ref2}>
        <div className="box-tip-header">
          <div className="close" onClick={() => {
            toggleShow(false);
            setallow(false);
            setTimeout(() => {
              setClick(true);
              setallow(true);
            }, 500);
          }}><FontAwesomeIcon icon={faTimes}/></div>
        </div>
        <div className="box-tip-content">
          {children}
        </div>
      </span>
      </div>
    );
  }
};

export default BoxTip;
