import { useEffect, useState } from 'react';
import useAlert from '../../common/useAlert';
import { Spinner, Table, Button, Form } from 'react-bootstrap';
import { ConfirmDeleteModal } from './Common';

const { REACT_APP_BASE_URL: appBaseUrl, REACT_APP_API_BASE_URL: apiBaseUrl, REACT_APP_IMAGE_SIZE_4: imageSize4 } = process.env;
const axios = require('axios').default;
const _ = require('lodash');
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);


const DataTable = ({ loading, banners, loadTable, projectId }) => {
  const [deletedIds, setDeletedIds] = useState([]);
  const [updatedBanner, setUpdatedBanner] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [updatingBanner, setUpdatingBanner] = useState([]);
  const { successAlert, errorAlert } = useAlert();

  const handleDelete = async () => {
    if (deletedIds.length === 0) return;

    const url = `${apiBaseUrl}/admin/banners`;
    const data = { ids: deletedIds };
    axios.delete(url, { data })
      .then(response => {
        loadTable();
        setDeletedIds([]);
        successAlert('批次刪除成功');
      })
    setShowDeleteConfirm(false);
  }

  const handleUpdate = async () => {
    const banners = _.map(updatedBanner, (bannerOrder, id) => ({ id, bannerOrder }));
    if (_.some(banners, ({ bannerOrder }) => bannerOrder < 0)) {
      errorAlert('排序不能小於 0');
      return;
    }
    const url = `${apiBaseUrl}/admin/banners`;
    axios.patch(url, banners)
      .then(response => {
        loadTable();
        setUpdatedBanner({});
        successAlert('批次修改成功');
      })
  }

  const submit = async (event, index) => {
    const image = event.target.files[0];
    if (!image) return;

    setUpdatingBanner([...updatingBanner, index]);
    const data = new FormData();
    data.append('files', image);
    const url = `${apiBaseUrl}/admin/banners`;
    axios.post(url, data)
      .then(response => {
        loadTable(() => setUpdatingBanner([]));
        successAlert('新增照片成功')
      })
  };

  return <>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>id</th>
          <th>照片</th>
          <th>
            排序 &nbsp;&nbsp;&nbsp;
            <Button
              size="sm"
              variant="primary"
              onClick={() => handleUpdate(true)}
              disabled={_.isEmpty(updatedBanner)}
            >更新</Button>
          </th>
          <th>
            <Button
              size="sm"
              variant="danger"
              onClick={() => setShowDeleteConfirm(true)}
              disabled={deletedIds.length === 0}
            >刪除</Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {loading
          ? <Spinner animation="border" />
          : _.range(5).map((index) => {
            const { id, bannerOrder, urls } = banners[index] || {};
            return <tr key={id}>
              <td>{id || ''}</td>
              <td>{id
                ? <img src={`${appBaseUrl}${urls[imageSize4]}`} width="200" alt=""/>
                : updatingBanner.includes(index)
                  ? <Spinner animation="border" />
                  : <input type="file" onChange={evt => submit(evt, index)}></input>
              }</td>
              <td>
                {id && <Form.Control
                  className="order-input"
                  type="number"
                  value={updatedBanner[id] === undefined ? bannerOrder : updatedBanner[id]}
                  onChange={(evt) => setUpdatedBanner({ ...updatedBanner, [id]: evt.target.value })}
                />}
              </td>
              <td>
                {id && <Form.Check
                  type="checkbox"
                  label=""
                  onChange={(evt) => {
                    if (evt.target.checked)
                      setDeletedIds([...deletedIds, id]);
                    else
                      setDeletedIds(deletedIds.filter(i => i !== id));
                  }}
                />}
              </td>
            </tr>
          })}
      </tbody>
    </Table>
    <ConfirmDeleteModal {...{
      show: showDeleteConfirm,
      handleClose: () => setShowDeleteConfirm(false),
      dataName: 'Banner',
      deletedIds,
      handleDelete,
    }} />
  </>
}

const Banner = (props) => {
  const [loading, setLoading] = useState(true);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    loadTable();
  }, []);

  const loadTable = async (callback) => {
    setLoading(true);
    const url = `${apiBaseUrl}/admin/banners`;

    axios.get(url)
      .then(response => {
        setBanners(response?.data?.banners);
        setLoading(false);
        if (_.isFunction(callback)) callback();
      })
  };

  return <div className="admin">
    <h1 className="title">Banner</h1>
    <DataTable {...{ loading, banners, loadTable }}/>
  </div>;
};

export default Banner;
