import React, {useEffect, useState} from 'react';
import {map} from 'lodash';
import {Carousel} from 'react-bootstrap';

const { REACT_APP_BASE_URL: img_url, REACT_APP_IMAGE_SIZE_1: imageSize1 } = process.env;

function ControlledCarousel({fetcher}) {
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(async () => {
    const result = await fetcher(`/banners`);
    setData(result.banners);
  }, []);

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {map(data, (img, ind) => {
        return <Carousel.Item key={ind} interval={3000}>
          {img.urls && img.urls[imageSize1] && <img
            className="d-block w-100"
            src={img_url + img.urls[imageSize1]}
            alt="First slide"
          />}
        </Carousel.Item>
      })}
    </Carousel>
  );
}

export default ControlledCarousel;