
import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import Menu from './Menu';
import BoxTip from './BoxTip';
import { Button, InputGroup, FormControl } from 'react-bootstrap';

const Logo = (props) => {
  const [show, toggleShow] = useState(false);

  return (
    <div className="logo-block">
      <NavLink to='/'>
      <div className="logo-wrap">
        <img src="/images/logo.png" />
        </div>
      <span>新展室內裝修</span>
      </NavLink>
    </div>
  );
};

export default Logo;
