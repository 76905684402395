import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Navbar, Nav, NavItem} from 'react-bootstrap';
import {map} from 'lodash';

const Menu = (props) => {
  const { width, path } = props;
  const [expanded, setExpanded] = useState(false);
  const menuValues = [
    {link: '/AboutUs', cn: '關於我們', en: 'About Us'},
    {link: '/Projects', cn: '作品集', en: 'Projects'},
    {link: '/Service', cn: '客戶服務', en: 'Service'},
    {link: '/ContactUs', cn: '聯絡我們', en: 'Contact Us'},
    {link: '/FollowUp', cn: '進度查詢', en: 'Follow Up'},
  ]

  const renderNavItem = () => {
    return map(menuValues, (value, ind) => {
      if (width < 1025) {
        return (
          <NavItem key={ind} href={value.link}>
            <Nav.Link as={Link} to={value.link}>{value.cn + " " + value.en}</Nav.Link>
          </NavItem>
        );
      } else {
        return (
          <NavItem key={ind} href="/" className={`${path.includes(value.link) ? "active" : ""}`}>
            <Nav.Link className={`${path.includes(value.link) ? "active" : (path !== "/") ? "show gray" : "show"}`} as={Link} to={value.link}>{value.en}</Nav.Link>
            <Nav.Link className="hidden" as={Link} to={value.link}>{value.cn}</Nav.Link>
          </NavItem>
        )
      }
    });
  }

  return (
    <div className="menu">
      <Navbar expanded={expanded}
              expand="xl">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className={`mr-auto my-2 my-xl-0`}
            onClick={() => setExpanded(false)}
            navbarScroll
          >
            {renderNavItem()}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Menu;
