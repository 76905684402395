
import React from 'react';

const Room = () => {
  return <svg id="Layer_1" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g clipRule="evenodd" fillRule="evenodd">
        <path d="m320.291 418.045v-40.793c0-11.82-9.666-21.457-21.487-21.457h-255.462c-11.792 0-21.458 9.637-21.458 21.457v40.793z" fill="#80b4fb" />
        <path d="m320.291 418.045v-40.793c0-11.82-9.666-21.457-21.487-21.457h-29.991c11.792 0 21.458 9.637 21.458 21.457v40.793z" fill="#61a2f9" />
        <path d="m298.804 355.795v-67.637c0-11.82-9.667-21.457-21.459-21.457h-212.544c-11.792 0-21.459 9.666-21.459 21.457v67.637z" fill="#f5bc98" />
        <path d="m298.804 355.795v-67.637c0-11.82-9.667-21.457-21.459-21.457h-29.99c11.792 0 21.458 9.637 21.458 21.457v67.637z" fill="#e59b6c" />
        <path d="m266.602 355.795v-28.658c0-8.73-7.144-15.902-15.902-15.902h-34.044c-8.731 0-15.902 7.172-15.902 15.902v28.658zm-125.208 0v-28.658c0-8.73-7.172-15.902-15.902-15.902h-34.045c-8.73 0-15.902 7.172-15.902 15.902v28.658z" fill="#e9f5ff" />
        <path d="m266.602 355.795v-28.658c0-8.73-7.144-15.902-15.902-15.902h-29.991c8.73 0 15.903 7.172 15.903 15.902v28.658zm-125.208 0v-28.658c0-8.73-7.172-15.902-15.902-15.902h-29.991c8.731 0 15.903 7.172 15.903 15.902v28.658z" fill="#cbe2ff" />
        <path d="m6.01 496.141h330.155c3.288 0 5.98-2.723 5.98-6.01v-66.105c0-3.287-2.692-5.98-5.98-5.98h-330.155c-3.316 0-6.01 2.693-6.01 5.98v66.105c0 3.287 2.693 6.01 6.01 6.01z" fill="#d68756" />
        <path d="m306.146 496.141h30.02c3.288 0 5.98-2.723 5.98-6.01v-66.105c0-3.287-2.692-5.98-5.98-5.98h-30.02c3.317 0 6.01 2.693 6.01 5.98v66.105c-.001 3.287-2.693 6.01-6.01 6.01z" fill="#c47443" />
        <path d="m342.146 457.078v-33.053c0-3.287-2.692-5.98-5.98-5.98h-330.156c-3.316 0-6.01 2.693-6.01 5.98v33.053z" fill="#f5bc98" />
        <path d="m342.146 457.078v-33.053c0-3.287-2.692-5.98-5.98-5.98h-30.02c3.317 0 6.01 2.693 6.01 5.98v33.053z" fill="#e59b6c" />
      </g>
      <path d="m446.943 302.105c0-4.139 3.374-7.483 7.513-7.483 4.138 0 7.482 3.344 7.482 7.483v53.689c0 4.139-3.345 7.482-7.482 7.482-4.139 0-7.513-3.344-7.513-7.482z" fill="#365e7d" />
      <path clipRule="evenodd" d="m506.019 496.141h-103.125c-3.288 0-6.01-2.723-6.01-6.01v-128.356c0-3.287 2.722-5.98 6.01-5.98h103.125c3.288 0 5.981 2.693 5.981 5.98v128.355c0 3.288-2.693 6.011-5.981 6.011z" fill="#d68756" fillRule="evenodd" />
      <path clipRule="evenodd" d="m506.019 496.141h-30.019c3.316 0 6.01-2.723 6.01-6.01v-128.356c0-3.287-2.693-5.98-6.01-5.98h30.019c3.288 0 5.981 2.693 5.981 5.98v128.355c0 3.288-2.693 6.011-5.981 6.011z" fill="#c47443" fillRule="evenodd" />
      <path clipRule="evenodd" d="m396.884 406.565v-44.789c0-3.287 2.722-5.98 6.01-5.98h103.125c3.288 0 5.981 2.693 5.981 5.98v44.789z" fill="#f5bc98" fillRule="evenodd" />
      <path clipRule="evenodd" d="m476 355.795h30.019c3.288 0 5.981 2.693 5.981 5.98v44.789h-29.99v-44.789c0-3.287-2.694-5.98-6.01-5.98z" fill="#e59b6c" fillRule="evenodd" />
      <path d="m472.06 441.459c0-4.139 3.345-7.512 7.483-7.512 4.139 0 7.512 3.373 7.512 7.512v19.785c0 4.139-3.373 7.512-7.512 7.512s-7.483-3.373-7.483-7.512z" fill="#365e7d" />
      <path clipRule="evenodd" d="m425.032 208.73h58.848c3.288 0 5.981 2.693 5.981 5.982v81.412c0 3.289-2.693 5.98-5.981 5.98h-58.848c-3.289 0-6.01-2.691-6.01-5.98v-81.412c0-3.288 2.721-5.982 6.01-5.982z" fill="#dd636e" fillRule="evenodd" />
      <path clipRule="evenodd" d="m453.86 208.73h30.02c3.288 0 5.981 2.693 5.981 5.982v81.412c0 3.289-2.693 5.98-5.981 5.98h-30.02c3.316 0 6.01-2.691 6.01-5.98v-81.412c0-3.288-2.693-5.982-6.01-5.982z" fill="#da4a54" fillRule="evenodd" />
      <path clipRule="evenodd" d="m114.776 15.859h282.476c2.155 0 3.912 1.758 3.912 3.912v139.523c0 2.154-1.757 3.939-3.912 3.939h-282.476c-2.154 0-3.94-1.785-3.94-3.939v-139.523c0-2.154 1.786-3.912 3.94-3.912z" fill="#e9f5ff" fillRule="evenodd" />
      <path clipRule="evenodd" d="m401.164 159.324c0 2.154-1.757 3.91-3.912 3.91h-121.92l76.508-89.744c2.184-2.496 4.31-2.496 6.379 0l42.945 50.371z" fill="#95d6a4" fillRule="evenodd" />
      <path clipRule="evenodd" d="m208.038 163.234h-93.261c-2.154 0-3.94-1.785-3.94-3.939v-11.367l38.892-45.609c2.069-2.154 4.195-2.184 6.35 0z" fill="#95d6a4" fillRule="evenodd" />
      <path clipRule="evenodd" d="m350.196 163.234h-180.71l45.185-52.981 41.981-49.236c2.069-2.494 4.196-2.637 6.35 0l42.01 49.236z" fill="#ccf49f" fillRule="evenodd" />
      <path clipRule="evenodd" d="m186.976 81.652c10.517 0 19.078-8.561 19.078-19.076 0-10.488-8.561-19.078-19.078-19.078s-19.077 8.59-19.077 19.078c-.001 10.516 8.56 19.076 19.077 19.076z" fill="#ffe07d" fillRule="evenodd" />
      <path clipRule="evenodd" d="m340.02 87.352 61.145 71.687v-35.178l-42.946-50.371c-2.069-2.496-4.195-2.496-6.379 0z" fill="#78c2a4" fillRule="evenodd" />
      <path clipRule="evenodd" d="m244.831 74.879 75.375 88.356h29.99l-87.194-102.217c-2.154-2.637-4.281-2.494-6.35 0z" fill="#b3e59f" fillRule="evenodd" />
      <path clipRule="evenodd" d="m188.761 140.615-32.684-38.297c-2.154-2.184-4.28-2.154-6.35 0l-11.821 13.861 35.859 42.037z" fill="#78c2a4" fillRule="evenodd" />
      <path d="m419.022 232.912h70.839v14.994h-70.839zm0 30.02h70.839v14.994h-70.839z" fill="#d82e3d" />
    </g>
  </svg>
}

const Pencil = () => {
  return <svg viewBox="1 0 511.99988 511" xmlns="http://www.w3.org/2000/svg">
  <path d="m498.839844 372.367188-358.707032-358.742188c-17.5-17.5-45.980468-17.5-63.488281 0l-63.484375 63.492188c-17.542968 17.542968-17.550781 45.941406 0 63.492187 11.609375 11.613281 351.136719 351.167969 358.707032 358.738281 17.480468 17.484375 45.9375 17.53125 63.488281 0l63.484375-63.488281c17.542968-17.546875 17.546875-45.945313 0-63.492187zm0 0" fill="#d6f3fe" />
  <path d="m44.886719 45.378906-31.726563 31.738282c-17.539062 17.539062-17.550781 45.9375 0 63.488281 3.277344 3.277343 32.757813 32.765625 73.535156 73.546875l21.171876 21.167968v.011719c52.304687 52.304688 117.015624 117.023438 169.292968 169.292969v.007812c2.339844 2.339844 4.648438 4.648438 6.9375 6.941407 2.390625 2.378906 4.742188 4.738281 7.058594 7.058593l7.171875 7.167969c41.566406 41.578125 71.355469 71.367188 73.535156 73.546875 17.480469 17.480469 45.941407 17.53125 63.488281 0l31.757813-31.746094zm0 0" fill="#bdecfc" />
  <path d="m318.589844 66.671875-59.039063 58.902344-215.625 215.097656c-.511719.511719-.980469 1.058594-1.398437 1.628906l127.132812 127.140625c.777344-.527344 1.515625-1.136718 2.183594-1.804687l273.835938-273.859375zm0 0" fill="#7280f8" />
  <path d="m382.203125 130.292969-58.976563 58.96875-216.871093 216.871093 63.308593 63.308594c.097657-.070312.199219-.140625.289063-.210937.238281-.167969.46875-.351563.699219-.53125.230468-.1875.449218-.378907.671875-.570313l.007812-.007812c.171875-.160156.339844-.320313.511719-.480469l214.871094-214.890625 58.96875-58.976562zm0 0" fill="#434af2" />
  <path d="m40.023438 347.4375-38.40625 145.160156c-1.367188 5.167969.125 10.675782 3.914062 14.445313 2.84375 2.820312 6.648438 4.347656 10.550781 4.347656 1.304688 0 2.621094-.167969 3.914063-.519531l145.183594-39.375c2.292968-.625 4.394531-1.785156 6.148437-3.378906l-127.417969-127.433594c-1.871094 1.871094-3.210937 4.199218-3.886718 6.753906zm0 0" fill="#f2d2ad" />
  <path d="m171.332031 468.121094-.007812.011718c-.222657.1875-.441407.378907-.671875.570313-.230469.179687-.460938.359375-.699219.527344-.101563.070312-.199219.140625-.300781.203125-1.359375.929687-2.867188 1.628906-4.46875 2.058594l-145.183594 39.378906c-1.300781.347656-2.609375.519531-3.921875.519531-3.898437 0-7.707031-1.519531-10.546875-4.351563-.011719-.007812-.03125-.027343-.042969-.039062l102.367188-102.367188zm0 0" fill="#eebf92" />
  <path d="m87.410156 130.210938-42.328125 42.324218-21.160156-21.160156 42.328125-42.332031c5.84375-5.835938 15.3125-5.835938 21.160156 0 5.84375 5.84375 5.84375 15.324219 0 21.167969zm0 0" fill="#4f84cf" />
  <path d="m171.355469 171.835938-63.488281 63.492187-21.167969-21.171875 63.488281-63.492188c5.84375-5.835937 15.320312-5.835937 21.167969 0 5.835937 5.847657 5.835937 15.324219 0 21.171876zm0 0" fill="#66a9df" />
  <path d="m361.8125 362.308594-63.484375 63.492187-21.171875-21.167969 63.488281-63.492187c5.84375-5.847656 15.320313-5.847656 21.167969 0 5.835938 5.84375 5.835938 15.324219 0 21.167969zm0 0" fill="#66a9df" />
  <g fill="#4f84cf">
      <path d="m86.710938 214.152344 63.488281-63.480469 21.171875 21.171875-63.492188 63.480469zm0 0" />
      <path d="m361.816406 362.304688-63.488281 63.5-7.171875-7.171876-13.996094-14v-.007812l63.484375-63.488281zm0 0" />
      <path d="m403.433594 446.253906-42.328125 42.332032-21.160157-21.164063 42.316407-42.328125c5.847656-5.847656 15.324219-5.847656 21.171875 0 5.835937 5.84375 5.835937 15.3125 0 21.160156zm0 0" />
  </g>
  <path d="m485.015625 27.378906c-34.976563-34.980468-91.929687-35.019531-126.957031-.082031l-35.476563 35.386719 127.089844 127.097656 35.34375-35.347656c35.027344-35.027344 35.027344-92.023438 0-127.054688zm0 0" fill="#dd5c60" />
  <path d="m485.019531 154.433594-35.347656 35.347656-63.476563-63.488281 98.863282-98.863281h.011718c34.96875 35.027343 34.949219 91.996093-.050781 127.003906zm0 0" fill="#d8333c" />
  <path d="m322.699219 62.582031-63.148438 62.992188 127.164063 127.171875 63.066406-63.074219zm0 0" fill="#eebb2c" />
  <path d="m386.304688 126.183594-.109376.109375-3.992187 4-58.976563 58.96875 63.488282 63.488281 58.96875-58.976562 3.988281-3.992188.109375-.109375zm0 0" fill="#e79b34" />
</svg>
}

const WorkPlace = () => {
  return <svg id="Capa_1" enableBackground="new 0 0 512 512"  viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <path d="m318.578 157.113v20.505c0 8.054-3.065 15.805-8.574 21.681l-20.033 21.369 48.052 25.773 48.052-25.773-20.033-21.369c-5.508-5.876-8.573-13.627-8.573-21.681v-20.505l-21.629-20.113z" fill="#74a4b7" />
      </g>
      <g>
        <path d="m71.216 169.753h-33.378v-46.507c0-8.947 7.253-16.2 16.2-16.2h.978c8.947 0 16.2 7.253 16.2 16.2z" fill="#0ed2b3" />
      </g>
      <g>
        <path d="m82.724 223.652h-39.704l-4.9-55.332c0-1.105 4.347-11.721 5.452-11.721h37.152c1.105 0 2 .895 2 2z" fill="#eef5f6" />
      </g>
      <g>
        <path d="m45.08 156.599h-16.688c-1.138 0-2.06.923-2.06 2.06v64.993h16.688v-64.992c0-1.138.923-2.061 2.06-2.061z" fill="#deecf1" />
      </g>
      <g>
        <path d="m463.476 226.732h-43.125v-24.592c0-5.523 4.477-10 10-10h23.125c5.523 0 10 4.477 10 10z" fill="#deecf1" />
      </g>
      <g>
        <path d="m445.636 389.305h-355.742l-43.546-147.155h414.287z" fill="#ecbba3" />
      </g>
      <g>
        <path d="m46.348 242.15h43.546v147.155h-43.546z" fill="#e8ad8c" />
      </g>
      <g>
        <path d="m216.617 423.297-12.115-1.726-72.518 81.863c-2.944 3.324-.585 8.566 3.856 8.566h25.486c1.488 0 2.904-.644 3.882-1.765l51.409-58.942z" fill="#a8d3d8" />
      </g>
      <g>
        <path d="m219.853 423.297 12.115-1.726 72.518 81.863c2.944 3.324.585 8.566-3.856 8.566h-25.486c-1.488 0-2.904-.644-3.882-1.765l-51.409-58.942z" fill="#a8d3d8" />
      </g>
      <g>
        <path d="m333.43 136.28h63.491l44.994-12.545v-113.735c0-5.523-4.477-10-10-10h-169.635c-5.523 0-13.44 4.477-13.44 10l-8.241 76.84h61.521z" fill="#c3ddff" />
      </g>
      <g>
        <path d="m303.8 123.735v33.378h127.812c5.69 0 10.302-4.612 10.302-10.302v-23.076z" fill="#89b4c9" />
      </g>
      <path d="m303.08 123.735v33.378l24.12 19.847 19.423-19.847v-33.378l-21.772-31.375z" fill="#74a4b7" />
      <path d="m318.578 177.618c0 8.054-3.065 15.805-8.574 21.681l-20.033 21.369 56.652 24.332v-87.887h-28.045z" fill="#5b94a5" />
      <g>
        <path d="m279.661 41.228h-22.533v-30.926c0-5.69 4.612-10.302 10.302-10.302h-22.994c-5.69 0-10.302 4.612-10.302 10.302v77.738h69.666v35.695h42.823v-15.545c0-36.923-30.039-66.962-66.962-66.962z" fill="#a4ccff" />
      </g>
      <g>
        <path d="m61.364 512h-30.033c-2.761 0-5-2.239-5-5v-264.85h40.033v264.85c0 2.761-2.239 5-5 5z" fill="#dc8758" />
      </g>
      <g>
        <path d="m480.669 512h-30.033c-2.761 0-5-2.239-5-5v-264.85h40.033v264.85c0 2.761-2.239 5-5 5z" fill="#dc8758" />
      </g>
      <g fill="#ac5e2e">
        <path d="m139.28 220.318h-104.58c-5.69 0-10.44 4.612-10.44 10.302v23.062c0 5.69 4.57 10.302 10.26 10.302h104.76z" />
        <path d="m501.698 220.318h-198.258l17.428 43.665h180.83c5.69 0 10.302-4.612 10.302-10.302v-23.061c0-5.69-4.612-10.302-10.302-10.302z" />
      </g>
      <g>
        <path d="m28.68 253.681v-23.061c0-5.69 4.612-10.302 10.302-10.302h-28.68c-5.69 0-10.302 4.612-10.302 10.302v23.062c0 5.689 4.612 10.302 10.302 10.302h28.68c-5.689-.001-10.302-4.613-10.302-10.303z" fill="#9e5528" />
      </g>
      <g>
        <g>
          <path d="m300.868 305.681h-150.188c-11.046 0-21.84-8.954-21.84-20l-1.44-178.699c0-22.091 18.597-40 40.689-40h112.78c22.091 0 40 17.909 40 40v178.699c-.001 11.046-8.955 20-20.001 20z" fill="#ff8086" />
        </g>
      </g>
      <g>
        <path d="m136.617 285.078v-176.888c0-22.758 18.449-41.207 41.207-41.207h-21.016c-22.758 0-41.207 18.449-41.207 41.207v176.888c0 11.379 9.225 20.604 20.604 20.604h21.016c-11.38-.001-20.604-9.225-20.604-20.604z" fill="#f46c7a" />
      </g>
      <g>
        <path d="m227.086 512h-17.702c-5.523 0-10-4.477-10-10v-239.723c0-5.523 4.477-10 10-10h17.702c5.523 0 10 4.477 10 10v239.723c0 5.523-4.477 10-10 10z" fill="#deecf1" />
      </g>
      <g>
        <g>
          <path d="m320.868 406.062v-35.3c0-1.481-.638-2.891-1.751-3.869l-12.53-11.012c-3.76-3.305-8.595-5.127-13.601-5.127h-131.986c-5.006 0-13.159 1.823-16.919 5.127l-12.53 11.012c-1.113.978-1.751 2.388-1.751 3.869l-1.68 35.3c0 2.845 9.703 5.151 12.548 5.151h175.049c2.845 0 5.151-2.306 5.151-5.151z" fill="#ff8086" />
        </g>
      </g>
      <g>
        <path d="m137.853 406.062v-35.3c0-1.482.638-2.891 1.751-3.869l12.53-11.012c3.76-3.305 8.595-5.127 13.601-5.127h-22.252c-5.006 0-9.841 1.823-13.601 5.127l-12.53 11.012c-1.113.978-1.751 2.388-1.751 3.869v35.3c0 2.845 2.306 5.151 5.151 5.151h22.252c-2.845 0-5.151-2.306-5.151-5.151z" fill="#f46c7a" />
      </g>
    </g>
  </svg>
}

const Office = () => {
  return <svg id="Capa_1" enableBackground="new 0 0 483.254 483.254"  viewBox="0 0 483.254 483.254"  xmlns="http://www.w3.org/2000/svg">
    <g>
      <g id="icon_8_">
        <path d="m63.46 61.037h356.335v401.814h-356.335z" fill="#dbd8dd" />
        <path d="m141.299 0h200.651v462.846h-200.651z" fill="#eeefee" />
        <path d="m79.525 79.342h45.774v54.018h-45.774z" fill="#69b0ee" />
        <path d="m79.525 157.152h45.774v54.018h-45.774z" fill="#69b0ee" />
        <path d="m79.525 234.956h45.774v54.018h-45.774z" fill="#69b0ee" />
        <path d="m79.525 312.766h45.774v54.018h-45.774z" fill="#69b0ee" />
        <path d="m79.525 390.57h45.774v54.018h-45.774z" fill="#69b0ee" />
        <path d="m356.164 79.342h45.774v54.018h-45.774z" fill="#69b0ee" />
        <path d="m356.164 157.152h45.774v54.018h-45.774z" fill="#69b0ee" />
        <path d="m356.164 234.956h45.774v54.018h-45.774z" fill="#69b0ee" />
        <path d="m356.164 312.766h45.774v54.018h-45.774z" fill="#69b0ee" />
        <path d="m356.164 390.57h45.774v54.018h-45.774z" fill="#69b0ee" />
        <path d="m161.878 22.913h159.492v29.797h-159.492z" fill="#69b0ee" />
        <path d="m161.861 76.519h159.533v29.797h-159.533z" fill="#69b0ee" />
        <path d="m161.861 130.155h159.533v29.797h-159.533z" fill="#69b0ee" />
        <path d="m161.861 183.79h159.533v29.797h-159.533z" fill="#69b0ee" />
        <path d="m161.861 237.425h159.533v29.797h-159.533z" fill="#69b0ee" />
        <path d="m161.861 291.061h159.533v29.797h-159.533z" fill="#69b0ee" />
        <path d="m170.418 344.472h142.424v118.374h-142.424z" fill="#575b6d" />
        <g fill="#eeefee">
          <path d="m365.093 444.588h-1.715l19.283-54.018h1.721z" opacity=".5" />
          <path d="m377.805 444.588h-9.989l19.283-54.018h9.989z" opacity=".5" />
          <path d="m365.093 366.778h-1.715l19.283-54.012h1.721z" opacity=".5" />
          <path d="m377.805 366.778h-9.989l19.283-54.012h9.989z" opacity=".5" />
          <path d="m365.093 288.974h-1.715l19.283-54.018h1.721z" opacity=".5" />
          <path d="m377.805 288.974h-9.989l19.283-54.018h9.989z" opacity=".5" />
          <path d="m365.093 211.17h-1.715l19.283-54.018h1.721z" opacity=".5" />
          <path d="m377.805 211.17h-9.989l19.283-54.018h9.989z" opacity=".5" />
          <path d="m365.093 133.361h-1.715l19.283-54.019h1.721z" opacity=".5" />
          <path d="m377.805 133.361h-9.989l19.283-54.019h9.989z" opacity=".5" />
          <path d="m243.649 22.913-17.362 29.797h-5.398l17.261-29.797z" opacity=".5" />
          <path d="m282.215 22.913-17.256 29.797h-27.198l17.262-29.797z" opacity=".5" />
          <path d="m243.649 76.525-17.362 29.791h-5.398l17.261-29.791z" opacity=".5" />
          <path d="m282.215 76.525-17.256 29.791h-27.198l17.262-29.791z" opacity=".5" />
          <path d="m243.649 130.131-17.362 29.797h-5.398l17.261-29.797z" opacity=".5" />
          <path d="m282.215 130.131-17.256 29.797h-27.198l17.262-29.797z" opacity=".5" />
          <path d="m243.649 183.737-17.362 29.797h-5.398l17.261-29.797z" opacity=".5" />
          <path d="m282.215 183.737-17.256 29.797h-27.198l17.262-29.797z" opacity=".5" />
          <path d="m243.649 237.349-17.362 29.791h-5.398l17.261-29.791z" opacity=".5" />
          <path d="m282.215 237.349-17.256 29.791h-27.198l17.262-29.791z" opacity=".5" />
          <path d="m243.649 290.955-17.362 29.796h-5.398l17.261-29.796z" opacity=".5" />
          <path d="m282.215 290.955-17.256 29.796h-27.198l17.262-29.796z" opacity=".5" />
          <path d="m87.275 444.588h-1.715l19.283-54.018h1.72z" opacity=".5" />
          <path d="m99.987 444.588h-9.99l19.283-54.018h9.99z" opacity=".5" />
          <path d="m87.275 366.778h-1.715l19.283-54.012h1.72z" opacity=".5" />
          <path d="m99.987 366.778h-9.99l19.283-54.012h9.99z" opacity=".5" />
          <path d="m87.275 288.974h-1.715l19.283-54.018h1.72z" opacity=".5" />
          <path d="m99.987 288.974h-9.99l19.283-54.018h9.99z" opacity=".5" />
          <path d="m87.275 211.17h-1.715l19.283-54.018h1.72z" opacity=".5" />
          <path d="m99.987 211.17h-9.99l19.283-54.018h9.99z" opacity=".5" />
          <path d="m87.275 133.361h-1.715l19.283-54.019h1.72z" opacity=".5" />
          <path d="m99.987 133.361h-9.99l19.283-54.019h9.99z" opacity=".5" />
        </g>
        <path d="m42.314 458.379h398.626v24.876h-398.626z" fill="#bbbbcd" />
        <path d="m234.184 339.775h14.887v118.444h-14.887z" fill="#eeefee" />
      </g>
    </g>
  </svg>
}

const Home = () => {
  return <svg id="Capa_1" enableBackground="new 0 0 512 512"  viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m256 0c-90.981 0-165 74.019-165 165 0 124.775 148.096 276.307 154.395 282.605 2.93 2.93 6.768 4.395 10.605 4.395s7.676-1.465 10.605-4.395c6.299-6.298 154.395-157.83 154.395-282.605 0-90.981-74.019-165-165-165z" fill="#ff641a" />
      <path d="m421 165c0-90.981-74.019-165-165-165v452c3.838 0 7.676-1.465 10.605-4.395 6.299-6.298 154.395-157.83 154.395-282.605z" fill="#f03800" />
      <path d="m346 482h-90-90c-8.291 0-15 6.709-15 15s6.709 15 15 15h90 90c8.291 0 15-6.709 15-15s-6.709-15-15-15z" fill="#c3ea21" />
      <g>
        <path d="m361 497c0-8.291-6.709-15-15-15h-90v30h90c8.291 0 15-6.709 15-15z" fill="#9cdd05" />
      </g>
      <path d="m256 107.999-75 50.099v97.902c0 8.399 6.599 15 15 15h60 60c8.401 0 15-6.601 15-15v-97.901z" fill="#f0f7ff" />
      <path d="m331 256v-97.901l-75-50.099v163h60c8.401 0 15-6.601 15-15z" fill="#dfe7f4" />
      <path d="m354.32 152.52-90-60c-2.52-1.685-5.42-2.527-8.32-2.527s-5.801.842-8.32 2.527l-90 60c-6.899 4.6-8.76 13.901-4.16 20.801 4.585 6.87 13.843 8.716 20.801 4.16l81.679-54.449 81.68 54.448c7.015 4.649 16.28 2.633 20.801-4.16 4.599-6.899 2.739-16.201-4.161-20.8z" fill="#979fef" />
      <path d="m358.48 173.32c4.6-6.899 2.739-16.201-4.16-20.801l-90-60c-2.52-1.685-5.42-2.527-8.32-2.527v33.04l81.68 54.448c7.014 4.65 16.28 2.634 20.8-4.16z" fill="#6b77e8" />
    </g>
  </svg>
}

const Helmet = () => {
  return <svg id="Capa_1" enableBackground="new 0 0 512 512"  viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <g>
          <path d="m437.52 373.88c13.679-21.045 23.621-44.436 29.305-68.851h-68.353c-20.588 59.868-77.775 101.591-142.482 101.591h-.34c-65.98-.143-122.55-42.495-142.895-101.591h-67.58c5.684 24.415 15.626 47.807 29.305 68.851l-20.71 20.71c-5.853 5.832-5.873 15.336-.01 21.22l42.43 42.43c5.877 5.856 15.381 5.85 21.22-.01l20.71-20.71c22.2 14.43 47.01 24.71 72.88 30.21v29.27c0 8.28 6.72 15 15 15h60c8.28 0 15-6.72 15-15v-29.27c25.87-5.5 50.68-15.78 72.88-30.21l20.71 20.71c5.906 5.906 15.387 5.823 21.22.01l42.43-42.43c5.86-5.881 5.846-15.385-.01-21.22z" fill="#766d78" />
        </g>
        <path d="m437.52 373.88c13.679-21.045 23.621-44.436 29.305-68.851h-68.353c-20.588 59.868-77.775 101.591-142.482 101.591h-.34v105.38h30.35c8.28 0 15-6.72 15-15v-29.27c25.87-5.5 50.68-15.78 72.88-30.21l20.71 20.71c5.885 5.885 15.365 5.844 21.22.01l42.43-42.43c5.863-5.884 5.843-15.388-.01-21.22z" fill="#554e56" />
      </g>
      <g>
        <g>
          <path d="m482.43 251.13v32.55c0 8.28-6.72 15-15 15h-171.77c-4.69 0-9.12-2.2-11.95-5.94-6.64-8.76-16.74-13.78-27.71-13.78s-21.07 5.02-27.71 13.78c-2.83 3.74-7.26 5.94-11.95 5.94h-171.77c-8.28 0-15-6.72-15-15v-32.55c0-54.87 19.88-107.8 55.97-149.04 13.12-15 28.05-28.08 44.34-39.01 4.13-2.78 16.62 7.24 16.62 7.24s6.25-20.8 11.51-23.33c18.29-8.79 37.77-15.13 57.95-18.73h80.08c20.18 3.6 39.66 9.94 57.95 18.74 4.66 2.23 10.22 26.71 10.22 26.71s13.22-13.78 17.91-10.63c16.29 10.93 31.22 24.01 44.34 39.01 36.09 41.24 55.97 94.17 55.97 149.04z" fill="#fff161" />
        </g>
        <path d="m482.43 251.13v32.55c0 8.28-6.72 15-15 15h-171.77c-4.69 0-9.12-2.2-11.95-5.94-6.64-8.76-16.74-13.78-27.71-13.78v-250.7h40.04c20.18 3.6 39.66 9.94 57.95 18.74 4.66 2.23 10.22 26.71 10.22 26.71s13.22-13.78 17.91-10.63c16.29 10.93 31.22 24.01 44.34 39.01 36.09 41.24 55.97 94.17 55.97 149.04z" fill="#fc0" />
        <g>
          <path d="m301 39.83v89.92c0 22.74-18.51 41.25-41.25 41.25h-7.5c-22.74 0-41.25-18.51-41.25-41.25v-89.92c0-21.96 17.87-39.83 39.83-39.83h10.34c21.96 0 39.83 17.87 39.83 39.83z" fill="#fc0" />
        </g>
        <g>
          <path d="m155.98 161c-7.41 0-13.85-5.48-14.85-13.03l-11.25-84.89c8.99-6.04 18.39-11.41 28.13-16.09l12.86 97.04c1.187 8.944-5.762 16.97-14.89 16.97z" fill="#fc0" />
        </g>
        <g>
          <path d="m382.12 63.08-11.25 84.89c-1.093 8.256-8.681 13.994-16.84 12.9-8.21-1.09-13.99-8.63-12.9-16.84l12.86-97.03c9.74 4.67 19.14 10.04 28.13 16.08z" fill="#ff8756" />
        </g>
        <g>
          <path d="m214.612 243.68h-169.652c-24.79 0-44.96 20.17-44.96 44.96v20.04c0 8.28 6.72 15 15 15h199.612z" fill="#fc0" />
        </g>
        <g>
          <path d="m467.04 243.68h-169.78v80h199.74c8.28 0 15-6.72 15-15v-20.04c0-24.79-20.17-44.96-44.96-44.96z" fill="#ff8756" />
        </g>
        <path d="m259.75 171h-3.75v-171h5.17c21.96 0 39.83 17.87 39.83 39.83v89.92c0 22.74-18.51 41.25-41.25 41.25z" fill="#ff8756" />
        <g>
          <g>
            <path d="m309.04 283.68c0 11.66-3.71 22.72-10.73 32-10.14 13.38-25.56 21.04-42.31 21.04s-32.17-7.66-42.3-21.03c-7.03-9.29-10.74-20.35-10.74-32.01s3.71-22.72 10.73-32c10.14-13.38 25.56-21.04 42.31-21.04s32.17 7.66 42.3 21.03c7.03 9.29 10.74 20.35 10.74 32.01z" fill="#f4f4f7" />
          </g>
          <path d="m309.04 283.68c0 11.66-3.71 22.72-10.73 32-10.14 13.38-25.56 21.04-42.31 21.04v-106.08c16.75 0 32.17 7.66 42.3 21.03 7.03 9.29 10.74 20.35 10.74 32.01z" fill="#e5eaee" />
          <g>
            <path d="m256 218.96c-36.242 0-64.72 29.368-64.72 64.72 0 35.249 28.381 64.72 64.72 64.72 36.243 0 64.72-29.368 64.72-64.72 0-35.25-28.382-64.72-64.72-64.72zm0 99.44c-19.455 0-34.72-15.747-34.72-34.72 0-19.006 15.297-34.72 34.72-34.72 19.455 0 34.72 15.746 34.72 34.72 0 19.006-15.297 34.72-34.72 34.72z" fill="#766d78" />
          </g>
          <path d="m320.72 283.68c0 35.277-28.406 64.72-64.72 64.72v-30c19.417 0 34.72-15.708 34.72-34.72 0-18.961-15.254-34.72-34.72-34.72v-30c36.314 0 64.72 29.445 64.72 64.72z" fill="#554e56" />
        </g>
      </g>
    </g>
  </svg>
}

const CS = () => {
  return <svg id="Capa_1" enableBackground="new 0 0 512 512"  viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path d="m309.777 77.616c-12.347-34.818-44.37-63.961-80.17-74.757-54.975 12.072-96.121 61.054-96.121 119.654 0 0-.011 34.753-.032 98.363-.008 23.741 15.146 44.836 37.648 52.407l43.529 14.645h82.736l19.105-15.199c.145-68.662 1.057-173.253-6.695-195.113z" fill="#756e78" />
                  <g>
                    <path d="m310.773 115.296v60.055c-.271 17.952-5.954 34.589-15.485 48.36v62.058c0 .728.039 1.447.106 2.16h1.974l43.529-14.645c22.502-7.57 37.656-28.665 37.648-52.407-.021-63.61-.032-98.363-.032-98.363.001-67.663-54.85-122.514-122.513-122.514-10.618 0-20.919 1.352-30.743 3.891 49.24 13.129 85.516 58.027 85.516 111.405z" fill="#665e66" />
                  </g>
                  <path d="m255.987 375.042c20.105 0 39.134-4.598 56.099-12.796-1.063-31.199-2.767-54.186-3-55.487l-.625-3.488c-4.933-4.291-7.994-10.587-7.994-17.503v-63.834h-88.932v63.834c0 6.916-3.062 13.211-7.994 17.502l-.625 3.488c-3.185 17.765-5.447 35.646-6.805 53.572 17.897 9.389 38.263 14.712 59.876 14.712z" fill="#ffddce" />
                  <g>
                    <path d="m211.534 511.979h88.933l6.994-7.735c10.282-51.657 12.822-104.509 7.578-156.827-17.446 9.83-37.586 15.442-59.039 15.442-21.318 0-41.337-5.545-58.708-15.26-5.251 52.581-2.638 105.699 7.792 157.6z" fill="#dbe8f9" />
                  </g>
                  <g>
                    <path d="m387.476 438.396c0-50.639-28.094-94.854-80.877-118.123 10.49 63.667 8.418 128.796-6.133 191.706h78.554l8.456-6.868z" fill="#7fb2f9" />
                  </g>
                  <path d="m346.061 315.453-26.746-6.862c-.274-.053-.537-.131-.807-.193-7.797-1.793-13.944-7.404-16.604-14.613l3.516 19.611c.672 3.746 1.294 7.498 1.878 11.254 42.918 19.85 71.722 63.193 71.722 112.247v75.081h33.699c18.58 0 33.643-15.062 33.643-33.642v-41.439c0-59.29-42.079-110.239-100.301-121.444z" fill="#60a0f7" />
                  <path d="m206.58 313.397 3.516-19.611c-2.66 7.208-8.808 12.82-16.604 14.613-.27.062-.533.14-.807.193l-26.746 6.862c-58.222 11.205-100.301 62.154-100.301 121.445v41.439c0 18.58 15.062 33.643 33.643 33.643h112.253c-15.075-65.179-16.761-132.737-4.954-198.584z" fill="#7fb2f9" />
                </g>
                <g>
                  <g>
                    <path d="m334.11 120.008v69.408h36.291c9.636 0 17.448-7.812 17.448-17.448v-34.512c0-9.636-7.812-17.448-17.448-17.448z" fill="#9bc4f9" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="m177.89 120.008v69.408h-36.291c-9.636 0-17.448-7.812-17.448-17.448v-34.512c0-9.636 7.812-17.448 17.448-17.448z" fill="#9bc4f9" />
                  </g>
                </g>
              </g>
            </g>
            <path d="m126.889 460.769c-4.267 0-7.726 3.459-7.726 7.726v43.483h15.452v-43.483c0-4.266-3.459-7.726-7.726-7.726z" fill="#9bc4f9" />
            <path d="m360.188 433.023h31.192c7.831 0 14.178-6.348 14.178-14.178v-8.51c0-7.831-6.348-14.178-14.178-14.178h-31.192c-7.831 0-14.178 6.348-14.178 14.178v8.51c0 7.83 6.348 14.178 14.178 14.178z" fill="#eaf2ff" />
            <path d="m405.558 418.844v-8.51c0-7.83-6.348-14.178-14.179-14.178h-19.264c4.066 11.646 6.435 24.04 6.833 36.867h12.431c7.831 0 14.179-6.348 14.179-14.179z" fill="#dbe8f9" />
          </g>
          <g>
            <path d="m316.457 177.302c-.027-47.251.858-65.923-1.776-81.498-6.547-8.897-10.325-17.82-12.49-24.561-1.244-3.873-6.495-4.366-8.47-.81-18.985 34.168-56.782 49.575-100.31 49.575-.823 0-11.407 0-24.798 0v55.343c.696 46.012 36.919 83.401 82.462 85.932 37.178-9.994 64.77-43.662 65.382-83.981z" fill="#ffddce" />
            <g>
              <path d="m304.141 76.687c4.289 12.072 6.632 25.066 6.632 38.608v60.055c-.637 42.104-31.027 76.976-71.08 84.538 5.285.998 10.731 1.54 16.307 1.54 47.831 0 86.666-38.419 87.387-86.079v-55.343c-22.902-11.622-33.949-29.934-39.246-43.319z" fill="#ffcbbe" />
            </g>
          </g>
        </g>
        <path d="m372.398 189.293v18.178c0 9.946-8.091 18.038-18.038 18.038h-51.836c-4.267 0-7.726 3.459-7.726 7.726s3.459 7.726 7.726 7.726h51.836c18.466 0 33.49-15.024 33.49-33.491v-35.504c0 8.962-6.758 16.336-15.452 17.327z" fill="#dbe8f9" />
        <path d="m313.05 246.451h-21.13c-8.061 0-14.596-6.535-14.596-14.596 0-8.061 6.535-14.596 14.596-14.596h21.13c8.061 0 14.596 6.535 14.596 14.596.001 8.061-6.534 14.596-14.596 14.596z" fill="#db636e" />
      </g>
      <path d="m394.554 512v-43.504c0-4.267-3.459-7.726-7.726-7.726s-7.726 3.459-7.726 7.726v43.504z" fill="#9bc4f9" />
    </g>
  </svg>
}

const TW = () => {
  return <svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
     viewBox="0 0 527.9 969.1" enableBackground="new 0 0 527.9 969.1;" xmlSpace="preserve">
  <path class="st0" d="M28.8,565.7L28.8,565.7C28.7,565.8,28.8,565.7,28.8,565.7L28.8,565.7z"/>
  <path class="st0" d="M25.3,567.2l1.4-0.6l-1.5-0.4l-2.4-0.1c-0.1,0-0.2,0-0.3-0.1l-1.1,1.6c0.1,0,0.2-0.1,0.3-0.1L25.3,567.2z"/>
  <path class="st1" d="M106.1,778.3l-2.6-7.4c0-0.1-0.1-0.2-0.1-0.4l0.1-7.1c0-0.1,0-0.2,0-0.2l5.7-21.1l0.1-9.4l-0.5-6.9
    c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.3l2.6-7.8l1.3-7.1c0-0.1,0-0.1,0-0.2l1.7-4.9c0.1-0.4,0.6-0.7,1-0.7l16.6,1l1,0.1l7.6-2.6l6.2-6.9
    c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.3-0.2l9.4-4.5c0.3-0.1,0.6-0.1,0.9,0l12.5,6.3l6.1-5.4c0.1-0.1,0.3-0.2,0.4-0.2l7.2-1.8
    c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0
    c0.1,0,0.2,0,0.2,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.1,0.3,0.2l7.6,8.9l5.2,4.9l7.8-6.2c0.2-0.1,0.4-0.2,0.6-0.2
    l4.3-0.2c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0l5.1,0.9l2.6-0.3l-0.9-3.5l-6-6.2c0,0-0.1-0.1-0.1-0.2l-3-4.6c-0.1-0.1-0.1-0.2-0.1-0.4
    l-1.3-8.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l2.3-9.8c0-0.2,0.2-0.4,0.3-0.5l8.4-7.1l4-5.5l0.1-10.9
    c0-0.1,0-0.1,0-0.2l3-13l-2.1-9.7c0-0.1,0-0.2,0-0.3l0.8-6.9c0-0.3,0.2-0.6,0.5-0.8l7.1-3.9l2.3-4.2l-2.1-5.6c0-0.1-0.1-0.2-0.1-0.3
    c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1l0.9-5.7c0-0.3,0.2-0.5,0.4-0.7l7.7-5l3.9-3.8c0,0,0.1-0.1,0.2-0.1l6.4-4.1
    c0.1-0.1,0.2-0.1,0.3-0.1l14.7-3.6l-0.2-1.5l-2.7-7.2l-6-5l-5.5-3.6c-0.2-0.1-0.4-0.4-0.4-0.6l-1-4c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0
    c0-0.1,0-0.2,0.1-0.4l1.4-3.2c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.1l0,0c0,0,0,0,0,0l5.3-2.3l2.6-4.3
    l-2.4-7.2l-3.9-5.5l-5.5-2.1l-0.1,0l-3.7-0.9l-18.1,9.2l-8.3,6.6l-3.5,6.6c0,0.1-0.1,0.1-0.1,0.2l-4.2,5c-0.1,0.1-0.2,0.2-0.3,0.2
    l-7.6,4.3l-7.8,6l-4.4,5.3c-0.1,0.1-0.2,0.2-0.3,0.2l-12.3,7.3c-0.1,0.1-0.3,0.1-0.4,0.1l-5.8,0.6c-0.1,0-0.1,0-0.2,0l-6.4-0.7
    l-3,2.9l-0.1,4.4l1.4,4.3c0,0,0,0.1,0,0.1l1,6.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0c0,0,0,0,0,0l-2.3,6.6
    c0,0.1-0.1,0.2-0.1,0.2L151,616.3l-10,16.9c0,0,0,0.1-0.1,0.1l-6.5,8.4c0,0-0.1,0.1-0.1,0.1l-7.4,7.2l-6.5,5.4l-6,6.3l-5.2,7
    l-2.9,5.1l-1.5,3.5l-0.9,2c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.2l-6.3,4.4c-0.1,0-0.1,0.1-0.2,0.1l-6.4,2.6l-3.4,2.5
    c-0.1,0.1-0.2,0.1-0.3,0.1l-4.2,1.5c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0l-8.3-1l-8.1,0.9
    c-0.1,0-0.2,0-0.2,0L50,687.7c-0.1,0-0.2-0.1-0.3-0.1l-4.8-2.5c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1-0.1
    c-0.1-0.1-0.1-0.2-0.2-0.3l-3.7-8.1l-4.5,2.8l2,12.8l0.6,1.8l2.8,4.8c0.1,0.1,0.1,0.2,0.1,0.3l0.4,2l2.4,2.1
    c0.1,0.1,0.2,0.2,0.3,0.4l0.5,1.2c0.1,0.2,0.1,0.3,0.1,0.5l-0.3,2.4c0,0.1,0,0.2-0.1,0.3l-1.4,3.4l-0.1,2.7l1,4.3l4.5,10L54,736
    c0,0,0,0.1,0.1,0.1l2.7,5.6c0,0.1,0.1,0.1,0.1,0.2l1.5,5.8c0,0.2,0,0.3,0,0.5l-1.1,4.1c0,0.1,0,0.1-0.1,0.2l-2,4.2l1.4,4.2l3.3,4
    l9,8.4l5.4,6.1c0.1,0.1,0.1,0.1,0.1,0.2l3.8,7.2c0,0.1,0.1,0.2,0.1,0.3l1.5,7.4c0.1,0.4-0.1,0.9-0.5,1.1c-0.1,0-0.2,0.1-0.3,0.1l0,0
    l12.6,9.9h1.7l1.6-0.6c0.1,0,0.2-0.1,0.3-0.1l1.6-0.1c0.2,0,0.3,0,0.5,0.1l1.7,0.7l7.6-19.3l0-0.7L106.1,778.3z"/>
  <path class="st1" d="M190.9,830.8c0.1-0.2,0.2-0.3,0.4-0.4l5.1-3.1l2-3l-4.1-5c0-0.1-0.1-0.1-0.1-0.2l-2.6-5
    c-0.1-0.1-0.1-0.3-0.1-0.5v-5.3l-1.1-6.9l-2-6c0,0,0-0.1,0-0.1l-1.2-6.5c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0l0.4-7.5l0.4-7.2
    c0-0.1,0-0.2,0.1-0.3l3-7.5c0,0,0-0.1,0.1-0.1l3.7-6.5l2.4-6.5c0-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1l5.4-4.5
    c0.1-0.1,0.3-0.2,0.4-0.2l1.3-0.3l6.2-1.3l4.7-2.6c0,0,0.1,0,0.1-0.1l9.4-3.9l1.8-4.4l0.1-5c0,0,0-0.1,0-0.1c0,0,0,0,0,0
    c0,0,0-0.1,0-0.1l2.7-13.1l-4.8-3.1l-5.2-1.3c-0.4-0.1-0.7-0.4-0.8-0.8l-0.7-4.7l-3.2-6l-4.6-0.8l-3.9,0.2l-8.2,6.5
    c-0.4,0.3-0.9,0.3-1.3-0.1l-5.9-5.5c0,0-0.1-0.1-0.1-0.1l-7.2-8.4l-6.4,1.6l-6.4,5.7c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0
    c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.3,0-0.4-0.1l-12.7-6.4
    l-8.8,4.2l-6.3,7c-0.1,0.1-0.3,0.2-0.4,0.3l-8,2.8c-0.1,0-0.2,0-0.3,0.1c0,0,0,0-0.1,0l-17-1.1l-1.4,4.1l-1.3,7.1c0,0,0,0,0,0v0
    c0,0,0,0.1,0,0.1l-2.5,7.7l0.5,6.8v0v0l-0.1,9.6c0,0.1,0,0.2,0,0.2l-5.7,21.1l-0.1,6.8l2.6,7.3c0,0.1,0.1,0.2,0.1,0.3l0.4,8.2
    c0,0,0,0,0,0c0,0.1,0,0.3-0.1,0.4l-7.8,19.7l1.9,1.2c0,0,0.1,0.1,0.1,0.1l4.8,4.2l4.4,3l9.9,4.8c0,0,0.1,0,0.1,0.1l4.4,2.9
    c0.1,0.1,0.3,0.2,0.3,0.4l0.7,1.3c0,0.1,0.1,0.2,0.1,0.2l0.8,3.3l5,2.8c0,0,0.1,0.1,0.1,0.1l8.2,6.5c0.1,0.1,0.1,0.1,0.2,0.2l2,2.8
    c0,0.1,0.1,0.1,0.1,0.2l4.3,9.8l5.4,6.7c0,0,0.1,0.1,0.1,0.1l1.6,2.9c0,0,0,0.1,0.1,0.1l19.9,51.6c0.1,0.2,0.1,0.3,0.1,0.5l-0.5,3
    c0,0.1-0.1,0.3-0.2,0.4l-2.4,3.4l-0.5,2.8v10.7c0,0,0,0.1,0,0.1l-0.4,3.2l-0.1,1.9l0.4,2.1l5.4,9.1c0.1,0.2,0.2,0.5,0.1,0.7
    l-1.3,6.9l0.2,1.9l3.2,2.7l2.6,0.6l1.6-1.6l0.3-6.2c0-0.3,0.2-0.6,0.4-0.8c0.2-0.1,0.4-0.2,0.6-0.2c0.1,0,0.2,0,0.2,0l8,1.9
    c0.1,0,0.1,0,0.2,0.1l7.8,3.5c0.1,0,0.1,0.1,0.2,0.1l6.8,5.2c0.1,0.1,0.2,0.1,0.2,0.2l2.9,4.3l0.2-2.8l-1.5-9.1c0,0,0-0.1,0-0.1
    l-0.1-8.3c0-0.3,0.1-0.6,0.3-0.7l4-3.7c0,0,0.1,0,0.1-0.1l3.2-2.3l2.4-5.7l1.6-7.1l-0.9-50.4l-3.5,0.2c0,0,0,0-0.1,0c0,0,0,0,0,0
    c-0.1,0-0.3,0-0.4-0.1l-10.2-4.7c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0v0
    c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l-4.6-4.9
    c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1l-3.2-5.9c-0.1-0.1-0.1-0.2-0.1-0.4l-0.6-6.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
    c0,0,0,0,0,0l0.1-5.4l-2.8-3.9l-3.2-1.6c-0.2-0.1-0.3-0.2-0.4-0.3l-2.7-3.9c-0.1-0.2-0.2-0.4-0.2-0.7l0.5-4.5c0-0.1,0.1-0.3,0.1-0.4
    L190.9,830.8z"/>
  <polygon class="st2" points="111.3,732.8 111.3,732.8 110.8,726 110.8,726 "/>
  <polygon class="st2" points="211.4,700.2 210.1,699.9 214.7,700.7 214.7,700.7 "/>
  <path class="st1" d="M50.5,685.8l15.4,2.2l8.1-0.9c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l8.2,1l3.8-1.4l3.4-2.5c0.1-0.1,0.1-0.1,0.2-0.1
    l6.4-2.6l6-4.2l2.3-5.2c0,0,0-0.1,0.1-0.1l3-5.2c0,0,0-0.1,0.1-0.1l5.3-7.1c0,0,0-0.1,0.1-0.1l6.1-6.4c0,0,0.1-0.1,0.1-0.1l6.5-5.4
    l7.3-7.1l6.4-8.3l10-16.9l10.4-15l1.8-5.3l-7.2-0.9c-0.1,0-0.1,0-0.2,0l-3.5-1l-4.6,0.6l-5.2,2.3c-0.1,0.1-0.3,0.1-0.4,0.1h-3.8
    c-0.2,0-0.4-0.1-0.6-0.2l-4.5-3.5c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.3l-1.6-4.6
    c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.1l1.8-14.1l-0.9-7l-9.7-9.9c-0.1-0.1-0.2-0.2-0.2-0.4l-1.7-5l-1.9-3.9l-3.7-2.2l-6.9-2.3
    l-8.2,0.5l-15,2.8l-7.6,3.4l-6,4.5l-4.6,4.7c0,0-0.1,0.1-0.1,0.1l-6.6,5.1c0,0-0.1,0-0.1,0.1l-5.6,3.5l-2.4,4.7
    c-0.1,0.2-0.3,0.4-0.5,0.5l-6.2,2.8c-0.2,0.1-0.4,0.1-0.7,0.1l-7.2-1.8l-8.8-1.3c-0.1,0-0.1,0-0.2-0.1l-4.3-1.6l-0.9,2.4l3,2.1
    c0.3,0.2,0.5,0.6,0.4,1c-0.1,0.4-0.4,0.7-0.8,0.8l-2.3,0.5l-1.3,1.3l-3.2,8.7l-0.4,2l0.5,2.1c0.1,0.3,0,0.6-0.2,0.9l-2.2,2.5
    l-1.3,3.5l-0.8,2.6l1.3,2.9c0,0.1,0,0.1,0.1,0.2l0.8,3.9c0,0.2,0,0.5-0.1,0.7l-1.4,2.5c-0.1,0.2-0.3,0.4-0.6,0.5l-2.6,0.9l-2,1.2
    l-0.5,3.1l2.4,2.8l4.2,3.7c0.1,0.1,0.2,0.3,0.3,0.5l0.8,2.6c0.1,0.3,0,0.6-0.1,0.9s-0.5,0.4-0.8,0.4l-6.9,0.1v0.1l8.6,2.2
    c0.2,0,0.4,0.1,0.5,0.3l1.4,1.5c0.3,0.3,0.3,0.7,0.2,1l-1,3c-0.1,0.3-0.3,0.5-0.6,0.6l-3.1,1c-0.1,0-0.1,0-0.2,0l-3.5,0.5l-2.6,1.4
    l-0.9,1.9l-0.6,3.1l0.6,3l2.2,1.9l1.9-0.5l1.6-2.6c0-0.1,0.1-0.1,0.2-0.2l2.3-2.1c0.3-0.3,0.7-0.3,1.1-0.2l4.1,1.8
    c0.3,0.1,0.5,0.3,0.5,0.6l0.8,2.4c0,0.1,0.1,0.3,0,0.4l-0.5,6.7l0.9,1.5l2.2,0.1l1.4-2.1l1.1-3.3c0-0.1,0.1-0.2,0.1-0.2l1.2-1.8
    c0.2-0.3,0.6-0.5,1-0.4l6.3,0.9c0.4,0.1,0.8,0.4,0.8,0.8l1.1,5.2c0,0.2,0,0.4-0.1,0.6l-2.7,6.1c-0.1,0.2-0.2,0.3-0.3,0.4l-3.7,2.7
    l3.1,3.1c0.1,0.1,0.1,0.2,0.2,0.3l1.8,3.6c0,0.1,0.1,0.2,0.1,0.3l0.8,4.8l4.8-2.9c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0
    c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.2
    c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3l0.2,0.5l3.7,8.3L50.5,685.8z"/>
  <path class="st2" d="M41.5,674.2C41.5,674.2,41.5,674.2,41.5,674.2c0,0,0.1,0,0.1,0C41.5,674.2,41.5,674.2,41.5,674.2z"/>
  <path class="st2" d="M40.8,674.3l-4.8,2.9l0,0L40.8,674.3C40.8,674.3,40.8,674.3,40.8,674.3C40.8,674.3,40.8,674.3,40.8,674.3
    C40.8,674.3,40.8,674.3,40.8,674.3z"/>
  <path class="st3" d="M247.3,157.1c0-0.1,0.1-0.2,0.1-0.2l3.4-4.3c0.1-0.1,0.2-0.2,0.4-0.3l5.9-2.4c0.1,0,0.1,0,0.2-0.1l3.3-0.6
    l1.6-0.3l1.9-1.1l0.8-2.8l-1.7-3.2l-3.6-3.7l-5.2-2.7l-15-4.8c0,0-0.1,0-0.1,0l-6.1-2.6l-1.6-0.7l-1.2,1.2l-1.2,2.4l0.9,1.8
    c0,0,0,0.1,0,0.1l0.7,1.9c0,0.1,0.1,0.2,0.1,0.3v1.7c0,0.1,0,0.3-0.1,0.4l-0.7,1.5c0,0.1-0.1,0.1-0.1,0.2l-2.1,2.9l-5.4,11.2
    l-0.9,3.1l6,2.4c0.1,0,0.1,0,0.2,0.1l4.3,2.6c0.1,0.1,0.2,0.2,0.3,0.3l2.5,3.6l3.8,1.5l4-1l2.2-2.9L247.3,157.1z"/>
  <path class="st3" d="M252.3,154l-3.1,4l-2.2,5.5c0,0.1-0.1,0.2-0.1,0.2l-2.1,2.6l4.1,10.4l3.7,4.2l1.9,1.5l3.9,2.9l12,5.7
    c0.1,0,0.2,0.1,0.2,0.2l3.9,3.4c0.2,0.2,0.3,0.4,0.3,0.6l0.7,5.2c0,0.1,0,0.1,0,0.2l-0.3,3.9c0,0,0,0.1,0,0.1l-0.8,3.5l-0.9,2.9
    l0.9,2.6l1,4.1c0,0.2,0,0.3,0,0.5l-1.3,4.6l-0.7,4.1l2.9,0.7l7.5-2c0.1,0,0.1,0,0.2,0l6.8-0.5c0.1,0,0.1,0,0.2,0l4.6,0.5l12.3-3.2
    c0.3-0.1,0.6,0,0.9,0.2l5,4c0.2,0.1,0.3,0.3,0.3,0.5l1.2,4.2l3.1,3.1c0.1,0.1,0.1,0.1,0.2,0.2l4.6,7.8c0.1,0.1,0.1,0.2,0.1,0.4
    l0.4,3.4l10.5-1c0.1,0,0.2,0,0.4,0l1.9,0.5l4-9.7c0-0.1,0.1-0.2,0.2-0.3l13.9-14.1l3.5-5.4l-0.5-4c0,0,0-0.1,0-0.1
    c0-0.1,0-0.2,0-0.3l1.4-4.7l2-5.7l-4-1.8l-7.4-4.1c0,0-0.1,0-0.1-0.1l-3.8-2.8l-5.3-0.9c-0.1,0-0.3-0.1-0.4-0.1l-6.2-3.8l-5.3-4
    c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.4l-0.5-4.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
    c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3l4.5-6.3l1.8-7.4l-1.5-7.1c0,0,0-0.1,0-0.1l-0.4-4.6l-3-2.3
    l-4.2-2.2c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0l-1.3-2.2l-1.3-2.2l-3-3.1
    l-3.4,0.3c-0.1,0-0.2,0-0.3,0l-3.8-0.7c-0.2,0-0.4-0.1-0.5-0.3l-3-3l-4-3l-6.3-2c0,0,0,0-0.1,0c0,0,0,0-0.1,0
    c-0.1-0.1-0.2-0.1-0.3-0.2l-3.5-3.7c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0
    c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0l1-5.3l-3.1-3.6l-6.5-2.1c0,0-0.1,0-0.1-0.1l-4-2l-4.2-1.2c0,0-0.1,0-0.1,0l-5-2
    c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1l0,0c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0l-2-4.3
    l-1.9-4.2l-4.1-2.7l-13.4,1c-0.1,0-0.3,0-0.4-0.1l-2.4-0.8l-1.6,2.5l-2.1,5.9l-0.6,2.6l0.2,1c0.1,0.5-0.2,0.9-0.6,1.1l-2.4,0.8
    l-2.5,1.9l-1.8,2.6l-0.8,2.7l-0.6,3.8c0,0.1-0.1,0.3-0.2,0.4l-0.9,1.3l7.2,3.1l15.1,4.8c0.1,0,0.1,0,0.2,0.1l5.4,2.8
    c0.1,0,0.2,0.1,0.3,0.2l3.8,3.9c0.1,0.1,0.1,0.2,0.2,0.2l1.9,3.7c0.1,0.2,0.1,0.5,0.1,0.7l-1,3.6c-0.1,0.3-0.2,0.5-0.5,0.6l-2.4,1.4
    c-0.1,0.1-0.2,0.1-0.3,0.1l-1.8,0.3c0,0,0,0,0,0l-3.2,0.6l-1,0.4L252.3,154z"/>
  <path class="st2" d="M267,144.4c0.1,0.2,0.1,0.5,0.1,0.7C267.1,144.9,267.1,144.6,267,144.4z"/>
  <polygon class="st2" points="246.8,163.7 244.7,166.3 244.7,166.3 "/>
  <path class="st1" d="M373.7,181.2l2.9,11.7c0.1,0.3,0,0.5-0.1,0.7l-2.2,3.8c-0.1,0.2-0.3,0.4-0.5,0.4l-3.4,1.3
    c-0.2,0.1-0.4,0.1-0.5,0l-3-0.5l-3.2,1.3l-0.4,1.1l0,0c0,0,0,0,0,0l-2.3,6.5l-1.3,4.4l0.6,4.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
    c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3l-3.8,5.8c0,0.1-0.1,0.1-0.1,0.2l-13.9,14.1l-3.8,9.3l2.5,0.7c0.1,0,0.2,0.1,0.3,0.1
    c0,0,0,0,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
    c0,0,0,0.1,0,0.1l-0.6,5.3l1.1,3.1l4.4,0.9c0.1,0,0.2,0.1,0.3,0.1l4.4,2.6l3.4,2.3l3.5-0.5l4.6-2.2c0.1-0.1,0.3-0.1,0.4-0.1h4.9
    c0.2,0,0.4,0.1,0.6,0.2l5.9,4.7l0,0c0,0,0,0,0,0l2.4,2l6.3,3l14.9,3.8c0,0,0.1,0,0.1,0l8.1,3.1h5.9l4.4-4.1l2.3-5.8
    c0.1-0.3,0.4-0.5,0.7-0.6l3.8-0.9c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1l9.2,5.5l9.2,5.5l9.9,1.5
    l-0.1-0.2c-0.2-0.3-0.2-0.6-0.1-0.9l0.5-1.4c0-0.1,0.1-0.2,0.1-0.3l0.9-1.2l0.5-1.3l0.5-6.3c0-0.1,0-0.1,0-0.2l0.8-3.2
    c0-0.1,0.1-0.2,0.1-0.2l1.3-2.3l1.1-2.3l-0.5-6.7c0-0.1,0-0.1,0-0.2l0.3-3.2c0-0.2,0.1-0.4,0.2-0.6l7-8c0.1-0.1,0.2-0.2,0.3-0.2
    l6.1-3.5l2-1.9l-0.4-2.1l-1.6-2.5c-0.1-0.1-0.1-0.2-0.1-0.3l-0.7-3c0-0.1,0-0.3,0-0.4l0.4-2.5c0-0.3,0.2-0.6,0.5-0.7l5.6-3.2
    l0.7-4.9l-1.2-6.2l-2.6-4.9c-0.2-0.4-0.1-1,0.3-1.3l1.9-1.4c0.1-0.1,0.2-0.1,0.3-0.2l2.6-0.8c0.1,0,0.1,0,0.2,0l3.1-0.3
    c0.1,0,0.1,0,0.2,0l0.7,0.1l-5.3-1c-0.1,0-0.2-0.1-0.3-0.1l-6.5-4c-0.1-0.1-0.3-0.2-0.3-0.4l-3.5-6.3c-0.1-0.1-0.1-0.3-0.1-0.5
    l-0.2-8.1c0-0.1,0-0.3,0.1-0.4l1.4-3.8l0.2-3.7l-3.3-15.4c0-0.1,0-0.1,0-0.2l-0.3-7.4c0,0,0-0.1,0-0.1l0.5-7.6c0,0,0-0.1,0-0.1
    l1.6-7.7c0,0,0-0.1,0-0.1l2.3-6.8c0-0.1,0.1-0.1,0.1-0.2l2.9-4.8c0,0,0.1-0.1,0.1-0.1l18.7-22.8c0,0,0.1-0.1,0.1-0.1l5.5-4.7
    c0.1-0.1,0.1-0.1,0.2-0.1l10.9-5.5l-2.7-1.6l-5.4,1.2l-8.2,4.3l-3.7,2.5c-0.1,0.1-0.2,0.1-0.4,0.2L477,95.6l-0.9,1.9l2.2,3.1
    c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0,0.3-0.1,0.5l-1.6,3.8c-0.1,0.2-0.2,0.3-0.4,0.5l-4.6,2.9c-0.1,0-0.1,0.1-0.2,0.1l-3.4,1.2
    l-3.5,1.9l-1.6,1.9l-1.6,1.9l-3.1,4.9c-0.1,0.1-0.2,0.2-0.3,0.3l-5,3.6c-0.1,0.1-0.2,0.1-0.3,0.1l-6.1,1.9l-13.4,8
    c0,0-0.1,0-0.1,0.1l-12.9,5.3l-3.7,2.2l-4.5,3.8l-1.8,4.5l0.2,3.9l1,3.1c0,0,0,0.1,0,0.1l0.4,2c0,0.1,0,0.2,0,0.3
    c0,0.3-0.2,0.5-0.4,0.7l-3.9,2.8c0,0-0.1,0.1-0.1,0.1l-6.5,3.2l-4.9,3.4l-3.8,3.3c-0.1,0.1-0.2,0.1-0.3,0.2l-4.9,1.8l-8.7,2.5
    L373.7,181.2z"/>
  <path class="st3" d="M431.2,38.2l-7.1,3.1l-2.6,1.8l0.4,2l2.8,3.5c0,0.1,0.1,0.1,0.1,0.2l1.7,3.4l2.7,3.4l3.6,3.3l2.9,2.1l10.4,4.3
    l4.7,0.6l3.6-1.4l2.1-1.5l0.3-1.6l-3.4-3.5c-0.2-0.2-0.3-0.4-0.3-0.6l-0.2-3c0,0,0-0.1,0-0.1l0.3-11.1l-10.4-2.6l-4-1.4
    c-0.2-0.1-0.3-0.2-0.4-0.3l-2.3-2.6l-0.7-0.5l-1.7,1.1l-2.2,1.4C431.3,38.1,431.2,38.1,431.2,38.2z"/>
  <path class="st4" d="M175.3,265.1l15,11l7.1,3.5l6.5,1.2c0.1,0,0.2,0.1,0.3,0.1l11.3,6.5l11.6-0.7l4.5-2.4l0.8-6.9
    c0-0.3,0.2-0.6,0.5-0.7l5.5-3.3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0l9.9,0.8c0.1,0,0.2,0,0.3,0.1l6,2.4
    c0.1,0.1,0.3,0.1,0.4,0.3l4.8,5.4l4.8,1.8l8.5-4.5l6.8-2.9l11.5-9.1c0,0,0.1-0.1,0.1-0.1l5.8-3.1l4.5-4.3c0,0,0.1-0.1,0.1-0.1l4.2-3
    c0.2-0.1,0.4-0.2,0.6-0.2l5.1,0.1l4.3-1.7l1.7-5.2c0.1-0.2,0.2-0.4,0.4-0.5l3.2-2.3l-0.5-4.1l-4.4-7.5l-3.2-3.2
    c-0.1-0.1-0.2-0.3-0.3-0.4l-1.2-4.1l-4.4-3.5l-12,3.1c-0.1,0-0.2,0-0.4,0l-4.7-0.5l-6.6,0.5l-7.6,2.1c-0.2,0-0.3,0-0.5,0l-4.1-1
    c-0.5-0.1-0.8-0.6-0.7-1.1l0.9-5.1c0,0,0-0.1,0-0.1l1.2-4.3l-0.9-3.7l-1-2.8c-0.1-0.2-0.1-0.4,0-0.6l1-3.1l0.8-3.3l0.3-3.7l-0.6-4.7
    l-3.5-3.1l-12-5.6c-0.1,0-0.1-0.1-0.2-0.1l-6-4.5c-0.1,0-0.1-0.1-0.1-0.1l-3.9-4.4c-0.1-0.1-0.1-0.2-0.2-0.3l-4-10.2l-4,1
    c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1l-4.4-1.7c-0.2-0.1-0.3-0.2-0.5-0.4l-2.6-3.6l-4-2.4l-5.7-2.2
    l0,0.2c-0.1,0.3-0.3,0.5-0.5,0.7l-3.3,1.6l-0.7,1.6l-1.9,5.6c0,0.1-0.1,0.3-0.2,0.4l-3.7,3.7l-2.4,3.3l0.8,5
    c0.1,0.3-0.1,0.6-0.3,0.9c-0.2,0.2-0.6,0.3-0.9,0.3l-6.9-1.3l-5.2,1.4l-4.4,3.2l-4.1,4.1l-1.3,2.3l-1.1,2.5c0,0.1-0.1,0.2-0.2,0.3
    l-1.9,2.1c-0.1,0.1-0.3,0.2-0.5,0.3l-6.4,1.9l-1.2,2.2l-1.6,6.2c0,0.1-0.1,0.2-0.1,0.2l-5.9,10.3l-2.1,5.4l-0.9,6.5
    c0,0.1,0,0.2-0.1,0.3l-1.9,4c0,0.1-0.1,0.2-0.2,0.2l-6.7,7.5l8.7,13.6l4.6,4.9L175.3,265.1z"/>
  <path class="st2" d="M239,168.4c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0C238.8,168.4,238.9,168.4,239,168.4z"/>
  <polygon class="st2" points="243,167.5 247,177.6 243,167.5 "/>
  <path class="st3" d="M423.4,80.2l1.6-3.5l-3.1-2.6l-4.5-2.6c-0.3-0.2-0.5-0.5-0.5-0.9l-0.1-5.1c0-0.1,0-0.2,0-0.3l1.5-6.3l-1.4-3.9
    l-3.7-3.3c-0.1-0.1-0.3-0.3-0.3-0.5l-2.5-9.9l-3.2-6.7c0-0.1-0.1-0.2-0.1-0.3l-0.4-3.5c0-0.1,0-0.2,0-0.3l0.4-3.1l-1.6-1.7l-3.2-1
    l-3.5,1.8l-2.8,2.4c0,0-0.1,0.1-0.1,0.1l-10.8,6.3l-3.4,3.8l-2,4.5c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0l-1.8,2.3l-1.2,2.3l3.3,3.7
    l5.7,5.5c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1l1.2,6.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
    l-1.4,7l3.6,6.2l5.7,4.5c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0l3.8,4.3l3.5,3.1l4.4,0.9l5.5,0.1l3.8-1.5L423.4,80.2z"/>
  <path class="st3" d="M455.4,66.1c-0.1,0.1-0.2,0.1-0.2,0.1l-4,1.5c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0l-5.1-0.6
    c-0.1,0-0.2,0-0.3-0.1l-10.6-4.4c-0.1,0-0.1-0.1-0.2-0.1l-3-2.2c0,0-0.1,0-0.1-0.1l-3.7-3.4c0,0-0.1-0.1-0.1-0.1l-2.8-3.5
    c0-0.1-0.1-0.1-0.1-0.2l-1.7-3.4l-2.8-3.6c-0.1-0.1-0.2-0.3-0.2-0.4l-0.6-2.9c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0
    c0-0.1,0-0.3,0.1-0.4c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.3-0.3l3.2-2.2c0.1,0,0.1-0.1,0.2-0.1l7.1-3.1l2.5-1.6l0.6-0.4l-1.1-0.8
    c-0.1,0-0.1-0.1-0.1-0.1l-1.1-1.2c-0.2-0.2-0.3-0.4-0.3-0.6l-0.1-2.2c0-0.2,0-0.4,0.1-0.5l1-1.8c0-0.1,0.1-0.1,0.1-0.2l0.7-0.9
    l-1-0.3l-2.7,0.4l-2.6,0.7l-1.1,0.5c-0.5,0.2-1.1,0-1.3-0.5l-3.6-7.3l-9.9-14l-4.2-3.1l-10.4-1.2l-10.7,2.4l-9.4,4.8l-6.4,5.7
    l-3.1,5.7l-2.1,5.1c0,0.1-0.1,0.2-0.1,0.2l-0.8,1c-0.1,0.1-0.2,0.2-0.4,0.3l-1.8,0.8l-1.5,1.2l-0.6,1.7l2.4,1.5
    c0.1,0,0.1,0.1,0.2,0.2l5.8,5.9c0,0.1,0.1,0.1,0.1,0.2l3.6,5.7c0.2,0.3,0.2,0.6,0.1,0.8c-0.1,0.3-0.3,0.5-0.6,0.6l-3.5,1.4
    c-0.4,0.2-0.8,0.1-1.1-0.2l-1.5-1.6c-0.1-0.1-0.1-0.2-0.2-0.3l-1.2-2.6l-1.6-2.3l-3-1h-2.9l-2,0.4l-1.7,0.8l-6,5.1
    c-0.1,0.1-0.2,0.1-0.3,0.2l-4.8,1.8c-0.1,0-0.1,0-0.2,0l-9.4,1.8l2.9,3.1l6.1,2.9c0,0,0.1,0,0.1,0.1l4.7,3.1c0,0,0.1,0.1,0.1,0.1
    l3,2.6l3.9,0.9c0.1,0,0.2,0,0.2,0.1l4.2,2.2c0.1,0.1,0.3,0.2,0.4,0.3l5.1,7.5c0.1,0.1,0.1,0.3,0.2,0.4l0.5,4.1c0,0.1,0,0.2,0,0.4
    l-1,4.2c0,0.2-0.1,0.3-0.3,0.5l-3.3,3.4c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1s-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0-0.1,0
    L345,88.3l-3.2,3.5l0.2,5.8c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2l-1,5.6l0.9,4.6l5.2,4.9c0.2,0.1,0.3,0.3,0.3,0.5l1,5.1c0,0.1,0,0.3,0,0.4
    l-1.2,6l4.2,2.4l7.1-0.4c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0.1l5,2c0.2,0.1,0.3,0.2,0.5,0.4l8.5,12.3c0.1,0.1,0.1,0.2,0.1,0.4
    c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3l-1.5,5.3c0,0.1-0.1,0.3-0.2,0.4l-2.8,3.4l0.7,4.8l1.3,5.2l8.7,8.3
    c0.1,0.1,0.2,0.2,0.2,0.3l2.1,4.6l4.9-1.4l4.7-1.7l3.7-3.2c0,0,0.1,0,0.1-0.1l5-3.5c0,0,0.1-0.1,0.1-0.1l6.5-3.2l3.3-2.4l-0.3-1.3
    l-1-3.1c0-0.1,0-0.2,0-0.3l-0.2-4.2c0,0,0,0,0,0v0c0-0.1,0-0.3,0.1-0.4l2-4.9c0.1-0.1,0.2-0.3,0.3-0.4l4.7-4c0,0,0.1-0.1,0.1-0.1
    l3.8-2.3c0,0,0.1-0.1,0.1-0.1l12.9-5.3l13.4-8.1c0.1,0,0.1-0.1,0.2-0.1l6-1.9l4.7-3.4l3-4.8c0,0,0-0.1,0.1-0.1l3.3-4.1
    c0.1-0.1,0.2-0.2,0.3-0.3l3.7-2c0,0,0.1,0,0.2-0.1l3.4-1.2l4.2-2.7l1.3-3l-2.2-3.1c-0.1-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.2-0.1-0.3
    c0-0.2,0-0.4,0.1-0.5l1.4-2.9c0.1-0.3,0.4-0.5,0.7-0.5l11-2.3l3.5-2.4c0,0,0.1,0,0.1-0.1l8.4-4.4c0.1,0,0.2-0.1,0.2-0.1l5.9-1.3
    c0.2-0.1,0.5,0,0.7,0.1l3.3,1.9c0.1,0.1,0.2,0.2,0.3,0.3l0.3,0.4l6.8-3.4l-1.1-1.4l-3.4-1.8l-4-1.1l-3.4-0.4l-6.9,1
    c-0.2,0-0.4,0-0.5-0.1l-2.2-0.9c-0.2-0.1-0.4-0.2-0.5-0.4l-2.6-4.1c-0.1-0.1-0.1-0.2-0.1-0.3l-2.4-8.7c0,0,0-0.1,0-0.1l-0.7-5.2
    c0-0.1,0-0.2,0-0.3l0.4-2.9c0-0.1,0-0.2,0.1-0.3l1.3-2.9l-2.3-1.5L455,43.4l0,1l-0.2,9.6l0.2,2.6l0,0l3.5,3.6
    c0.1,0.1,0.2,0.2,0.2,0.4c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l-0.4,2.5c0,0.1-0.1,0.2-0.1,0.4c0,0,0,0.1-0.1,0.1
    c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0L455.4,66.1z M427.2,76.9l-2,4.3c0,0.1-0.1,0.1-0.1,0.2l-7.6,9.6l0,0c0,0,0,0,0,0
    c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1l0,0l-4.2,1.7c-0.1,0-0.2,0.1-0.4,0.1c0,0,0,0,0,0
    l-5.8-0.1c-0.1,0-0.1,0-0.2,0l-4.8-1c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1l-3.7-3.3
    c0,0-0.1-0.1-0.1-0.1l-3.7-4.2l-5.7-4.5c-0.1-0.1-0.2-0.2-0.2-0.3l-3.9-6.7c0,0,0,0,0,0c-0.1-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.1
    c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2l1.5-7.2l-1.1-5.8l-5.5-5.3l-3.8-4.2c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
    c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1v0c0,0,0,0,0,0c0,0,0,0,0,0
    c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.3l1.6-3c0,0,0.1-0.1,0.1-0.1l1.7-2.3l2.1-4.5c0-0.1,0.1-0.2,0.2-0.3l3.6-4
    c0.1-0.1,0.1-0.1,0.2-0.2l10.8-6.4l2.8-2.4c0.1-0.1,0.1-0.1,0.2-0.1l4-2c0.2-0.1,0.5-0.1,0.7-0.1l3.8,1.2c0.2,0.1,0.3,0.1,0.4,0.3
    l2.1,2.2c0.2,0.2,0.3,0.5,0.3,0.8l-0.5,3.5l0.4,3.2l3.1,6.7c0,0.1,0,0.1,0.1,0.2l2.4,9.7l3.7,3.3c0.1,0.1,0.2,0.2,0.3,0.4l1.6,4.4
    c0.1,0.2,0.1,0.4,0,0.6l-1.6,6.5l0.1,4.4l4.1,2.3c0.1,0,0.1,0.1,0.2,0.1l3.8,3.2C427.3,76,427.4,76.5,427.2,76.9z"/>
  <path class="st2" d="M408.4,150.4c0-0.1,0-0.3,0.1-0.4C408.4,150.1,408.4,150.2,408.4,150.4L408.4,150.4z"/>
  <polygon class="st2" points="381.6,174.8 386.5,173.4 381.6,174.8 "/>
  <polygon class="st2" points="509.9,86 509.9,86 509.6,85.6 "/>
  <path class="st2" d="M473.9,97.7c0-0.2,0-0.4,0.1-0.5l1.4-2.9l-1.4,2.9C473.9,97.4,473.9,97.6,473.9,97.7z"/>
  <polygon class="st2" points="476.2,101.4 474,98.2 476.2,101.4 "/>
  <polygon class="st2" points="409.9,159.3 409.9,159.3 409.6,158 408.6,154.8 409.6,158 "/>
  <path class="st2" d="M450.8,67.7c-0.1,0-0.1,0-0.2,0C450.7,67.7,450.7,67.7,450.8,67.7C450.8,67.7,450.8,67.7,450.8,67.7z"/>
  <path class="st2" d="M374.5,48.4C374.5,48.5,374.5,48.5,374.5,48.4C374.5,48.5,374.5,48.5,374.5,48.4
    C374.5,48.4,374.5,48.4,374.5,48.4z"/>
  <path class="st2" d="M374.5,48.7C374.5,48.7,374.5,48.7,374.5,48.7C374.5,48.7,374.5,48.7,374.5,48.7L374.5,48.7z"/>
  <path class="st2" d="M417.3,91.1c0,0,0.1-0.1,0.1-0.1C417.4,91,417.4,91,417.3,91.1C417.3,91.1,417.3,91.1,417.3,91.1z"/>
  <path class="st3" d="M268.7,100c0.1,0.1,0.2,0.2,0.2,0.3l3.8,8.3l4.6,1.8l4.2,1.2c0.1,0,0.1,0,0.2,0.1l4,2l6.7,2.2c0,0,0.1,0,0.1,0
    c0,0,0.1,0,0.1,0c0.1,0.1,0.2,0.1,0.3,0.2l3.6,4.2c0.1,0.1,0.1,0.2,0.2,0.4c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
    c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0l-1,5.3l3,3.1l6.3,2c0.1,0,0.2,0.1,0.3,0.1l4.2,3.1c0,0,0.1,0.1,0.1,0.1l2.9,2.9l3.4,0.6l3.8-0.3
    c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0,0,0,0l1.7,1.8l1.7,1.7c0.1,0.1,0.1,0.1,0.1,0.2
    l2.5,4.2l4,2.1c0.1,0,0.1,0.1,0.2,0.1l3.4,2.6c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.2,0.3c0,0,0,0,0,0c0.1,0.1,0.1,0.3,0.1,0.4l0.4,4.9
    l1.6,7.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2l-1.9,7.8c0,0.1-0.1,0.2-0.2,0.3l-4.4,6.2l0.4,3.5l4.9,3.6l6,3.6l5.3,0.9
    c0.2,0,0.3,0.1,0.4,0.2l4,2.9l7.2,4.1l3.7,1.7l0.2-0.4c0.1-0.3,0.3-0.5,0.6-0.6l3.9-1.6c0.2-0.1,0.4-0.1,0.6-0.1l3,0.5l2.8-1.1
    l1.8-3.1l-3-12c-0.1-0.4,0.1-0.8,0.4-1.1l5.4-3.9c0.1-0.1,0.2-0.1,0.3-0.1l2-0.6l-1.9-4.2l-8.8-8.4c-0.1-0.1-0.2-0.3-0.3-0.5
    l-1.4-5.6l-0.8-5.3c0-0.3,0-0.6,0.2-0.8l3-3.5l1.3-4.6l-8.1-11.7l-4.5-1.8l-7.2,0.4c0,0,0,0-0.1,0h0c-0.1,0-0.1,0-0.2,0
    c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1l-5.1-2.9c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
    c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.2-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0v0c0,0,0-0.1,0-0.1
    c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3l1.3-6.5l-0.9-4.6l-5.2-4.9c-0.2-0.1-0.3-0.3-0.3-0.5l-1-5.1c0-0.1,0-0.2,0-0.4l1-5.7l-0.2-6.1
    c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.3l3.7-4c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1,0,0.1,0
    c0,0,0,0,0.1,0l12.8-3.8l2.9-3l0.9-3.8l-0.4-3.7l-4.8-7.1l-3.9-2l-4-1c-0.2,0-0.3-0.1-0.4-0.2l-3.1-2.8l-4.6-3l-6.2-3
    c-0.1-0.1-0.2-0.1-0.3-0.2l-3.8-4.2l-19.5,3.5l-8.8,3.1l-4.3,2.4l-3.6,3.1c0,0-0.1,0.1-0.1,0.1l-4.3,2.7c-0.1,0-0.1,0.1-0.2,0.1
    l-18.2,6l-7,5.9l-6.8,8.2L248.1,97l1.7,0.6l13.5-1c0.2,0,0.4,0,0.6,0.2l4.6,3.1C268.6,99.9,268.6,100,268.7,100
    C268.6,100,268.6,100,268.7,100z"/>
  <path class="st2" d="M268.7,100C268.7,100,268.7,100,268.7,100c0.1,0.1,0.2,0.2,0.2,0.3C268.8,100.2,268.8,100.1,268.7,100z"/>
  <polygon class="st2" points="361.8,199.4 358.1,197.7 361.8,199.4 "/>
  <polygon class="st2" points="323.8,142 322.1,140.3 322.8,141 "/>
  <path class="st2" d="M268.5,99.9C268.6,99.9,268.6,100,268.5,99.9C268.6,100,268.6,100,268.5,99.9C268.6,100,268.6,99.9,268.5,99.9z
    "/>
  <path class="st2" d="M296.7,120.9c0,0,0-0.1,0-0.1c0,0,0,0,0,0C296.7,120.8,296.7,120.8,296.7,120.9z"/>
  <polygon class="st2" points="379.7,175.3 377.7,175.9 379.7,175.3 "/>
  <polygon class="st2" points="361.9,198.9 361.8,199.4 361.8,199.4 "/>
  <polygon class="st2" points="361.8,199.4 361.8,199.4 361.8,199.4 361.8,199.4 "/>
  <polygon class="st2" points="340,97.6 340,97.6 339,103.3 340,97.6 "/>
  <path class="st4" d="M160.7,436.5l0.8-0.5l1.6-1l2.4-2.2c0.1-0.1,0.2-0.1,0.3-0.2l3.3-1.3l0.6-2.2l-4.8-2.5l-3.8-1.4
    c-0.1,0-0.2-0.1-0.3-0.2l-3.6-3.3c-0.1-0.1-0.2-0.3-0.3-0.4l-2.1-6.3c0-0.1-0.1-0.3,0-0.4l0.9-13.4c0,0,0-0.1,0-0.1l1.2-6.5l0.1-5.6
    c0-0.1,0-0.2,0-0.3l1.6-5.4c0,0,0-0.1,0-0.1l1.9-4.3l-0.5-3.7c0-0.2,0-0.3,0.1-0.5l1.2-2.9c0.1-0.3,0.3-0.5,0.6-0.6l3.6-1.3l0-0.1
    l-0.1-2.7c0,0,0,0,0,0c0,0,0,0,0,0v-0.9l-4.8-3.1l-4.1-0.8c-0.3-0.1-0.6-0.3-0.8-0.6l-1.2-3.3c0-0.1-0.1-0.2-0.1-0.3l-0.1-3.8
    l-1.9-3.8l-3.1-4.8l-4-3.4l-11.7-3.4c-0.2-0.1-0.4-0.2-0.5-0.3l-4.5-5.4c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
    l-1-7.5l-2.6-6l-1.6-1.4l-2.1-1.8l-5-1.6l-10.9,12.7l-2.6,2.5l-0.8,2.4l-0.6,10c0,0.1,0,0.2-0.1,0.3l-1.2,3
    c-0.1,0.2-0.2,0.3-0.3,0.4l-4.9,3.8l-1.1,2.5l-0.6,2.9c0,0.2-0.2,0.4-0.3,0.6l-1.6,1.4c-0.1,0.1-0.1,0.1-0.2,0.1l-2.1,1.1l-2.4,1.6
    l-2.8,2.8l-2.6,4.5l-5.7,16c0,0,0,0.1-0.1,0.1l-4.2,7.6l-2.7,7.7c0,0.1-0.1,0.2-0.1,0.3l-2,2.8c0,0.1-0.1,0.1-0.1,0.1l-6.9,6.6
    l-1.1,2.7l-1.6,7c0,0.1-0.1,0.2-0.1,0.3l-0.3,0.5l6.5,3.7l21.7-2.6c0.1,0,0.1,0,0.2,0l7.6,0.4c0.1,0,0.1,0,0.2,0l6.6,1.8l11.6,4.4
    l19.6,2.3c0.1,0,0.2,0,0.3,0.1l12,5.9l5.6-0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0L160,437L160.7,436.5L160.7,436.5
    C160.7,436.5,160.7,436.5,160.7,436.5z"/>
  <path class="st1" d="M118.6,545.3l2.1,4.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0l1.6,4.9l9.7,9.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
    c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1c0,0.1,0.1,0.2,0.1,0.3l1,7.5c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l-1.8,14l1.4,4.1
    l4,3.1h3.2l5.1-2.3c0.1,0,0.2-0.1,0.3-0.1l5-0.6c0.1,0,0.3,0,0.4,0l3.6,1.1l7,0.8l-0.8-5.3l-1.4-4.4c0-0.1,0-0.2,0-0.3l0.1-5
    c0-0.3,0.1-0.5,0.3-0.7l3.6-3.5c0.2-0.2,0.5-0.3,0.8-0.3l6.8,0.7l5.5-0.6l12-7.1l4.4-5.3c0-0.1,0.1-0.1,0.2-0.2l7.9-6.1
    c0,0,0.1-0.1,0.1-0.1l7.5-4.2l4-4.8l3.5-6.7c0.1-0.1,0.2-0.2,0.3-0.3l8.5-6.8c0.1,0,0.1-0.1,0.2-0.1l15.2-7.7l-22.8-0.2
    c-0.1,0-0.1,0-0.2,0l-7.6-1.4c-0.4-0.1-0.7-0.3-0.8-0.7l-1.1-3.8c0-0.1,0-0.1,0-0.2l-0.4-5.7l-2.6-6.3c0,0,0-0.1,0-0.1l-1.2-4.8
    c-0.1-0.3,0-0.5,0.1-0.7l2.9-4.9l0-0.2l0.2-4.2l-4.5-2.4l-4-1.2l-6.8-2.6l-5.4-0.6l-0.2,4l0,3.3c0,0.4-0.3,0.8-0.7,1l-4,1.3
    c0,0-0.1,0-0.1,0l-11.7,2.4c0,0-0.1,0-0.1,0l-6.3,0.6c-0.4,0-0.7-0.1-0.9-0.5l-2.3-3.7l-4.1-3l-7.8,2.3c0,0-0.1,0-0.1,0l-3.7,0.5
    c-0.2,0-0.3,0-0.5-0.1l-12.6-4.6c-0.2-0.1-0.3-0.2-0.4-0.3l-5-5.8l-4.9-4.3l-6.4,0.4l-12.5,2.3l-7,1.9l-16.1,9.3l-3.7,3.7
    c-0.1,0.1-0.1,0.1-0.2,0.2l0,0l-4.3,2.3l-6.9,5.6c-0.1,0.1-0.3,0.2-0.5,0.2l-4.9,0.7l-1.8,2.5l-1.7,4.8c0,0,0,0,0,0c0,0,0,0.1,0,0.1
    c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3l-3.9,3.2c-0.2,0.1-0.4,0.2-0.6,0.2c0,0,0,0,0,0l-6.1-0.1
    c-0.2,0-0.3,0-0.5-0.1l-8-4.5l-10.4,1.3l0.2,6.8l7.5-0.4c0.4,0,0.8,0.2,0.9,0.5c0.2,0.3,0.1,0.8-0.1,1.1l-3.9,5.1l-0.8,2.4
    c0,0.1-0.1,0.2-0.2,0.3l-1.6,1.8l-0.2,0.8l2.4,1.2c0.3,0.2,0.6,0.5,0.6,0.9v1.9c0,0.2,0,0.3-0.1,0.5l-1.7,3.2l3.9,7.5
    c0,0,0,0.1,0.1,0.1l3.7,10.5c0.1,0.3,0.1,0.7-0.1,0.9c-0.2,0.3-0.5,0.4-0.8,0.4l-5.4-0.1l-3,0.2l-2.1,4.9v2.1l1.5,0.1
    c0.1,0,0.2,0,0.2,0l1.3,0.4l1.8-1.3c0.3-0.2,0.6-0.2,0.9-0.1c0.3,0.1,0.5,0.4,0.6,0.7l0.9,3.2c0.1,0.4,0,0.7-0.3,1
    c-0.3,0.3-0.7,0.3-1,0.2l-1-0.4l-2.8,1.2l1.2,0.4l8.8,1.3l7,1.7l5.6-2.5l2.3-4.6c0.1-0.2,0.2-0.3,0.4-0.4l5.8-3.6l6.5-5l4.7-4.8
    c0,0,0.1-0.1,0.1-0.1l6.1-4.6c0.1,0,0.1-0.1,0.2-0.1l7.8-3.5c0.1,0,0.1-0.1,0.2-0.1l15.2-2.8c0,0,0.1,0,0.1,0l8.5-0.5
    c0.1,0,0.3,0,0.4,0l7.2,2.4c0.1,0,0.1,0.1,0.2,0.1l4,2.4c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1C118.4,545.1,118.5,545.2,118.6,545.3z
     M117.6,530.2l-6.7,2.7c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0-0.2,0l-7.3-1.7c-0.1,0-0.2-0.1-0.3-0.1l-6.9-4.4l-6-2.4
    c-0.1,0-0.2-0.1-0.3-0.2c0,0,0,0-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3l-0.9-5.6
    c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.2-0.4l3.8-4.1c0.1-0.2,0.3-0.3,0.6-0.3l9.3-1.6l7.9-0.7
    c0.1,0,0.3,0,0.4,0.1l5.6,2.1c0,0,0.1,0,0.1,0.1l4.1,2.2c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1l2.5,4
    c0,0.1,0.1,0.1,0.1,0.2l2.5,6.8c0.2,0.4,0,0.9-0.3,1.2l-3,2.2c-0.1,0.1-0.3,0.2-0.5,0.2L117.6,530.2z"/>
  <path class="st1" d="M96.8,524.9l6.9,4.4l6.8,1.6l6.5-2.6c0.1,0,0.2-0.1,0.2-0.1l4.2-0.6l2.2-1.6l-2.2-6l-2.3-3.7l-3.8-2l-5.3-2
    l-7.6,0.7l-8.9,1.5l-3.2,3.5l0.7,4.6l5.6,2.2C96.7,524.8,96.8,524.9,96.8,524.9z"/>
  <path class="st1" d="M442.3,277.9c-0.1,0-0.3-0.1-0.4-0.1l-18.2-10.9l-2.9,0.7l-2.2,5.5c0,0.1-0.1,0.1-0.1,0.2v0c0,0,0,0,0,0
    c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1-0.1,0.1-0.2,0.2l-4.9,4.5c-0.2,0.2-0.4,0.3-0.7,0.3h-6.5c-0.1,0-0.2,0-0.4-0.1
    l-8.2-3.2l-14.9-3.8c-0.1,0-0.1,0-0.2-0.1l-6.5-3.1c-0.1,0-0.1-0.1-0.2-0.1l-1.8-1.5l-3.8,4l0,0.2l-0.4,3.7c0,0.1,0,0.2-0.1,0.3
    l-2.4,6.2c0,0.1-0.1,0.2-0.2,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
    c-0.1,0.1-0.1,0.1-0.2,0.2l-7.3,4.6l-2.8,4.7l-1.3,5c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
    c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
    c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0s0,0,0,0
    c0,0,0,0,0,0v0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0l-2.2,2.7l-0.9,1.1
    c-0.1,0.1-0.2,0.2-0.3,0.3l-3.1,1.6l-1,1.8c-0.1,0.1-0.2,0.2-0.3,0.3l-2.3,1.6l1.3,5.6c0,0.1,0,0.1,0,0.2l0.2,3.7c0,0,0,0,0,0v0
    c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.3-0.4,0.4l-5.2,2.4c0,0,0,0,0,0c0,0-0.1,0-0.1,0l-6.4,2.1l-3.2,2.5l0.2,9l2.6,2.7l5.1,3.3
    c0.2,0.1,0.3,0.3,0.4,0.5l1.5,4.5c0,0.1,0.1,0.3,0,0.4l-0.3,3.9c0,0.2-0.1,0.4-0.2,0.5l-7.9,10.3l-5,10.5l-2.1,7.1l1.8,2.9
    c0,0,0.1,0.1,0.1,0.1l1.8,4.5c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3l-0.6,7.6l-1.6,10c0,0,0,0,0,0
    c0,0,0,0.1,0,0.1l-3,8.8c0,0,0,0.1,0,0.1l-3.9,8.5l-2.4,6.8l-0.2,3.6c0,0.2-0.1,0.3-0.1,0.5l-5.4,8.9l1.8,3.4l3.1,4
    c0.1,0.1,0.2,0.3,0.2,0.4l1.2,5.4l0.9,7.3c0,0.1,0,0.2,0,0.3l-1.2,8.4c0,0,0,0.1,0,0.1l-3.2,12.6l-0.7,2.2l-0.9,3
    c-0.1,0.2-0.2,0.4-0.4,0.5l-4.5,3.1c-0.1,0-0.1,0.1-0.2,0.1l-7.3,2.7l-3.7,4.8l-1.3,8.2c0,0.2-0.2,0.4-0.3,0.6l-5.3,4.7
    c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0l-7.3,0.3l-7.1,1.5l-4.8,3.6l-1.4,3.7c0,0,0,0.1-0.1,0.1l-1.3,2.3
    l1.6,5.8c0.1,0.4,0,0.8-0.3,1l-3.1,2.8c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0.1l-4.9,2.9l-0.4,0.2l1.6,5c0,0.1,0,0.2,0,0.3
    c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.4l-3,5c-0.1,0.1-0.1,0.2-0.2,0.2c0,0,0,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1
    c0,0,0,0,0,0l-5.2,2.2l-1.1,2.5l0.8,3.3l5.3,3.4c0,0,0.1,0,0.1,0.1l6.3,5.2c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0c0,0,0,0,0,0
    c0,0,0,0,0,0l0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0.1,0.1,0.1,0.1,0.2l2.8,7.6c0,0.1,0,0.1,0.1,0.2l0.3,2.5
    l1.2,7.2l4.7,7.6l7.5,2.7l6.2,2.9c0.1,0,0.2,0.1,0.3,0.2l4.8,4.6l6,0.9c0,0,0.1,0,0.1,0l5.7,1.5c0.3,0.1,0.5,0.2,0.6,0.5l9.1,15.8
    l11.3,9.4l3.8,0.5l3.8,0.5l5.8-3.3l0.8-5.6l-0.1-6.6c0-0.2,0-0.3,0.1-0.5l3.1-6l4.8-13.1l3.7-7.7l2.4-8.3c0,0,0-0.1,0-0.1l2.7-6.6
    c0-0.1,0.1-0.1,0.1-0.2l3.4-5.2l3.4-6.6l-0.6-6.5l-1.7-6.8c-0.1-0.3,0-0.6,0.2-0.8l3.9-5.4c0.1-0.1,0.2-0.2,0.4-0.3l10.8-5.2
    c0.2-0.1,0.5-0.1,0.7-0.1l2.4,0.7l10.8-63c0-0.1,0-0.2,0.1-0.3l4.7-9.7l8-40c0-0.1,0-0.1,0-0.2l6.4-16.9l3.2-16.2l0.4-6.3
    c0,0,0-0.1,0-0.1l0.7-3.5c0-0.1,0.1-0.2,0.1-0.3l3.6-5.7l1-3.3l-0.1-2.8l-0.6-1l-1.1-0.9c-0.1-0.1-0.2-0.2-0.2-0.2l-1.5-2.3
    c0-0.1-0.1-0.1-0.1-0.2l-1.8-5.2c0-0.1,0-0.2-0.1-0.3l-0.2-4.7c0-0.1,0-0.2,0.1-0.4l1.5-4.4c0-0.1,0.1-0.2,0.1-0.2l7.3-10.7l2.9-12
    c0-0.1,0-0.1,0.1-0.2l2.8-6c0.1-0.1,0.1-0.2,0.2-0.3l2.5-2.4c0,0,0.1,0,0.1-0.1l6.5-5l0.6-0.9l-0.6-1.7c-0.1-0.3-0.1-0.6,0.1-0.9
    l1.4-2c0.1-0.1,0.1-0.2,0.2-0.2l4.6-3.6l6.1-8.5c0,0,0.1-0.1,0.1-0.1l5.7-5.6l1.1-2l-0.1-0.6l-1.5-2.7l-0.3-0.3h-0.6
    c-0.1,0-0.1,0-0.2,0L442.3,277.9z"/>
  <path class="st2" d="M262,540.4C262,540.3,262,540.3,262,540.4L262,540.4C262,540.3,262,540.3,262,540.4z"/>
  <polygon class="st2" points="374.1,266.4 375.9,267.9 374.1,266.4 "/>
  <path class="st4" d="M183.1,478.4c0.1,0,0.2,0,0.4,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.2c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.2
    l2.3,3.2l5.7,0.7c0.1,0,0.2,0,0.2,0.1l6.8,2.6l4,1.2c0.1,0,0.1,0,0.2,0.1l5.1,2.8c0.3,0.2,0.5,0.5,0.5,0.9l-0.3,5.3
    c0,0.2-0.1,0.3-0.1,0.5l-2.8,4.7l1.1,4.3l2.7,6.4c0,0.1,0.1,0.2,0.1,0.3l0.4,5.8l0.9,3.1l6.9,1.3l25.5,0.2c0.1,0,0.1,0,0.2,0
    l0.9,0.2c0,0,0,0,0,0c0,0,0,0,0,0l4.2,1l0.1,0c0,0,0.1,0,0.1,0l5.8,2.2c0.2,0.1,0.3,0.2,0.5,0.4l4.2,5.9c0.1,0.1,0.1,0.2,0.1,0.3
    l0.3,0.9l4.9-2.8l2.6-2.3l-1.5-5.6c-0.1-0.3,0-0.5,0.1-0.8l1.5-2.6l1.5-3.9c0.1-0.2,0.2-0.3,0.3-0.4l5.2-3.9
    c0.1-0.1,0.2-0.1,0.4-0.2l7.4-1.6c0.1,0,0.1,0,0.2,0l7-0.3l4.8-4.2l1.2-8.1c0-0.2,0.1-0.3,0.2-0.5l4-5.2c0.1-0.1,0.3-0.3,0.4-0.3
    l7.5-2.8l4.1-2.8l1.5-4.9l3.1-12.3l1.2-8.2l-0.9-7.1l-1.1-5.1l-3.1-3.9c0,0-0.1-0.1-0.1-0.2l-2.1-4c0-0.1-0.1-0.2-0.1-0.3
    c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.3,0.1-0.4l5.6-9.2l0.2-3.4
    c0-0.1,0-0.2,0.1-0.3l2.5-7l3.9-8.5l3-8.7l1.6-9.8l0.6-7.3l-1.7-4.2l-2-3.2c-0.1-0.2-0.2-0.5-0.1-0.8l2.3-7.6c0,0,0,0,0-0.1
    c0,0,0-0.1,0-0.1l5.1-10.7c0-0.1,0.1-0.1,0.1-0.2l7.8-10.2l0.3-3.4l-1.3-4l-4.9-3.2c-0.1,0-0.1-0.1-0.2-0.2l-2.9-3.1
    c-0.2-0.2-0.3-0.4-0.3-0.7l-0.2-9.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2
    c0.1-0.1,0.2-0.3,0.3-0.4l3.7-2.9c0.1-0.1,0.2-0.1,0.3-0.2l6.5-2.2l4.5-2.1l-0.2-2.9l-1.2-5.4l-12.7-1.2c0,0-0.1,0-0.1,0l-4.6-1
    l-4.3,0.3l-4.8,1.9l-6.1,2.4c-0.1,0-0.1,0-0.2,0.1l-6.3,1c0,0-0.1,0-0.1,0l-6,0.2l-8.6,4.6c-0.1,0-0.2,0.1-0.3,0.1l-4.2,0.8
    l-4.8,2.8l-7.8,7.5c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.2l-5,1.9c-0.1,0-0.2,0.1-0.4,0.1c0,0,0,0,0,0l-6.2-0.1l-4.2,3.5l-0.8,1.7
    l-1.7,3.6c0,0,0,0,0,0c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0.1-0.2,0.2-0.4,0.2l-3.5,0.9
    c-0.1,0-0.2,0-0.2,0h0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.3-0.1-0.4-0.2l-8.3-6.3l-2.3,1.4l-5.7,7.6c0,0,0,0,0,0
    c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0.1-0.3,0.1-0.4,0.1l-6,0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0
    l-9.8-1.4l-5.8,2.7l-3.5,8.2l-7.6,14.5c0,0.1-0.1,0.2-0.2,0.3l0,0c0,0,0,0,0,0l-5,4.8c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1
    c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.1l-4.2,0.6c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-17.9-2.5l-3.2-0.1l0.1,2.5c0,0.4-0.3,0.8-0.7,1
    l-3.9,1.4l-0.9,2.3l0.5,3.7c0,0.2,0,0.4-0.1,0.5l-2,4.5L159,389l-0.1,5.6c0,0.1,0,0.1,0,0.2l-1.2,6.5l-0.9,13.1l2,5.9l3.3,3l3.7,1.3
    c0,0,0.1,0,0.1,0.1l5.6,2.9c0.4,0.2,0.6,0.7,0.5,1.2l-1,3.5c-0.1,0.3-0.3,0.5-0.6,0.7l-3.6,1.4l-2.4,2.2c0,0-0.1,0.1-0.1,0.1
    l-1.8,1.2l1.7,6.5c0.1,0.2,0,0.4,0,0.6l-1.9,4.6l-1,6.9l1,7.6c0,0.2,0,0.3-0.1,0.5l-1.9,5.1l-0.8,5.1l3.6,4.2l5.3,2.3l6.5-1.9
    c0.1,0,0.1,0,0.2,0L183.1,478.4z"/>
  <path class="st2" d="M248.5,523.3l-4.2-1c0,0,0,0,0,0c0,0,0,0,0,0L248.5,523.3z"/>
  <polygon class="st2" points="162.4,437.8 162.4,437.8 164.2,436.6 "/>
  <polygon class="st2" points="156.8,414.4 156.8,414.4 157.7,401.3 "/>
  <polygon class="st2" points="243.2,522.1 217.7,521.9 210.8,520.7 217.7,521.9 "/>
  <path class="st2" d="M244.3,522.4C244.3,522.4,244.3,522.4,244.3,522.4l-0.9-0.2L244.3,522.4C244.3,522.4,244.3,522.4,244.3,522.4z"
    />
  <path class="st2" d="M244.3,522.4C244.3,522.4,244.3,522.4,244.3,522.4C244.3,522.4,244.3,522.4,244.3,522.4L244.3,522.4z"/>
  <path class="st2" d="M282,508.7c-0.1,0-0.1,0-0.2,0l-7.4,1.6l7.4-1.6C281.9,508.8,282,508.7,282,508.7z"/>
  <path class="st4" d="M130.5,331.8l4.1,4.9l11.6,3.4c0.1,0,0.3,0.1,0.4,0.2l4.3,3.6c0.1,0.1,0.1,0.1,0.2,0.2l3.2,5c0,0,0,0.1,0.1,0.1
    l2,4c0.1,0.1,0.1,0.3,0.1,0.4l0.1,3.8l0.9,2.6l3.7,0.7c0.1,0,0.3,0.1,0.4,0.1l5.4,3.5c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.2,0.2
    c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2v0v0.4l3.3,0.2
    c0,0,0.1,0,0.1,0l17.8,2.5l3.7-0.5l4.7-4.5l7.5-14.3l3.7-8.4c0.1-0.2,0.3-0.4,0.5-0.5l6.4-3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
    c0.1,0,0.2,0,0.3,0l0,0c0,0,0,0,0,0l0,0l10,1.4l5.4-0.2l5.5-7.3c0.1-0.1,0.2-0.2,0.3-0.2l3-1.9c0,0,0,0,0,0c0,0,0.1,0,0.1,0
    c0.1,0,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.2l8.5,6.4l2.6-0.7l1.2-2.5l1.2-2.5
    c0.1-0.1,0.2-0.2,0.3-0.3l4.7-3.9c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.1,0.4-0.1l6.4,0.1l4.6-1.8l7.8-7.5
    c0.1-0.1,0.1-0.1,0.2-0.1l5.1-2.9c0,0,0,0,0,0l0,0c0.1-0.1,0.2-0.1,0.3-0.1l4.2-0.8l8.7-4.6c0,0,0,0,0,0c0.1-0.1,0.3-0.1,0.4-0.1
    l6.1-0.2l6.1-1l11-4.4c0.1,0,0.2-0.1,0.3-0.1l4.6-0.3c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0h0c0,0,0,0,0,0l4.6,1l13,1.3l2.3-1.7l1.1-1.9
    c0.1-0.2,0.2-0.3,0.4-0.4l3.2-1.6l2.8-3.5l1.2-4.9c0-0.1,0.1-0.2,0.1-0.3l3-5c0.1-0.1,0.2-0.2,0.3-0.3l7.2-4.5l2.2-5.8l0.4-4.1
    c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
    c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0.1-0.1,0.1-0.1l3.9-4.1l-4.8-3.8h-4.3l-4.5,2.2
    c-0.1,0-0.2,0.1-0.3,0.1l-4,0.6c-0.2,0-0.5,0-0.7-0.2l-3.7-2.5l-4.2-2.5l-4.7-1c-0.3-0.1-0.6-0.3-0.7-0.6l-1.4-3.8
    c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.1l0.5-4.7l-2.8-0.8c0,0,0,0,0,0c0,0,0,0,0,0l-2.6-0.7l-10.1,1l-0.6,0.4l0,0c0,0,0,0,0,0
    l-3.4,2.4l-1.7,5.3c-0.1,0.3-0.3,0.5-0.6,0.6l-4.9,2c-0.1,0.1-0.3,0.1-0.4,0.1l-5-0.1l-3.9,2.8l-4.5,4.4c-0.1,0.1-0.1,0.1-0.2,0.2
    l-5.8,3.2l-11.5,9.1c-0.1,0.1-0.1,0.1-0.2,0.1l-6.8,2.9l-8.8,4.7c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0
    c-0.1,0-0.2,0-0.4-0.1l-5.4-2.1c-0.1-0.1-0.3-0.1-0.4-0.3l-4.8-5.4l-5.6-2.3l-9.4-0.8l-4.8,2.9l-0.8,6.9c0,0.3-0.2,0.6-0.5,0.8
    l-5.2,2.8c-0.1,0.1-0.3,0.1-0.4,0.1l-12.1,0.7c0,0,0,0-0.1,0c0,0,0,0,0,0s0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0
    c-0.1,0-0.2-0.1-0.2-0.1l-11.4-6.6l-6.4-1.2c-0.1,0-0.2,0-0.3-0.1l-7.3-3.6c-0.1,0-0.1-0.1-0.2-0.1l-15.1-11.1l-6.4-5.6
    c0,0,0,0-0.1-0.1l-4.7-5c0,0-0.1-0.1-0.1-0.1l-8.5-13.2L142,256.4l-3.3,5.3l-2.8,9.8c0,0.1-0.1,0.2-0.1,0.2l-9.2,16l-2.7,12.1
    c0,0,0,0.1,0,0.1l-2.2,6.5c-0.1,0.2-0.2,0.3-0.3,0.5l-3,2.5l-0.7,3c0,0,0,0,0,0l4.5,1.5c0.1,0,0.2,0.1,0.3,0.2l4,3.5
    c0.1,0.1,0.2,0.2,0.3,0.4l2.7,6.3c0,0.1,0.1,0.2,0.1,0.3L130.5,331.8z"/>
  <path class="st2" d="M336.8,247.8C336.8,247.8,336.8,247.8,336.8,247.8C336.8,247.8,336.8,247.8,336.8,247.8l-2.6-0.7L336.8,247.8
    C336.9,247.8,336.9,247.8,336.8,247.8C336.9,247.8,336.9,247.8,336.8,247.8L336.8,247.8z"/>
  <path class="st2" d="M336.8,247.8C336.8,247.8,336.8,247.8,336.8,247.8L336.8,247.8C336.8,247.8,336.8,247.8,336.8,247.8z"/>
  <polygon class="st2" points="323.5,248.5 323.5,248.5 320.1,250.9 "/>
  <path class="st2" d="M215.3,289.4C215.3,289.4,215.3,289.4,215.3,289.4C215.3,289.4,215.3,289.4,215.3,289.4
    C215.3,289.4,215.3,289.4,215.3,289.4C215.3,289.4,215.3,289.4,215.3,289.4z"/>
  <path class="st2" d="M368.5,269.5C368.5,269.5,368.5,269.5,368.5,269.5C368.5,269.6,368.5,269.6,368.5,269.5
    C368.5,269.5,368.5,269.5,368.5,269.5z"/>
  <polygon class="st2" points="197.1,363.4 197.1,363.4 192.4,367.9 192.4,367.9 "/>
  <polygon class="st2" points="266.6,325.2 260.2,325.1 266.6,325.2 "/>
  <path class="st2" d="M254.8,329.3l4.7-3.9L254.8,329.3c-0.1,0.1-0.2,0.2-0.3,0.3l-1.2,2.5l1.2-2.5
    C254.6,329.5,254.7,329.4,254.8,329.3z"/>
  <polygon class="st2" points="284.3,313 284.3,313 284.3,313 "/>
  <path class="st2" d="M279.2,315.9l5.1-2.9L279.2,315.9c-0.1,0-0.1,0.1-0.2,0.1l-7.8,7.5l7.8-7.5C279,316,279.1,315.9,279.2,315.9z"
    />
  <path class="st4" d="M184.3,482.8c0-0.1,0-0.2,0-0.2l-1.6-2.2l-7.5,0.8l-6.7,2c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
    c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0l-5.8-2.5c-0.1-0.1-0.3-0.2-0.4-0.3l-4-4.7c-0.2-0.2-0.3-0.5-0.2-0.8l0.9-5.7c0-0.1,0-0.1,0-0.2
    l1.8-5l-1-7.5c0-0.1,0-0.2,0-0.3l1-7.2c0-0.1,0-0.2,0.1-0.3l1.9-4.4l-1.5-5.5c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0
    c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0l-11.9-2.4l-5.7,0.1c-0.2,0-0.3,0-0.5-0.1l-12-5.9l-19.5-2.3c-0.1,0-0.2,0-0.2-0.1
    L98.6,424l-6.4-1.7l-7.4-0.4l-21.9,2.6c-0.2,0-0.4,0-0.6-0.1l-6.7-3.9l-0.3,0.6c-0.1,0.1-0.2,0.3-0.3,0.3l-5.4,4.2l-0.6,1.3
    l-1.1,2.7c0,0.1-0.1,0.2-0.1,0.2l-6,8l-1.2,2.8l-2.3,8.7l-1.3,9.9c0,0.1-0.1,0.2-0.1,0.4l-4.2,7.6l-2.7,9.6L29.2,489l0.2,16.3
    c0,0.2-0.1,0.5-0.3,0.7l-2.9,3.2l9-1.1c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0.1,0,0.2,0.1,0.2,0.1l8.1,4.6l5.5,0.1l3.4-2.8l1.6-4.6
    c0-0.1,0.1-0.2,0.1-0.2l2.1-3c0.2-0.2,0.4-0.4,0.7-0.4l5-0.8l6.8-5.5c0,0,0.1-0.1,0.2-0.1l4.3-2.3l3.7-3.7c0.1-0.1,0.1-0.1,0.2-0.2
    l16.3-9.4c0.1,0,0.2-0.1,0.2-0.1l7.2-2l12.7-2.3c0,0,0.1,0,0.1,0l6.9-0.4c0.2,0,0.5,0.1,0.7,0.2l5.3,4.6c0,0,0.1,0.1,0.1,0.1
    l4.8,5.7l12.1,4.4l3.4-0.5l8.2-2.4c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0c0.1,0,0.3,0.1,0.4,0.2l4.7,3.4c0.1,0.1,0.2,0.2,0.3,0.3
    l2.1,3.4l5.6-0.5l11.6-2.4l3.3-1.1v-2.7v0L184.3,482.8z"/>
  <path class="st2" d="M160.3,439c-0.1,0-0.2,0-0.2,0C160.2,439,160.2,439,160.3,439C160.3,439,160.3,439,160.3,439z"/>
  <path class="st2" d="M62.3,424.3c0.2,0.1,0.4,0.2,0.6,0.1C62.7,424.5,62.5,424.4,62.3,424.3z"/>
  <path class="st2" d="M147.4,489.7l8.2-2.4c0.1,0,0.1,0,0.2,0c-0.1,0-0.2,0-0.2,0L147.4,489.7z"/>
  <polygon class="st2" points="160.7,439 162.1,444.5 160.7,439 "/>
  <polygon class="st2" points="182.7,480.4 184.3,482.6 184.3,482.6 "/>
  <path class="st1" d="M324.3,628.6C324.3,628.6,324.3,628.6,324.3,628.6l-11.7-9.7c0,0,0,0-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-9-15.6
    l-5.2-1.4l-6.2-0.9c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.2l-4.9-4.7l-6-2.8l-7.7-2.8c-0.2-0.1-0.4-0.2-0.5-0.4
    l-5-8.1c-0.1-0.1-0.1-0.2-0.1-0.4l-1-6.3l-0.8,0.2l-13.6,3.3l-6.2,4l-3.9,3.8c0,0-0.1,0.1-0.2,0.1l-7.4,4.9l-0.8,5l2.2,5.8
    c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0.1l-2.7,4.9
    c-0.1,0.2-0.2,0.3-0.4,0.4l-6.9,3.8l-0.7,6.2l1.6,7.7l0.4,2.1c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0l-3,13.1l-0.1,11.1
    c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3l-4.3,5.9c0,0.1-0.1,0.1-0.2,0.2l-8.2,7l-2.2,9.3l1.2,7.7l2.8,4.3l6.1,6.3
    c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1l1.2,4.9
    c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1c0,0,0,0,0,0
    c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0l-2.5,0.3l2.8,5.3c0,0,0,0,0,0
    c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3l0.6,4.2l4.7,1.1c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0h0c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1
    l5.5,3.6c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1
    c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2l-2.8,13.7l-0.1,5.1c0,0.1,0,0.2-0.1,0.4l-2,5
    c0,0.1-0.1,0.2-0.2,0.3c0,0,0,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0l-9.7,4.1l-4.7,2.7c-0.1,0.1-0.2,0.1-0.3,0.1
    l-6.1,1.3l-1.3,0.3l-5,4.2l-2.3,6.3c0,0.1,0,0.1-0.1,0.1l-3.7,6.5l-2.9,7.3l-0.8,14.4l1.2,6.3l2,6c0,0.1,0,0.1,0,0.2l1.1,7.1
    c0,0.1,0,0.1,0,0.2v5.2l2.4,4.7l4.5,5.5c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1
    c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l-2.5,3.8
    c-0.1,0.1-0.2,0.2-0.3,0.3l-5.1,3.1l-1.9,3.5l-0.4,3.9l2.3,3.4l3.2,1.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
    c0,0,0,0,0,0l0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0h0l0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0
    c0.1,0,0.2,0.1,0.2,0.2h0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0s0,0,0,0
    s0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1l3.1,4.4c0.1,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0l-0.1,5.6
    l0.6,5.8l3,5.6l4.4,4.7l9.8,4.5l3.3-0.2l-0.3-13.7c0,0,0-0.1,0-0.1l1.7-16.1c0,0,0-0.1,0-0.1l1.4-5.7c0-0.1,0.1-0.2,0.1-0.3
    l9.4-16.6l4.8-16.6l1.9-12.2c0-0.1,0.1-0.2,0.1-0.3l3.3-6c0,0,0.1-0.1,0.1-0.1l7.9-9.9l2.5-4.9l5.2-13.4c0-0.1,0.1-0.2,0.1-0.3
    l2.1-2.7c0.1-0.1,0.2-0.2,0.3-0.3l2.5-1.3l10.7-11.8c0.1-0.1,0.1-0.1,0.2-0.2l16-10.7l4.6-4.4l4.7-6l2.1-3.3l1.1-3l0.2-2.6l-0.6-6.1
    c0-0.1,0-0.2,0-0.2l0.4-2.7c0-0.2,0.1-0.4,0.2-0.5l3.3-3.8c0,0,0.1-0.1,0.1-0.1l13.7-11.7l1.2-2.5c0,0,0-0.1,0.1-0.1l10.2-14.7
    l2-5.7l2.9-13c0-0.1,0.1-0.2,0.1-0.3l4.7-8.3l1.7-5.7c0,0,0-0.1,0-0.1l1-2.3c0-0.1,0.1-0.2,0.2-0.3l2.2-2.2c0,0,0.1-0.1,0.1-0.1
    l4.6-3.5l1.9-1.9l4.7-9.6l1-9.5L359,598c0,0,0-0.1,0-0.2l1.5-11.4c0-0.1,0-0.1,0-0.2l2.1-6.1c0,0,0-0.1,0.1-0.1l2.6-4.9
    c0,0,0.1-0.1,0.1-0.1l7-9.3l2.1-4.7l4.4-25.4l-2.3-0.6l-10.2,4.9l-3.5,4.8l1.6,6.5c0,0,0,0.1,0,0.2l0.6,6.9c0,0.2,0,0.4-0.1,0.5
    l-3.6,6.9l-3.4,5.2l-2.6,6.5l-2.4,8.3c0,0.1,0,0.1-0.1,0.2l-3.7,7.7l-4.8,13.1c0,0,0,0.1,0,0.1l-3,5.9l0.1,6.5c0,0.1,0,0.1,0,0.2
    l-0.9,6.2c0,0.3-0.2,0.6-0.5,0.7l-6.5,3.7c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
    l-8.2-1.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0C324.5,628.7,324.4,628.7,324.3,628.6z"/>
  <path class="st2" d="M219.2,658.9c0,0.1-0.1,0.1-0.2,0.2C219.1,659.1,219.1,659,219.2,658.9l4.3-5.9c0.1-0.1,0.1-0.2,0.1-0.3
    c0,0.1-0.1,0.2-0.1,0.3L219.2,658.9z"/>
  <polygon class="st2" points="265.4,575.2 266.2,575 266.2,575 "/>
  <path class="st2" d="M220.1,699.9C220.1,699.9,220.1,699.9,220.1,699.9C220.1,699.9,220.1,699.9,220.1,699.9
    C220.1,699.9,220.1,699.9,220.1,699.9C220.1,699.9,220.1,699.9,220.1,699.9C220.1,699.8,220.1,699.8,220.1,699.9
    C220.1,699.9,220.1,699.9,220.1,699.9z"/>
  <path class="st2" d="M235.4,602.8C235.5,602.8,235.5,602.8,235.4,602.8L235.4,602.8C235.5,602.8,235.5,602.8,235.4,602.8z"/>
  <path class="st2" d="M220.2,699.6C220.2,699.6,220.2,699.5,220.2,699.6C220.2,699.5,220.2,699.6,220.2,699.6
    C220.2,699.6,220.2,699.6,220.2,699.6z"/>
  <path class="st2" d="M223.8,641.4l3-13.1c0,0,0,0,0,0c0,0,0,0,0,0L223.8,641.4z"/>
  <path class="st2" d="M231.4,715.9C231.4,715.9,231.4,715.9,231.4,715.9C231.4,715.9,231.4,715.9,231.4,715.9
    C231.4,715.9,231.4,715.9,231.4,715.9z"/>
  <polygon class="st2" points="199.3,855.3 199.4,849.7 199.3,855.3 "/>
  <polygon class="st2" points="198.8,753.7 203.8,749.6 205.1,749.3 203.8,749.6 "/>
  <path class="st2" d="M231.5,716.2C231.5,716.2,231.5,716.2,231.5,716.2C231.5,716.2,231.5,716.2,231.5,716.2
    C231.5,716.2,231.5,716.2,231.5,716.2z"/>
  <polygon class="st2" points="189.1,788.5 189.9,774.1 189.1,788.5 "/>
  <path class="st2" d="M333.2,629.9c0,0,0.1,0,0.1,0c-0.1,0-0.2,0-0.3,0l-8.2-1.1l8.2,1.1C333.1,629.9,333.1,629.9,333.2,629.9z"/>
  </svg>
  
}

export { Room, Pencil, WorkPlace, Office, Home, Helmet, CS, TW };