import { useEffect, useState } from 'react';
import { Spinner, Table, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { TablePagination } from './Common';
import useAlert from '../../common/useAlert';

const { REACT_APP_API_BASE_URL: apiBaseUrl } = process.env;
const axios = require('axios').default;
const _ = require('lodash');

const WriteModal = ({ handleClose, loadTable, processTypes, id }) => {
  const [caseNo, setCaseNo] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [process, setProcess] = useState();
  const { successAlert } = useAlert();

  useEffect(() => {
    if (id) loadDetail(id);
  }, []);

  const submit = async () => {
    if (!caseNo || !name || !address || !process) return;

    const url = `${apiBaseUrl}/admin/cases`;
    const data = { caseNo, name, address, process };
    if (id) data.id = id;
    const method = id ? 'patch' : 'post';
    const successMsg = id ? '編輯案件成功' : '新增案件成功';
    axios({ url, data, method })
      .then(response => {
        handleClose();
        loadTable();
        successAlert(successMsg);
      })
  };

  const loadDetail = async (id) => {
    const url = `${apiBaseUrl}/admin/cases/${id}`;
    axios.get(url)
      .then(response => {
        setCaseNo(response.data.caseNo);
        setName(response.data.name);
        setAddress(response.data.address);
        setProcess(response.data.process);
      })
  }

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{id ? '編輯' : '新增'}案件</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="caseNo">
            <Form.Label className="required">案件編號</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={caseNo}
              onChange={({ target: { value } }) => setCaseNo(value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label className="required">業主姓名</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={({ target: { value } }) => setName(value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="address">
            <Form.Label className="required">工程地址</Form.Label>
            <Form.Control
              type="text"
              value={address}
              onChange={({ target: { value } }) => setAddress(value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="process">
            <Form.Label className="required">進度</Form.Label>
            <Form.Control
              as="select"
              value={process}
              onChange={({ target: { value } }) => setProcess(value)}
            >
              <option value="">請選擇</option>
              {processTypes.map(({ code, description }) => (
                <option key={code} value={code}>{description}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={submit}>
          {id ? '編輯' : '新增'}案件
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const TableFilter = ({ loadTable, setSize, processTypes = [] }) => {
  const [caseNo, setCaseNo] = useState();
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [process, setProcess] = useState();

  const search = async () => {
    loadTable({ caseNo, name, address, process });
  }

  return (
    <Row className="mb-3">
      <Form.Group as={Col} className="mb-3" controlId="caseNo">
        <Form.Label>案件編號</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          onChange={({ target: { value } }) => setCaseNo(value)}
        />
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="name">
        <Form.Label>業主</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          onChange={({ target: { value } }) => setName(value)}
        />
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="address">
        <Form.Label>地址</Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          onChange={({ target: { value } }) => setAddress(value)}
        />
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="process">
        <Form.Label>進度</Form.Label>
        <Form.Control
          as="select"
          placeholder=""
          onChange={({ target: { value } }) => setProcess(value)}
        >
          <option value="">全部</option>
          {processTypes.map(({ code, description }) => (
            <option key={code} value={code}>{description}</option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group as={Col} className="mb-3" controlId="process">
        <Form.Label>查詢筆數</Form.Label>
        <Form.Control
          as="select"
          onChange={({ target: { value } }) => setSize(value)}
        >
          <option value={20}>20</option>
          <option value={10}>10</option>
          <option value={5}>5</option>
        </Form.Control>
      </Form.Group>
      <Col>
        <Button variant="primary" className="custom-btn" as={Col} onClick={search}>查詢</Button>
      </Col>
    </Row>
  )
}

const DataTable = ({ loading, list, processTypeMap, setModalShow, setId }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>id</th>
          <th>案件編號</th>
          <th>業主姓名</th>
          <th>工程地址</th>
          <th>進度</th>
          <th>檢視</th>
          <th>編輯</th>
        </tr>
      </thead>
      <tbody>
        {loading
          ? <Spinner animation="border" />
          : list?.map(({ id, caseNo, name, address, process }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{caseNo}</td>
              <td>{name}</td>
              <td>{address}</td>
              <td>{processTypeMap[process]}</td>
              <td>
                <NavLink to={`/admin/cases/${id}/processes`}>
                  <Button size="sm" variant="outline-primary">檢視進度</Button>
                </NavLink>
              </td>
              <td >
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {setModalShow(true);setId(id)}}
                >編輯</Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

const Case = () => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState();
  const [processTypes, setProcessTypes] = useState([]);
  const [size, setSize] = useState(20);
  const [modalShow, setModalShow] = useState(false);
  const [id, setId] = useState();

  const processTypeMap = _.chain(processTypes).keyBy('code').mapValues('description').value();
  const loadTable = async ({ page, caseNo, name, address, process } = {}) => {
    setLoading(true);
    let url = `${apiBaseUrl}/admin/cases?page=${page || 1}&size=${size}`;
    if (caseNo) url += `&caseNo=${caseNo}`;
    if (name) url += `&name=${name}`;
    if (address) url += `&address=${address}`;
    if (process) url += `&process=${process}`;

    axios.get(url)
      .then(response => {
        setList(response?.data?.list);
        setPaginationInfo(_.omit(response?.data, 'list'));
        setLoading(false);
      })
  };
  const loadProcessType = async () => {
    const url = `${apiBaseUrl}/cases/processType`;
    axios.get(url)
      .then(response => setProcessTypes(response?.data?.types))
  };

  useEffect(() => {
    loadTable();
    loadProcessType();
  }, []);

  return <>
    <Button variant="primary" className="new-btn" onClick={() => setModalShow(true)}>新增案件</Button>{' '}
    <h1 className="title">案件</h1>
    {modalShow &&
      <WriteModal
        id={id}
        processTypes={processTypes}
        handleClose={() => {setModalShow(false); setId(null);}}
        loadTable={() => loadTable()}
      />
    }
    <TableFilter {...{ setLoading, setList, processTypes, setSize, loadTable }} />
    <DataTable {...{ loading, list, processTypeMap, setId, setModalShow }}/>
    <TablePagination {...{ paginationInfo, loadTable, loading }}/>
  </>;
};

export default Case;
