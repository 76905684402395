import './App.css';
import "animate.css"
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  Home, AboutUs, Projects, Pictures, Service, ContactUs, FollowUp, FrontRoute,
  AdminRoute, Case, Process, Project, Image, Contact, News, Banner,
} from './pages/index.js';
import React from "react";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <FrontRoute exact path="/" component={Home} />
          <FrontRoute exact path="/AboutUs" component={AboutUs} />
          <FrontRoute exact path="/Projects" component={Projects} />
          <FrontRoute exact path="/Projects/:id" component={Pictures} />
          <FrontRoute exact path="/Service" component={Service} />
          <FrontRoute exact path="/ContactUs" component={ContactUs} />
          <FrontRoute exact path="/FollowUp" component={FollowUp} />

          <AdminRoute exact path="/admin/cases/:id/processes" component={Process} />
          <AdminRoute exact path="/admin/cases" component={Case} />
          <AdminRoute exact path="/admin/projects/:id/images" component={Image} />
          <AdminRoute exact path="/admin/projects" component={Project} />
          <AdminRoute exact path="/admin/contact" component={Contact} />
          <AdminRoute exact path="/admin/news" component={News} />
          <AdminRoute exact path="/admin/banners" component={Banner} />
          <Route render={() => <h1>Page not found</h1>} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
