import React, {useState, useEffect} from 'react';
import {Container, Form, Row, Col, Button, InputGroup, Modal} from 'react-bootstrap';
import {Formik} from 'formik';
import * as yup from 'yup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhoneAlt, faEnvelope, faMapMarker, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
const axios = require('axios').default;
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const ContactUs = (props) => {
  const {baseUrl} = props;
  const [imgUrl, setImgUrl] = useState('');
  const [key, setKey] = useState('');
  const [errMsg, setErrMsg] = useState(null);
  const [sucMsg, setSucMsg] = useState(null);
  const [timer, setTimer] = useState(0);
  const initialValues = {
      name: '',
      phoneNumber: '',
      email: '',
      lineId: '',
      spaceType: 0,
      ping: '',
      budget: '',
      estimateStartTime: '',
      estimateEndTime: '',
      message: '',
      captchaKey: '',
      captcha: ''
  };
  const schema = yup.object().shape({
    name: yup.string().required('請輸入姓名'),
    phoneNumber: yup.string().required('請填寫電話').matches(/^(0[2-8]{1,2}-?[0-9]{3,4}-?[0-9]{3,4})|09\d{2}(\d{6}|-\d{3}-?\d{3})$/i, "請輸入正確的電話號碼格式"),
    email: yup.string().email('無效的 Email').required('請填寫Email'),
    lineId: yup.string().matches(/^[a-z0-9~\-_.]{1,120}$/i, '請輸入正確的lineID'),
    spaceType: yup.number().required(),
    ping: yup.number().typeError('請輸入數字').positive("坪數需大於0").nullable(true).required('請填寫坪數'),
    budget: yup.number().typeError('請輸入數字').positive('預算需大於0').nullable(true).required('請填寫預算'),
    estimateStartTime: yup.date()
      .min(new Date(), '請選擇未來日期')
      .nullable(),
    estimateEndTime: yup.date()
      .min(
        yup.ref('estimateStartTime'),
        "住宿日不可早於施工日"
      )
      .nullable(),
    message: yup.string(),
    captchaKey: yup.string(),
    captcha: yup.string().required("請輸入驗證碼")
  });

  useEffect(() => {
    loadCaptcha();
  }, []);

  useEffect(() => {
    if (!errMsg && !sucMsg) clearTimeout(timer);
  }, [errMsg, sucMsg]);

  const loadCaptcha = async () => {
    const url = `${baseUrl}/captcha`;
    const response = await axios.get(url);
    if (response.status !== 200) return;
    setKey(response.data.key);
    setImgUrl(response.data.image);
  }

  const UpdateTip = (type, msg) => {
    if (type === 'error') {
      setErrMsg(msg);
    }
    if (type === 'success') {
      setSucMsg(msg);
    }

    setTimer(setTimeout(() => {
      setErrMsg(null);
      setSucMsg(null);
    }, 3000));
  }

  const submit = (data, setSubmitting, resetForm) => {
    const url = `${baseUrl}/contact`;
    const submitData = _.cloneDeep(data);

    submitData.captchaKey = key;
    if(submitData.estimateStartTime){
      submitData.estimateStartTime = dayjs.utc(submitData.estimateStartTime).format('YYYY-MM-DD HH:mm:ss');
    }
    if(submitData.estimateEndTime){
      submitData.estimateEndTime = dayjs.utc(submitData.estimateEndTime).format('YYYY-MM-DD HH:mm:ss');
    }

    axios.post(url, submitData)
      .then(response => {
        if (response.status === 200) {
          UpdateTip('success', '新增成功');
          setSubmitting(false);
          resetForm({
            values: initialValues,
          });
          loadCaptcha();
        }
      })
      .catch(error => {
        if (error.response.data) {
          UpdateTip('error', error.response.data.message ? error.response.data.message : "新增失敗");
          loadCaptcha();
        }
        if(data.estimateStartTime){
          data.estimateStartTime = "";
        }
        if(data.estimateEndTime){
          data.estimateEndTime= "";
        }
      });
  }

  return (
    <div className="contact-us">
      <div className="pageContainer">
        <div className="background"/>
        <div className="information">
          <div>謝謝您的資訊， 我們會在五天內與您聯繫</div>
          <div>亦或是可直接電話與我們聯繫，謝謝！</div>
        </div>
        <Container className="text-center content">
          <Modal
            size="sm"
            show={errMsg || sucMsg}
            aria-labelledby="example-modal-sizes-title-sm"
            className={errMsg ? "warning" : "success"}
            backdropClassName={errMsg ? "warning" : "success"}
            onHide={() => {
              setErrMsg(null);
              setSucMsg(null);
            }}
            onClick={() => {
              setErrMsg(null);
              setSucMsg(null);
            }}
          >
            <Modal.Body><FontAwesomeIcon icon={faExclamationCircle}/>{errMsg || sucMsg}</Modal.Body>
          </Modal>
          <Row>
            <Col lg={12} xl={8}>
              <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  submit(values, setSubmitting, resetForm);
                }}
              >
                {({
                    setFieldValue,
                    handleSubmit,
                    touched,
                    values,
                    errors,
                    getFieldProps
                  }) => (
                  <Form className="form" noValidate onSubmit={handleSubmit} onKeyPress={event => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                    }
                  }}>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                      <Form.Label column sm={2} className="required">
                        姓名
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control className={(touched.name && errors.name) && "error"}
                                      name="name"
                                      {...getFieldProps('name')}
                                      isValid={touched.name && !errors.name}/>
                        {(touched.name && errors.name) && <div className="error">{errors.name}</div>}
                      </Col>
                      <Form.Label column sm={2} className="required">
                        電話
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control className={(touched.phoneNumber && errors.phoneNumber) && "error"}
                                      name="phoneNumber"
                                      {...getFieldProps('phoneNumber')}
                                      isValid={touched.phoneNumber && !errors.phoneNumber}/>
                        {(touched.phoneNumber && errors.phoneNumber) &&
                        <div className="error">{errors.phoneNumber}</div>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                      <Form.Label column sm={2} className="required">
                        Email
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control className={(touched.email && errors.email) && "error"}
                                      name="email"
                                      {...getFieldProps('email')}
                                      isValid={touched.email && !errors.email}/>
                        {(touched.email && errors.email) && <div className="error">{errors.email}</div>}
                      </Col>
                      <Form.Label column sm={2}>
                        Line ID
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control className={(touched.lineId && errors.lineId) && "error"}
                                      name="lineId"
                                      {...getFieldProps('lineId')}
                                      isValid={values.lineId && !errors.lineId}/>
                        {(touched.lineId && errors.lineId) && <div className="error">{errors.lineId}</div>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                      <Form.Label column sm={2} className="required">
                        空間性質
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Select aria-label="1"
                                     name="spaceType"
                                     {...getFieldProps('spaceType')}>
                          <option value="0">新成屋</option>
                          <option value="1">預售屋</option>
                          <option value="2">老屋</option>
                          <option value="3">商業空間</option>
                          <option value="4">辦公室</option>
                          <option value="5">毛胚屋</option>
                        </Form.Select>
                      </Col>
                      <Form.Label column sm={2} className="required">
                        坪數
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control className={(touched.ping && errors.ping) && "error"}
                                      name="ping"
                                      {...getFieldProps('ping')}
                                      isValid={values.ping && !errors.ping}/>
                        {(touched.ping && errors.ping) && <div className="error">{errors.ping}</div>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                      <Form.Label column sm={2} className="required">
                        預算
                      </Form.Label>
                      <Col sm={4}>
                        <InputGroup>
                          <Form.Control className={(touched.budget && errors.budget) && "budget error"}
                                        name="budget"
                                        {...getFieldProps('budget')}
                                        isValid={values.budget && !errors.budget}/>
                          <InputGroup.Text>萬</InputGroup.Text>
                        </InputGroup>
                        {(touched.budget && errors.budget) && <div className="error">{errors.budget}</div>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4" controlId="formHorizontalEmail">
                      <Form.Label column sm={2}>
                        預計施工日
                      </Form.Label>
                      <Col sm={4}>
                        <DatePickerField
                          className="date form-control"
                          name="estimateStartTime"
                          value={values.estimateStartTime}
                          onChange={setFieldValue}
                          isValid={!errors.estimateStartTime}
                        />
                        {(errors.estimateStartTime) && <div className="error">{errors.estimateStartTime}</div>}
                      </Col>
                      <Form.Label column sm={2}>
                        預計住宿日
                      </Form.Label>
                      <Col sm={4}>
                        <DatePickerField
                          className="date form-control"
                          name="estimateEndTime"
                          value={values.estimateEndTime}
                          onChange={setFieldValue}
                          isValid={!errors.estimateEndTime}
                        />
                        {(errors.estimateEndTime) && <div className="error">{errors.estimateEndTime}</div>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                      <Form.Label column sm={2} className="textarea">
                        留言內容
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control className={`textarea ${(touched.message && errors.message) && "error"}`}
                                      name="message" as="textarea"
                                      {...getFieldProps('message')}
                                      isValid={values.message && !errors.message}/>
                        {(touched.message && errors.message) && <div className="error">{errors.message}</div>}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 align-center" controlId="formHorizontalEmail">
                      <Form.Label column sm={2} className="required">
                        驗證碼
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control style={{display: 'none'}}
                                      name="captchaKey"
                                      {...getFieldProps('captchaKey')}
                                      isValid={true}/>
                        <Form.Control className={`date ${(touched.captcha && errors.captcha) && "error"}`}
                                      name="captcha"
                                      {...getFieldProps('captcha')}/>
                        {(touched.captcha && errors.captcha) && <div className="error">{errors.captcha}</div>}
                      </Col>
                      <Col sm={4} className="catch-wrap">
                        <div><img src={imgUrl} alt="驗證碼" onClick={loadCaptcha}/></div>
                        <span>※點按圖片可更換驗證碼</span>
                      </Col>
                    </Form.Group>
                    <Button type="submit" className="submit" variant="primary">送出</Button>
                  </Form>
                )}
              </Formik>
            </Col>
            <Col lg={12} xl={4} className="company-info">
              <div className="info-text">
                <ul>
                  <li>
                    <a className="link" href="https://www.facebook.com/xinzhandesign" target="_blank">
                      <span className="fb-icon"><FbIcon/></span>
                      <span className="link-text">新展室內裝修</span>
                    </a>
                  </li>
                  <li>
                    <a className="link" href="mailto:xinzhaninterior@gmail.com">
                      <span className="envelope-icon"><FontAwesomeIcon icon={faEnvelope}/></span>
                      <span className="link-text">xinzhaninterior@gmail.com</span>
                    </a>
                  </li>
                  <li>
                    <a className="link" href="tel:+886927161568">
                      <span className="phone-icon"><FontAwesomeIcon icon={faPhoneAlt}/></span>
                      <span className="link-text">+886927161568</span>
                    </a>
                  </li>
                  <li>
                    <a className="link">
                      <span className="map-icon"><FontAwesomeIcon icon={faMapMarker}/></span>
                      <span className="link-text">新北市板橋區國光路</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

const FbIcon = () => {
  return <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px"
              viewBox="0 0 167.657 167.657" style={{enableBackground: 'new 0 0 167.657 167.657'}} xmlSpace="preserve">
    <g>
      <path d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184
    c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452
    v20.341H37.29v27.585h23.814v70.761H89.584z"/>
    </g>
  </svg>
}

const DatePickerField = ({ name, value, onChange, className, isValid }) => {
  return (
    <DatePicker
      utcOffset={8}
      className={className + (isValid ? "" : " error")}
      dateFormat="yyyy/MM/dd"
      selected={(value ? new Date(value) : null) || null}
      onChange={val => {
        onChange(name, val);
      }}
    />
  );
};

export default ContactUs;
