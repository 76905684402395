import _ from 'lodash';
import { Route } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { Alert } from 'react-bootstrap';
import AlertProvider from '../../common/alertProvider';
import useAlert from '../../common/useAlert';
import React from 'react';
import WithAxios from './WithAxios';

const { REACT_APP_BASE_URL: appBaseUrl } = process.env;

const SideBar = ({ match }) => {
  const activeStyle = (url) => {
    const parentUrl = {
      '/admin/cases/:id/processes': '/admin/cases',
      '/admin/projects/:id/images': '/admin/projects',
    }[match.path] || match.path;
    if (parentUrl === url) return { fontWeight: 'bold' };
    return null;
  };

  const sidbarMap = {
    '/admin/news': '最新消息',
    '/admin/banners': 'banner',
    '/admin/projects': '作品集',
    '/admin/cases': '案件',
    '/admin/contact': '聯絡我們',
  };

  return (
    <div className="sidebar">
      {_.map(sidbarMap, (name, url) =>  <NavLink to={url} style={activeStyle(url)} key={name}>{name}</NavLink>)}
      <a href={`${appBaseUrl}/api/perform_logout`} className="">登出</a>
    </div>
  )
}

const CustomAlert = () => {
  const { alert } = useAlert();
  if (!alert) return null;

  const { status, message, code } = alert;
  return <Alert className="custom-alert" variant={status === 'error' ? 'danger' : 'success'}>
    {message}{' '}{code ? `(${code})` : ''}
  </Alert>
}

const AdminRoute = ({ component: Component, ...props }) => {
  return <Route
    {...props}
    render={(props) => <>
      <SideBar {...props} />
      <AlertProvider>
        <WithAxios>
          <div className="admin-container">
            <Component {...props} />
            <CustomAlert />
          </div>
        </WithAxios>
      </AlertProvider>
    </>}
  />
}

export default AdminRoute;
